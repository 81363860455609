import { makeAutoObservable } from 'mobx';

export default class VacancyStore {
  constructor() {
    this._vacancy = [];
    makeAutoObservable(this);
  }

  setVacancy(vacancy) {
    this._vacancy = vacancy;
  }

  addVacancy(vacancy) {
    this._vacancy.push(vacancy);
  }

  updateVacancy(id, data) {
    this._vacancy = this._vacancy.map((vacancy) =>
      vacancy.id === id ? { ...data } : { ...vacancy }
    );
  }

  removeVacancy(id) {
    this._vacancy = this._vacancy.filter((vacancy) => vacancy.id !== id);
  }

  get vacancy() {
    return this._vacancy;
  }
}
