import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Dropdown, Form, Row, Table } from 'react-bootstrap';
import {
  deleteCategory,
  deleteCategoryInfo,
  fetchAllCategory,
  updateCategoryInfo,
} from '../../http/categoryApi';
import { Context } from '../../index';
import CreateCategory from '../../components/modals/CreateCategory';

const Category = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editName, setEditName] = useState('');
  const [lang, setLang] = useState();
  const { category, locale } = useContext(Context);

  useEffect(() => {
    fetchAllCategory().then((data) => category.setCategory(data));
  }, [category]);

  const editToggle = (id, name, locale) => {
    setEditId(id);
    setEditName(name);
    setLang(locale);
  };

  const editCategoryInfo = () => {
    updateCategoryInfo(editId, editName, lang.id).then((data) => {
      setEditId('');
      category.updateСategory(data.id, data);
    });
  };

  const delCategory = (id) => {
    deleteCategory(id)
      .then(() => {
        category.removeСategory(id);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  const delCategoryInfo = (id) => {
    deleteCategoryInfo(id)
      .then((data) => {
        category.setCategory(data);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Типы продуктов</h1>
      <Button variant="outline-success" onClick={() => setCreateVisible(true)}>
        <i className="bi bi-plus-square"></i> Добавить
      </Button>
      <Row>
        {category.category.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th>Информация</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {category.category?.map((category) => (
                <tr key={category.id}>
                  <td>{category.id}</td>
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Название</th>
                          <th className="col-2">Язык</th>
                          <th className="col-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {category.categoryInfos?.map((info) =>
                          editId === info.id ? (
                            <tr key={info.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editName}
                                  onChange={(e) => setEditName(e.target.value)}
                                  placeholder={'Введите название категории...'}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => editToggle()}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editCategoryInfo()}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={info.id}>
                              <td>{info.name}</td>
                              <td>{info.locale?.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2"
                                  onClick={() =>
                                    editToggle(info.id, info.name, info.locale)
                                  }
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => delCategoryInfo(info.id)}
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => delCategory(category.id)}
                    >
                      Удалить
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateCategory
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Category;
