import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import PersoneCard from '../components/PersoneCard';
import { fetchAllEnterpriseAdministrationLang } from '../http/enterpriseAdministrationApi';
import { Context } from '../index';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Helmet } from 'react-helmet';

const Structure = observer(() => {
  const { enterpriseAdministration, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAllEnterpriseAdministrationLang(locale.selectLang)
      .then((data) => {
        enterpriseAdministration.setEnterpriseAdministration(data);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [enterpriseAdministration, locale.selectLang]);
  const { t } = useTranslation();
  document.title = t('structure.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('structure.pageTitle')}
          meta={[
            { name: 'description', content: t('structure.pageDescription') },
          ]}
        />
      </Container>
    );
  return (
    <div className="container mt-5 d-flex flex-column min-vh-100">
      <Helmet
        title={t('structure.pageTitle')}
        meta={[
          { name: 'description', content: t('structure.pageDescription') },
        ]}
      />
      <h1 className="text-center mb-3">{t('structure.title')}</h1>
      <Row>
        {enterpriseAdministration.enterpriseAdministration?.map(
          (enterpriseAdministration) =>
            enterpriseAdministration.enterpriseAdministrationInfos?.map(
              (info) => (
                <Col key={info.id} xs={12} sm={6} md={4}>
                  <PersoneCard
                    phone={enterpriseAdministration.phone}
                    mail={enterpriseAdministration.mail}
                    fullName={info.fullName}
                    position={info.position}
                    about={info.about}
                  />
                </Col>
              )
            )
        )}
      </Row>
    </div>
  );
});

export default Structure;
