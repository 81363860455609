import { makeAutoObservable } from 'mobx';

export default class AwardStore {
  constructor() {
    this._award = [];
    makeAutoObservable(this);
  }

  setAward(award) {
    this._award = award;
  }

  addAward(award) {
    this._award.push(award);
  }

  updateAward(id, data) {
    this._award = this._award.map((award) =>
      award.id === id ? { ...data } : { ...award }
    );
  }

  removeAward(id) {
    this._award = this._award.filter((award) => award.id !== id);
  }

  get award() {
    return this._award;
  }
}
