import { $authHost, $host } from './index';

export const fetchAllLocale = async () => {
  const { data } = await $host.get('api/locale');
  return data;
};

export const updateLocale = async (id, name) => {
  const { data } = await $authHost.put('api/locale/' + id, { name });
  return data;
};

export const fetchAllCurrency = async () => {
  const { data } = await $host.get('api/currency');
  return data;
};

export const updateCurrency = async (id, name, value) => {
  const { data } = await $authHost.put('api/currency/' + id, { name, value });
  return data;
};
