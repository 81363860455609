import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { fetchAllNews } from '../../http/newsApi';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import MainPageNewsVI from './MainPageNewsVI';
import { NEWS_ROUTE } from '../../utils/consts';

const NewsBlockVI = observer(() => {
  const { news, locale } = useContext(Context);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    fetchAllNews(1, locale.selectLang, 3, 1)
      .then((data) => {
        news.setNews(data.rows);
      })
      .then(() => {
        setLoading(false);
      });
  }, [news, locale, locale.selectLang]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div className="">
      {news.news.map((news) =>
        news.newsInfos.map((info) => (
          <MainPageNewsVI
            img={news.photo ? process.env.REACT_APP_API_URL + news.photo : ''}
            key={info.id}
            heading={info.title}
            text={info.newsText}
            link={NEWS_ROUTE + '/' + news.id}
          />
        ))
      )}
    </div>
  );
});

export default NewsBlockVI;
