import React, { useContext, useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { login } from '../http/userApi';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { useHistory } from 'react-router-dom';
import { ADMIN_ROUTE } from '../utils/consts';

const Auth = observer(() => {
  const { user } = useContext(Context);
  const history = useHistory();
  const [userLogin, setUserLogin] = useState('');
  const [password, setPassword] = useState('');

  const signIn = async () => {
    try {
      let data = await login(userLogin, password);
      user.setUser(user);
      user.setIsAuth(true);
      history.push(ADMIN_ROUTE);
    } catch (e) {
      alert(e.response.data.message);
    }
  };
  return (
    <Container className="d-flex flex-column min-vh-100 align-items-center">
      <Card style={{ width: 600 }} className="p-3 mt-5">
        <h3 className="m-auto">Авторизация</h3>
        <Form className="d-flex flex-column">
          <Form.Control
            className="mt-2"
            placeholder="Введите логин"
            value={userLogin}
            onChange={(e) => setUserLogin(e.target.value)}
          />
          <Form.Control
            className="mt-2"
            placeholder="Введите пароль"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            className="mt-2 align-self-center"
            variant="success"
            onClick={signIn}
          >
            Войти
          </Button>
        </Form>
      </Card>
    </Container>
  );
});

export default Auth;
