import React, { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { createProductAvailability } from '../../http/productAvailabilityApi';
import { Context } from '../../index';

const CreateProductAvailability = ({ productId, show, onHide }) => {
  const { productAvailability } = useContext(Context);

  const [weight, setWeight] = useState('');
  const [price, setPrice] = useState('');
  const [available, setAvailable] = useState(false);
  const [error, setError] = useState('');

  const addProductAvailability = () => {
    weight
      ? price
        ? createProductAvailability(weight, price, available, productId).then(
            (data) => {
              setWeight('');
              setPrice('');
              setAvailable(false);
              onHide();
              productAvailability.addProductAvailability(data);
            }
          )
        : setError('price')
      : setError('weight');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить наличение продукта
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            type="number"
            value={weight}
            onChange={(e) => {
              setWeight(e.target.value);
              setError('');
            }}
            className={error === 'weight' ? 'error mb-3' : 'mb-3'}
            placeholder={'Введите вес в кг'}
          />
          <Form.Control
            type="number"
            value={price}
            onChange={(e) => {setPrice(e.target.value); setError('')}}
            className={error === 'price' ? 'error mt-3 mb-3' : 'mt-3 mb-3'}
            placeholder={'Введите цену в руб.'}
          />
          <Form.Check
            value={available}
            onChange={(e) => setAvailable(e.target.checked)}
            className="mt-3"
            type={'checkbox'}
            label={'В наличии'}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button variant="outline-success" onClick={addProductAvailability}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateProductAvailability;
