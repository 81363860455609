import React, { useContext, useEffect } from 'react';
import {
  Button,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';
import {
  AWARDS_ROUTE,
  CONTACT_ROUTE,
  ENTERPRISE_TODAY_ROUTE,
  HISTORY_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  PRODUCTS_ROUTE,
  QUALITY_ROUTE,
  RECIPE_ROUTE,
  SOCIAL_ACTIVITY_ROUTE,
  STORE_ROUTE,
  STRUCTURE_ROUTE,
  TRADE_UNION_ROUTE,
  VACANCY_ROUTE,
  PRICE_ROUTE,
  FEEDBACK_ROUTE,
  RECEPTOION_SCHEDULE,
} from '../utils/consts';
import { Context } from '../index';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
  const { locale, vi } = useContext(Context);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    locale.setSelectLang(lang);
  };

  let lang = localStorage.getItem('i18nextLng');
  locale.setSelectLang(lang);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
  }, [locale.selectLang]);

  const setVI = () => {
    vi.setVI(true);
    localStorage.setItem('VI', true);
  };

  return (
    <Navbar
      bg="light"
      className="header"
      expand="xl"
      role="navigation"
      sticky="top"
      collapseOnSelect="true"
      // style={{ backgroundImage: 'url(img/header.jpg) no-repeat' }}
    >
      <Container className="flex-xl-column header_container">
        <Link to={MAIN_ROUTE} className="nav_logo">
          <Image
            src={process.env.REACT_APP_API_URL + 'LOGO.png'}
            className="nav_logo"
          />
        </Link>

        <Navbar.Brand href={MAIN_ROUTE} className="logo">
          {t('header.brand')}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar" className="burger" />
        <Navbar.Collapse id="navbar">
          <Nav className="d-flex justify-content-between" navbarScroll>
            <NavDropdown
              title={t('header.about')}
              id="basic-nav-dropdown"
              className="dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={HISTORY_ROUTE}
                  href={HISTORY_ROUTE}
                >
                  {t('header.aboutCompany')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={ENTERPRISE_TODAY_ROUTE}
                  href={ENTERPRISE_TODAY_ROUTE}
                >
                  {t('header.today')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={STRUCTURE_ROUTE}
                  href={STRUCTURE_ROUTE}
                >
                  {t('header.structure')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item href={QUALITY_ROUTE}>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={QUALITY_ROUTE}
                  href={QUALITY_ROUTE}
                >
                  {t('header.quality')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={AWARDS_ROUTE}
                  href={AWARDS_ROUTE}
                >
                  {t('header.awards')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item href={TRADE_UNION_ROUTE}>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={TRADE_UNION_ROUTE}
                  href={TRADE_UNION_ROUTE}
                >
                  {t('header.tradeUnion')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item href={SOCIAL_ACTIVITY_ROUTE}>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={SOCIAL_ACTIVITY_ROUTE}
                  href={SOCIAL_ACTIVITY_ROUTE}
                >
                  {t('header.socialActivity')}
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              className="link"
              to={PRODUCTS_ROUTE}
              href={PRODUCTS_ROUTE}
            >
              {t('header.products')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="link"
              href={RECIPE_ROUTE}
              to={RECIPE_ROUTE}
            >
              {t('header.recipes')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="link"
              href={STORE_ROUTE}
              to={STORE_ROUTE}
            >
              {t('header.store')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="link"
              href={VACANCY_ROUTE}
              to={VACANCY_ROUTE}
            >
              {t('header.vacancy')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="link"
              href={NEWS_ROUTE}
              to={NEWS_ROUTE}
            >
              {t('header.news')}
            </Nav.Link>
            <NavDropdown
              title={t('header.contacts')}
              id="basic-nav-dropdown"
              className="dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={CONTACT_ROUTE}
                  href={CONTACT_ROUTE}
                >
                  {t('header.contacts')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={RECEPTOION_SCHEDULE}
                  href={RECEPTOION_SCHEDULE}
                >
                  {t('header.receptionSchedule')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  className="link ndd-link"
                  to={FEEDBACK_ROUTE}
                  href={FEEDBACK_ROUTE}
                >
                  {t('header.feedback')}
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
            {/* <NavLink className="link" to={CONTACT_ROUTE}>
              {t('header.contacts')}
            </NavLink> */}
            <Nav.Link
              as={Link}
              className="link"
              to={PRICE_ROUTE}
              href={PRICE_ROUTE}
            >
              {t('header.price')}
            </Nav.Link>
            <div className="p-1 lang_btn">
              <Button
                title="Russian"
                className={
                  locale.selectLang === 1
                    ? 'lang__btn active_lang'
                    : 'lang__btn'
                }
                onClick={() => changeLanguage('ru')}
              >
                Ru
              </Button>
              <Button
                title="Belarusian"
                className={
                  locale.selectLang === 3
                    ? 'lang__btn active_lang'
                    : 'lang__btn'
                }
                onClick={() => changeLanguage('be')}
              >
                Be
              </Button>
              <Button
                title="English"
                className={
                  locale.selectLang === 2
                    ? 'lang__btn active_lang'
                    : 'lang__btn'
                }
                onClick={() => changeLanguage('en')}
              >
                En
              </Button>
              <Button
                title="Chinese"
                className={
                  locale.selectLang === 4
                    ? 'lang__btn active_lang'
                    : 'lang__btn'
                }
                onClick={() => changeLanguage('zh')}
              >
                Ch
              </Button>
              <Button className="lang__btn iconVI" onClick={() => setVI()}>
                <i className="fa-regular fa-eye"></i>
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
