import { $authHost, $host } from './index';

export const createAward = async (award) => {
  const { data } = await $authHost.post('api/award', award);
  return data;
};

export const fetchAllAward = async () => {
  const { data } = await $host.get('api/award');
  return data;
};

export const fetchAllAwardLang = async (id) => {
  const { data } = await $host.get('api/award/locale/' + id);
  return data;
};

export const updateAward = async (id, award) => {
  const { data } = await $authHost.put('api/award/' + id, award);
  return data;
};

export const deleteAward = async (id) => {
  const { data } = await $authHost.delete('api/award/' + id);
  return data;
};

export const createAwardInfo = async (description, localeId, awardId) => {
  const { data } = await $authHost.post('api/award/info', {
    description,
    localeId,
    awardId,
  });
  return data;
};

export const updateAwardInfo = async (id, description, localeId) => {
  const { data } = await $authHost.put('api/award/info/' + id, {
    description,
    localeId,
  });
  return data;
};

export const deleteAwardInfo = async (id, awardId) => {
  const { data } = await $authHost.delete('api/award/info/' + id, {
    data: { awardId },
  });
  return data;
};
