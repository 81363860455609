import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

const ShopCard = ({ img, name, address }) => {
  return (
    <div>
      <div className="item">
        <div className="p-3">
          <Row>
            <Col md={12} lg={8}>
              <Image src={img} className="img img-shop" />
            </Col>
            <Col>
              <h4>
                <b>{name}</b>
              </h4>
              <p>{address}</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
