import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import { Context } from '../../index';
import { fetchProductLang } from '../../http/productApi';
import { fetchAllCurrency } from '../../http/localeApi';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { PRODUCTS_ROUTE } from '../../utils/consts';

const ProductInfoVI = observer(() => {
  const { product, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(false);
  const params = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    fetchAllCurrency().then((data) => {
      data.map((data) => locale.setCurrency(data.name, data.value));
    });
    fetchProductLang(params.id, locale.selectLang)
      .then((data) => {
        product.setProduct(data);
      })
      .then(() => {
        document.title = product.product?.productInfos[0]?.name;
        setLoading(false);
        checkAvailable();
      });
    window.scrollTo(0, 0);
  }, [
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const checkAvailable = () => {
    product.product?.available?.forEach((available) => {
      if (available.available) setAvailable(true);
    });
  };

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );
  return (
    <div
      className={
        'container__VI py-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <div className="">
        {product.product ? (
          <Row>
            <Col md="4">
              <Image
                src={process.env.REACT_APP_API_URL + product.product?.photo}
                className="img img-pruduct__big"
              />
            </Col>
            <Col>
              <h1>{product.product?.productInfos[0]?.name}</h1>
              <p className="product_description">
                {product.product?.productInfos[0]?.description}
              </p>
              {available ? t('product.weightСategory') : ''}
              <ul>
                {product.product?.available?.map((available) =>
                  available.available ? (
                    <li key={available.weight}>
                      {locale.selectLang === 1 || locale.selectLang === 3
                        ? available.weight +
                          ' кг (' +
                          available.price +
                          ' BYN за кг)'
                        : ''}
                      {locale.selectLang === 2
                        ? available.weight +
                          ' kg (' +
                          Math.ceil(
                            available.price * locale.currency.USD * 100
                          ) /
                            100 +
                          ' USD per kg)'
                        : ''}
                      {locale.selectLang === 4
                        ? available.weight +
                          ' kg (' +
                          Math.ceil(
                            available.price * locale.currency.CNY * 100
                          ) /
                            100 +
                          ' CNY 每公斤)'
                        : ''}
                    </li>
                  ) : (
                    ''
                  )
                )}
              </ul>
              <Col className="my-5">
                <Link to={PRODUCTS_ROUTE} className="link__VI">
                  {t('product.back')}
                </Link>
              </Col>
            </Col>
          </Row>
        ) : (
          <Row>
            <h2 className="d-flex justify-content-center mt-5">
              {t('product.notFound')}
            </h2>
            <p className="my-5 d-flex justify-content-center">
              {t('product.text')}
            </p>
            <Col className="my-5">
              <Link to={PRODUCTS_ROUTE} className="btn__card">
                {t('product.back')}
              </Link>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
});

export default ProductInfoVI;
