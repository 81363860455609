import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

const StoreCardVI = ({ img, name, address }) => {
  return (
    <Row className='pb-3'>
      <Col md={5}>
        <Image src={img} className="img img-shop" />
      </Col>
      <Col>
        <h4>
          <b>{name}</b>
        </h4>
        <p>{address}</p>
      </Col>
    </Row>
  );
};

export default StoreCardVI;
