import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const MainPageNews = ({ img, heading, text, link }) => {
  const { t } = useTranslation();
  return (
    <div className="item">
      <Image src={img} className="img img-main" />
      <div className="p-3">
        <h2 className="heading">{heading}</h2>
        <p className='card-text'>{text}</p>
        <Link to={link} className="btn__card">
          {t('main.more')}
        </Link>
      </div>
    </div>
  );
};

export default MainPageNews;
