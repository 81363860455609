import { makeAutoObservable } from 'mobx';

export default class ProductStore {
  constructor() {
    this._product = [];
    makeAutoObservable(this);
  }

  setProduct(product) {
    this._product = product;
  }

  addProduct(product) {
    this._product.push(product);
  }

  updateProduct(id, data) {
    this._product = this._product.map((product) =>
      product.id === id ? { ...data } : { ...product }
    );
  }

  removeProduct(id) {
    this._product = this._product.filter((product) => product.id !== id);
  }

  get product() {
    return this._product;
  }
}
