import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const PageSize = observer(() => {
  const { page } = useContext(Context);
  const pageSizes = [10, 20, 50, 100, 200, 500];
  return (
    <>
      <p className="mt-1 me-2">Размер страницы: </p>
      <DropdownButton title={page.limit}>
        {pageSizes.map((pageSize) => (
          <Dropdown.Item
            key={pageSize}
            active={page.limit === pageSize}
            onClick={() => {
              page.setLimit(pageSize);
              page.setPage(1);
            }}
          >
            {pageSize}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
});

export default PageSize;
