import React, { useContext, useEffect } from 'react';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

const VIControl = observer(() => {
  const { vi } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {}, [vi.colors]);

  let VI = localStorage.getItem('VI');
  let tSize = localStorage.getItem('tSize');
  let pColor = localStorage.getItem('pColor');
  let colors = localStorage.getItem('colors');
  let interval = localStorage.getItem('interval');
  let kerning = localStorage.getItem('kerning');

  VI && vi.setVI(VI);
  tSize && vi.setTSize(tSize);
  pColor && vi.setPColor(pColor);
  colors && vi.setColors(colors);
  interval && vi.setInterval(interval);
  kerning && vi.setKerning(kerning);

  const setVI = () => {
    vi.setVI(false);
    localStorage.setItem('VI', false);
  };

  const setTSize = (size) => {
    vi.setTSize(size);
    localStorage.setItem('tSize', size);
  };

  const setPColor = (color) => {
    vi.setPColor(color);
    localStorage.setItem('pColor', color);
  };

  const setColors = (color) => {
    vi.setColors(color);
    localStorage.setItem('colors', color);
  };

  const setInterval = (interval) => {
    vi.setInterval(interval);
    localStorage.setItem('interval', interval);
  };

  const setKerning = (kerning) => {
    vi.setKerning(kerning);
    localStorage.setItem('kerning', kerning);
  };

  return (
    <div
      className={'container__VI pb-3' + ' colors_' + vi.colors}
      expand="xl"
      role="navigation"
      sticky="top"
    >
      <Row className="py-3">
        <Col lg={4} md={6} sm={12} className="pb-3">
          Размер текста:{' '}
          <ButtonGroup size="lg">
            <button onClick={() => setTSize(1)} className="tSize_1">
              А
            </button>
            <button onClick={() => setTSize(2)} className="tSize_2">
              А
            </button>
            <button onClick={() => setTSize(3)} className="tSize_3">
              А
            </button>
          </ButtonGroup>
        </Col>
        <Col lg={3} md={3} sm={12} className="pb-3">
          Цвет: {' '}
          <button onClick={() => setColors(1)} className="colors_1">
            А
          </button>
          <button onClick={() => setColors(2)} className="colors_2">
            А
          </button>
          <button onClick={() => setColors(3)} className="colors_3">
            А
          </button>
          <button onClick={() => setColors(4)} className="colors_4">
            А
          </button>
          <button onClick={() => setColors(5)} className="colors_5">
            А
          </button>
        </Col>
        <Col lg={5} md={6} sm={12} className="pb-3">
          Изображения:{' '}
          <button onClick={() => setPColor(1)}>Цветные</button>
          <button onClick={() => setPColor(2)}>Чёрно-белые</button>
          <button onClick={() => setPColor(3)}>Нет</button>
        </Col>
      </Row>
      <Row>
        <Col lg={5} md={5} sm={12} className="pb-3">
          Интервал:{' '}
          <button onClick={() => setInterval(1)}>Одинарный</button>
          <button onClick={() => setInterval(2)}>Полуторный</button>
          <button onClick={() => setInterval(3)}>Двойной</button>
        </Col>
        <Col lg={6} md={6} sm={11} className="pb-3">
          Кернинг:{' '}
          <button onClick={() => setKerning(1)} className="kerning_1">
            Одинарный
          </button>
          <button onClick={() => setKerning(2)} className="kerning_2">
            Полуторный
          </button>
          <button onClick={() => setKerning(3)} className="kerning_3">
            Двойной
          </button>
        </Col>
        <Col lg={1} md={1} sm={1}>
          <button onClick={() => setVI()}>
            <i className="fa-regular fa-eye-slash"></i>
          </button>
        </Col>
      </Row>
    </div>
  );
});

export default VIControl;
