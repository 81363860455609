import React, { useContext, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { createVacancy, createVacancyInfo } from '../../http/vacancyApi';
import { Context } from '../../index';

const CreateVacancy = ({ show, onHide }) => {
  const { vacancy, locale } = useContext(Context);
  const [jobTitle, setJobTitle] = useState('');
  const [requiredSpecialty, setRequiredSpecialty] = useState('');
  const [wageMin, setWageMin] = useState(undefined);
  const [wageMax, setWageMax] = useState(undefined);
  const [workingConditions, setWorkingConditions] = useState('');
  const [requiredExperienceFrom, setRequiredExperienceFrom] =
    useState(undefined);
  const [requiredExperienceUpTo, setRequiredExperienceUpTo] =
    useState(undefined);
  const [lang, setLang] = useState();
  const [toggle, setToggle] = useState(false);
  const [selectedVacancy, setSelectedVacancy] = useState(null);
  const [error, setError] = useState('');

  const submitHandler = (type) => {
    type === 'info'
      ? createVacancyInfo(
          jobTitle,
          requiredSpecialty,
          workingConditions,
          lang ? lang.id : 1,
          selectedVacancy.id
        ).then((data) => {
          vacancy.updateVacancy(data.id, data);
        })
      : createVacancy(
          jobTitle,
          requiredSpecialty,
          wageMin,
          wageMax,
          workingConditions,
          requiredExperienceFrom,
          requiredExperienceUpTo,
          lang ? lang.id : 1
        ).then((data) => {
          vacancy.addVacancy(data);
        });

    setJobTitle('');
    setRequiredSpecialty('');
    setWageMin('');
    setWageMax('');
    setWorkingConditions('');
    setRequiredExperienceFrom('');
    setRequiredExperienceUpTo('');
    setSelectedVacancy(null);
    onHide();
  };

  const addVacancy = () => {
    jobTitle
      ? requiredSpecialty
        ? workingConditions
          ? lang
            ? submitHandler()
            : setError('lang')
          : setError('workingConditions')
        : setError('requiredSpecialty')
      : setError('jobTitle');
  };

  const addVacancyInfo = () => {
    selectedVacancy
      ? jobTitle
        ? requiredSpecialty
          ? workingConditions
            ? lang
              ? submitHandler('info')
              : setError('lang')
            : setError('workingConditions')
          : setError('requiredSpecialty')
        : setError('jobTitle')
      : setError('selectedVacancy');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить вакансию
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle
                className={error === 'selectedVacancy' ? 'error' : ''}
              >
                {selectedVacancy
                  ? selectedVacancy.vacancyInfos[0].jobTitle
                  : 'Выберите вакансию'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {vacancy.vacancy.map((vacancy) =>
                  vacancy.vacancyInfos.map((vacancyInfo) =>
                    vacancyInfo.locale.name === 'ru' ? (
                      <Dropdown.Item
                        key={vacancyInfo.id}
                        onClick={() => {
                          setSelectedVacancy(vacancy);
                          setError('');
                        }}
                      >
                        {vacancyInfo.jobTitle}
                      </Dropdown.Item>
                    ) : (
                      ''
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ''
          )}
          <Form.Control
            value={jobTitle}
            onChange={(e) => {
              setJobTitle(e.target.value);
              setError('');
            }}
            className={(error === 'jobTitle' ? 'error' : '') + ' mb-3'}
            placeholder={'Введите название вакансии'}
          />
          <Form.Control
            value={requiredSpecialty}
            onChange={(e) => {
              setRequiredSpecialty(e.target.value);
              setError('');
            }}
            className={
              (error === 'requiredSpecialty' ? 'error' : '') + ' mt-3 mb-3'
            }
            placeholder={'Введите требуемую специальность'}
          />
          <Form.Control
            value={workingConditions}
            onChange={(e) => {
              setWorkingConditions(e.target.value);
              setError('');
            }}
            as="textarea"
            rows={2}
            className={
              (error === 'workingConditions' ? 'error' : '') + ' mt-3 mb-3'
            }
            placeholder={'Введите условия'}
          />
          {toggle ? (
            <></>
          ) : (
            <>
              <Form.Control
                value={requiredExperienceFrom}
                onChange={(e) => setRequiredExperienceFrom(e.target.value)}
                className="mt-3 mb-3"
                placeholder={'Требуемый опыт от'}
                type="number"
                min="0"
              />
              <Form.Control
                value={requiredExperienceUpTo}
                onChange={(e) => setRequiredExperienceUpTo(e.target.value)}
                className="mt-3 mb-3"
                placeholder={'Требуемый опыт до'}
                type="number"
                min="0"
              />
              <Form.Control
                value={wageMin}
                onChange={(e) => setWageMin(e.target.value)}
                className="mt-3 mb-3"
                placeholder={'Введите заработную плату от'}
                type="number"
                min="0"
              />
              <Form.Control
                value={wageMax}
                onChange={(e) => setWageMax(e.target.value)}
                className="mt-3 mb-3"
                placeholder={'Введите заработную плату до'}
                type="number"
                min="0"
              />
            </>
          )}
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={onHide}>
            Закрыть
          </Button>
          <Button
            variant="outline-success"
            onClick={toggle ? addVacancyInfo : addVacancy}
          >
            Добавить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateVacancy;
