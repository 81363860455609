import React, { useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import {
  AWARDS_ROUTE,
  ECO_ROUTE,
  ENTERPRISE_TODAY_ROUTE,
  HISTORY_ROUTE,
  QUALITY_ROUTE,
  STRUCTURE_ROUTE,
  TRADE_UNION_ROUTE,
} from '../../utils/consts';
import '../main.css';
import '../VI.css';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import MainPageCardVI from '../../components/VI/MainPageCardVI';
import EcoCardVI from '../../components/VI/EcoCardVI';
import NewsBlockVI from '../../components/VI/NewsBlockVI';

const MainVI = observer(() => {
  const { vi } = useContext(Context);
  useEffect(() => {}, [
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  document.title = t('main.brand');
  const ecoCards = t('main.eco.ecoCard', { returnObjects: true });
  return (
    <div
      className={
        'container__VI py-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <section>
        <h1>{t('main.brand')}</h1>
      </section>
      <section className="py-5">
        <Row>
          <MainPageCardVI
            heading={t('main.mainCard1.heading')}
            link={HISTORY_ROUTE}
          />
          <MainPageCardVI
            heading={t('main.mainCard2.heading')}
            link={STRUCTURE_ROUTE}
          />
          <MainPageCardVI
            heading={t('main.mainCard3.heading')}
            link={ENTERPRISE_TODAY_ROUTE}
          />
          <MainPageCardVI
            heading={t('main.mainCard4.heading')}
            link={QUALITY_ROUTE}
          />
          <MainPageCardVI
            heading={t('main.mainCard5.heading')}
            link={AWARDS_ROUTE}
          />
          <MainPageCardVI
            heading={t('main.mainCard6.text')}
            link={TRADE_UNION_ROUTE}
          />
        </Row>
      </section>
      <section>
        <h1>{t('main.eco.heading')}</h1>
        <h3>{t('main.eco.text')}</h3>
      </section>
      <section className="mt-5">
        {ecoCards.map((card) => (
          <EcoCardVI
            heading={card.heading}
            icon={card.icon}
            link={ECO_ROUTE + card.link}
            key={card.heading}
          />
        ))}
      </section>
      <section>
        <h1 className='py-3'>{t('main.news')}</h1>
      </section>
      <section>
        <NewsBlockVI />
      </section>
    </div>
  );
});

export default MainVI;
