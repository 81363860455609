import React from 'react';

const ContactCardVI = ({ name, contacts }) => {
  return (
    <div className="p-3">
      <h4 className="text-center mb-3">
        <b>{name}</b>
      </h4>
      {contacts.map((contact) => (
        <p key={contact}>{contact}</p>
      ))}
    </div>
  );
};

export default ContactCardVI;
