import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import EcoCard from '../components/EcoCard';
import ImgBlock from '../components/ImgBlock';
import MainPageCard from '../components/MainPageCard';
import {
  AWARDS_ROUTE,
  ECO_ROUTE,
  ENTERPRISE_TODAY_ROUTE,
  HISTORY_ROUTE,
  QUALITY_ROUTE,
  STRUCTURE_ROUTE,
  TRADE_UNION_ROUTE,
} from '../utils/consts';
import './main.css';
import './VI.css';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import NewsBlock from '../components/NewsBlock';

const Main = () => {
  const { t } = useTranslation();
  document.title = t('main.pageTitle');
  const ecoCards = t('main.eco.ecoCard', { returnObjects: true });
  const [show, setShow] = useState(true);

  return (
    <div>
      <Helmet
        title={t('main.pageTitle')}
        meta={[{ name: 'description', content: t('main.pageDescription') }]}
      />
      {/* <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        size="xl"
      >
        <img src={require('../static/sale.jpg')} />
      </Modal> */}
      <section>
        <ImgBlock
          img={'img/slider_1.jpg'}
          heading={t('main.brand')}
          // text={t('main.about')}
          size={'l'}
        />
      </section>
      <section className="mt-5 container">
        <Row>
          <Col lg={4} md={6}>
            <MainPageCard
              img={require('../static/img_1.jpg')}
              heading={t('main.mainCard1.heading')}
              text={t('main.mainCard1.text')}
              link={HISTORY_ROUTE}
            />
          </Col>
          <Col lg={4} md={6}>
            <MainPageCard
              img={require('../static/img_2.jpg')}
              heading={t('main.mainCard2.heading')}
              text={t('main.mainCard2.text')}
              link={STRUCTURE_ROUTE}
            />
          </Col>
          <Col lg={4} md={6}>
            <MainPageCard
              img={require('../static/img_3.jpg')}
              heading={t('main.mainCard3.heading')}
              text={t('main.mainCard3.text')}
              link={ENTERPRISE_TODAY_ROUTE}
            />
          </Col>
          <Col lg={4} md={6}>
            <MainPageCard
              img={require('../static/img_4.jpg')}
              heading={t('main.mainCard4.heading')}
              text={t('main.mainCard4.text')}
              link={QUALITY_ROUTE}
            />
          </Col>
          <Col lg={4} md={6}>
            <MainPageCard
              img={require('../static/img_5.jpg')}
              heading={t('main.mainCard5.heading')}
              text={t('main.mainCard5.text')}
              link={AWARDS_ROUTE}
            />
          </Col>
          <Col lg={4} md={6}>
            <MainPageCard
              img={require('../static/img_6.jpg')}
              heading={t('main.mainCard6.text')}
              text={t('main.mainCard6.text')}
              link={TRADE_UNION_ROUTE}
            />
          </Col>
        </Row>
      </section>
      <section>
        <ImgBlock
          img={'img/slider_2.jpg'}
          heading={t('main.eco.heading')}
          text={t('main.eco.text')}
          size={'s'}
        />
      </section>
      <section className="mt-5 container">
        <Row>
          {ecoCards.map((card) => (
            <Col key={card.heading}>
              <EcoCard
                heading={card.heading}
                icon={card.icon}
                link={ECO_ROUTE + card.link}
              />
            </Col>
          ))}
        </Row>
      </section>
      <section>
        <ImgBlock
          img={'img/slider_4.jpg'}
          heading={t('main.news')}
          size={'s'}
        />
      </section>
      <section>
        <NewsBlock />
      </section>
      <section className="container mb-5 d-flex justify-content-center">
        <video
          className="text-center item video"
          controls="controls"
          poster={require('../static/LOGO.png')}
        >
          <source src={require('../static/Melenka.mp4')} />
        </video>
      </section>
    </div>
  );
};

export default Main;
