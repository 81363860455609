import React from 'react';
import { Col, Image } from 'react-bootstrap';

const CertificateCard = ({ img }) => {
  return (
    <Col
      lg={3}
      md={4}
      sm={6}
      xs={12}
      className="d-flex justify-content-center my-3"
    >
      <a
        href={process.env.REACT_APP_API_URL + img}
        target="_blank"
        className="d-flex justify-content-center"
      >
        <Image
          className="certificate__img"
          src={process.env.REACT_APP_API_URL + img}
        />
      </a>
    </Col>
  );
};

export default CertificateCard;
