import { $authHost, $host } from './index';

export const createStoreBrand = async (storeId, brandId) => {
  const { data } = await $authHost.post('api/storeBrand', {storeId, brandId});
  return data;
};

export const fetchAllStoreBrand = async (storeId) => {
  const { data } = await $host.get('api/storeBrand?storeId=' + storeId);
  return data;
};

export const deleteStoreBrand = async (id) => {
  const { data } = await $authHost.delete('api/storeBrand/' + id);
  return data;
};
