import { $authHost, $host } from './index';

export const createBrand = async (name, localeId) => {
  const { data } = await $authHost.post('api/brand', {name, localeId});
  return data;
};

export const fetchAllBrand = async () => {
  const { data } = await $host.get('api/brand');
  return data;
};

export const fetchAllBrandLang = async (id) => {
  const { data } = await $host.get('api/brand/locale/' + id);
  return data;
};

export const fetchBrand = async (id) => {
  const { data } = await $host.get('api/brand/' + id);
  return data;
};

export const deleteBrand = async (id) => {
  const { data } = await $authHost.delete('api/brand/' + id);
  return data;
};

export const createBrandInfo = async (name, localeId, brandId) => {
  const { data } = await $authHost.post('api/brand/info/', {name, localeId, brandId});
  return data;
};

export const updateBrandInfo = async (id, name, localeId) => {
  const { data } = await $authHost.put('api/brand/info/' + id, { name, localeId });
  return data;
};

export const deleteBrandInfo = async (id) => {
  const { data } = await $authHost.delete('api/brand/info/' + id);
  return data;
};
