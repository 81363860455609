import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { createRequest } from '../../http/requestApi';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import useInput from '../../utils/hooks/useInput';
import ErrorText from '../ErrorText';

const CreateRequest = ({ show, onHide, user }) => {
  const [agree, setAgree] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  const fullName = useInput('', { isEmpty: true });
  const phone = useInput('', { isEmpty: true, isPhone: true });
  const mail = useInput('', { isEmpty: true, isMail: true });

  const [disabledButton, setDisableButton] = useState(true);

  const { locale } = useContext(Context);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
  }, [locale, locale.selectLang]);

  useEffect(() => {
    fullName.inputValid &&
    mail.inputValid &&
    phone.inputValid &&
    agree &&
    selectedType
      ? setDisableButton(false)
      : setDisableButton(true);
  }, [fullName, phone, mail, agree, selectedType]);

  const addRequest = () => {
    createRequest(fullName.value, phone.value, mail.value, selectedType).then(
      (data) => {
        onHide();
        fullName.clear();
        phone.clear();
        mail.clear();
        setSelectedType('');
        setAgree(false);
      }
    );
  };
  const { t } = useTranslation();
  const type = t('request.type', { returnObjects: true });

  return (
    <Modal show={show} onHide={onHide} size={user ? 'md' : 'lg'} centered>
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={user ? 'text-center' : ''}
        >
          {user ? t('request.title') : 'Добавить заявку'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle>
              {/* <Dropdown.Toggle className={error === 'brand' ? 'error' : ''}> */}
              {selectedType ? selectedType : t('request.selectType')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {type.map((type) => (
                <Dropdown.Item
                  key={type.name}
                  onClick={() => {
                    setSelectedType(type.name);
                    // setError('');
                  }}
                >
                  {type.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Form.Control
            className={fullName.isDirty && fullName.isEmpty && 'error'}
            value={fullName.value}
            onChange={(e) => fullName.onChange(e)}
            onBlur={(e) => fullName.onBlur(e)}
            placeholder={user ? t('request.name') : 'Введите полное имя'}
          />
          {fullName.isDirty &&
            fullName.isEmpty &&
            ErrorText(t('error.fullName'))}
          <Form.Control
            className={
              ((phone.isDirty && phone.isEmpty && 'error') ||
                (phone.isDirty && phone.phoneError && 'error')) + ' mt-3'
            }
            value={phone.value}
            onChange={(e) => phone.onChange(e)}
            onBlur={(e) => phone.onBlur(e)}
            placeholder={user ? t('request.phone') : 'Введите телефон...'}
          />
          {phone.isDirty && phone.isEmpty && ErrorText(t('error.phone'))}
          {phone.isDirty && phone.phoneError && ErrorText(t('error.format'))}
          <Form.Control
            className={
              ((mail.isDirty && mail.mailError && ' error') ||
                (mail.isDirty && mail.isEmpty && ' error')) + ' mt-3'
            }
            value={mail.value}
            type="email"
            onChange={(e) => mail.onChange(e)}
            onBlur={(e) => mail.onBlur(e)}
            placeholder={user ? t('request.mail') : 'Введите почту...'}
          />
          {mail.isDirty && mail.isEmpty && ErrorText(t('error.mail'))}
          {mail.isDirty && mail.mailError && ErrorText(t('error.format'))}
          {user ? (
            <Form.Check
              value={agree}
              onChange={(e) => {
                setAgree(e.target.checked);
              }}
              className={' mt-3'}
              type={'checkbox'}
              label={
                <span>
                  {t('request.agreement')}
                  <a
                    href={
                      process.env.REACT_APP_API_URL +
                      'Политика_обработки_персональных_данных.pdf'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('request.terms')}
                  </a>
                </span>
              }
            />
          ) : (
            ''
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          {t('request.close')}
        </Button>
        <Button
          variant="outline-success"
          disabled={disabledButton}
          onClick={addRequest}
        >
          {user ? t('request.send') : 'Добавить'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateRequest;
