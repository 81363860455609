import React from 'react';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const VacancyCard = ({
  title,
  requiredSpecialty,
  wageMin,
  wageMax,
  workingConditions,
  requiredExperienceFrom,
  requiredExperienceUpTo,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="item">
        <div className="p-3">
          <h2 className="heading">
            <b>{title}</b>
          </h2>
          <p>
            <b>{t('vacancy.speciality')}</b> {requiredSpecialty}
          </p>
          <p>
            <b>{t('vacancy.wage')}</b>
            {wageMin ? t('vacancy.from') + wageMin : ''}
            {wageMax ? t('vacancy.to') + wageMax : ''}{' '}
            {wageMin || wageMax ? t('vacancy.currency') : t('vacancy.noWage')}
          </p>
          <p>
            <b>{t('vacancy.experience')}</b>
            {requiredExperienceFrom
              ? t('vacancy.from') + requiredExperienceFrom
              : ''}
            {requiredExperienceUpTo
              ? t('vacancy.to') + requiredExperienceUpTo
              : ''}{' '}
            {requiredExperienceFrom || requiredExperienceUpTo
              ? t('vacancy.years')
              : t('vacancy.withoutExperience')}
          </p>
          <p>
            <b>{t('vacancy.conditions')}</b> {workingConditions}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VacancyCard;
