import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { fetchAllNews } from '../../http/newsApi';
import { Context } from '../../index';
import Pages from '../../components/Pages';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { Col, Row, Spinner, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  TRADE_UNION_ROUTE,
  TRADE_UNION_STRUCTURE_ROUTE,
} from '../../utils/consts';
import NewsCardVI from '../../components/VI/NewsCardVI';

const TradeUnionVI = observer(() => {
  const { news, page, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    fetchAllNews(2, locale.selectLang, page.limit, page.page)
      .then((data) => {
        news.setNews(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [
    news,
    page.page,
    page.limit,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const { t } = useTranslation();
  document.title = t('tradeUnion.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div
      className={
        'container__VI pb-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="d-flex justify-content-center">{t('tradeUnion.title')}</h1>
      <h3 className="d-flex justify-content-center">{t('tradeUnion.news')}</h3>
      <Col>
        <Link to={TRADE_UNION_STRUCTURE_ROUTE} className="link__VI  ">
          {t('tradeUnion.structure')}
        </Link>
      </Col>
      <Col className="d-flex justify-content-end mb-3">
        <Pages />
      </Col>
      {news.news?.map((news) =>
        news.newsInfos?.map((newsInfo) => (
          <NewsCardVI
            key={newsInfo.id}
            title={newsInfo.title}
            newsText={newsInfo.newsText}
            date={news.createdAt}
            img={news.photo ? process.env.REACT_APP_API_URL + news.photo : ''}
            link={TRADE_UNION_ROUTE + '/' + news.id}
          />
        ))
      )}
    </div>
  );
});

export default TradeUnionVI;
