import { $authHost, $host } from './index';

const path = 'api/recipe/';

export const createRecipe = async (recipe) => {
  const { data } = await $authHost.post(path, recipe);
  return data;
};

export const fetchAllRecipe = async (limit = 5, page = 1) => {
  const { data } = await $host.get(path, { params: { limit, page } });
  return data;
};

export const fetchAllRecipeLang = async (id, limit = 5, page = 1) => {
  const { data } = await $host.get(path + 'locale/' + id, {
    params: { limit, page },
  });
  return data;
};

export const fetchRecipeLang = async (id, localeId) => {
  const { data } = await $host.get(path + id + '/' + localeId);
  return data;
};

export const fetchRecipe = async (id) => {
  const { data } = await $host.get(path + id);
  return data;
};

export const updateRecipe = async (id, recipe) => {
  const { data } = await $authHost.put(path + id, recipe);
  return data;
};

export const deleteRecipe = async (id) => {
  const { data } = await $authHost.delete(path + id);
  return data;
};

export const createRecipeInfo = async (
  name,
  ingredients,
  manual,
  localeId,
  recipeId
) => {
  const { data } = await $authHost.post(path + 'info', {
    name,
    ingredients,
    manual,
    localeId,
    recipeId,
  });
  return data;
};

export const updateRecipeInfo = async (
  id,
  name,
  ingredients,
  manual,
  localeId
) => {
  const { data } = await $authHost.put(path + 'info/' + id, {
    name,
    ingredients,
    manual,
    localeId,
  });
  return data;
};

export const deleteRecipeInfo = async (id, recipeId) => {
  const { data } = await $authHost.delete(path + 'info/' + id, {
    data: { recipeId },
  });
  return data;
};
