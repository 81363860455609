import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const ProductCard = ({ img, name, ingredients, link }) => {
  const { t } = useTranslation();
  return (
    <div className="recipe_card">
      <div className="item item__product p-3">
        <h3 className="">{name}</h3>
        <Row>
          <Col md={12} lg={6}>
            <div className="img-pruduct_wrap ps=3">
              <Image src={img} className="img img-recipe" />
            </div>
          </Col>
          <Col>
            <p className="recipe-card-text pt-3">{ingredients}</p>
            <Link to={link} className="btn__card float-end">
              {t('recipes.more')}
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductCard;
