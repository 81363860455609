import React, { useContext, useState } from 'react';
import { Button, Form, Dropdown, Modal } from 'react-bootstrap';
import { createBrand, createBrandInfo } from '../../http/brandApi';
import { Context } from '../../index';

const CreateBrand = ({ show, onHide }) => {
  const { brand, locale } = useContext(Context);
  const [toggle, setToggle] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [name, setName] = useState();
  const [error, setError] = useState('');
  const [lang, setLang] = useState();

  const submitHandler = (type) => {
    type === 'info'
      ? createBrandInfo(name, lang ? lang.id : 1, selectedBrand.id).then(
          (data) => {
            brand.updateBrand(data.id, data);
          }
        )
      : createBrand(name, lang ? lang.id : 1).then((data) => {
          brand.addBrand(data);
        });
    setName('');
    setSelectedBrand(null);
    onHide();
  };

  const addBrand = () => {
    name ? (lang ? submitHandler() : setError('lang')) : setError('name');
  };

  const addBrandInfo = () => {
    selectedBrand
      ? name
        ? lang
          ? submitHandler('info')
          : setError('lang')
        : setError('name')
      : setError('brand');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить производителя
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle className={error === 'brand' ? 'error' : ''}>
                {selectedBrand
                  ? selectedBrand.brandInfos[0].name
                  : 'Выберите производителя'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {brand.brand.map((brand) =>
                  brand.brandInfos.map((info) =>
                    info.localeId === 1 ? (
                      <Dropdown.Item
                        key={info.id}
                        onClick={() => {
                          setSelectedBrand(brand);
                          setError('');
                        }}
                      >
                        {info.name}
                      </Dropdown.Item>
                    ) : (
                      ''
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ''
          )}
          <Form.Control
            value={name}
            className={error === 'name' ? 'error' : ''}
            onChange={(e) => {
              setName(e.target.value);
              setError('');
            }}
            placeholder={'Введите название производителя...'}
          />
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={onHide}>
            Закрыть
          </Button>
          <Button
            variant="outline-success"
            onClick={toggle ? addBrandInfo : addBrand}
          >
            Добавить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default CreateBrand;
