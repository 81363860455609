import React, { useContext, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { createCategory, createCategoryInfo } from '../../http/categoryApi';
import { Context } from '../../index';

const CreateCategory = ({ show, onHide }) => {
  const { category, locale } = useContext(Context);
  const [toggle, setToggle] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [name, setName] = useState('');
  const [lang, setLang] = useState();
  const [error, setError] = useState('');

  const submitHandler = (type) => {
    type === 'info'
      ? createCategoryInfo(name, lang.id, selectedCategory.id).then((data) => {
          setName('');
          setSelectedCategory(null);
          onHide();
          category.updateСategory(data.id, data);
        })
      : createCategory(name, lang.id).then((data) => {
          setName('');
          onHide();
          category.addСategory(data);
        });
  };

  const addСategory = (e) => {
    e.preventDefault();
    name ? (lang ? submitHandler() : setError('lang')) : setError('name');
  };

  const addСategoryInfo = (e) => {
    e.preventDefault();
    selectedCategory
      ? name
        ? lang
          ? submitHandler('info')
          : setError('lang')
        : setError('name')
      : setError('category');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить тип продукции
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle className={error === 'category' ? 'error' : ''}>
                {selectedCategory
                  ? selectedCategory.categoryInfos[0].name
                  : 'Выберите тип'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {category.category.map((category) =>
                  category.categoryInfos.map((info) =>
                    info.localeId === 1 ? (
                      <Dropdown.Item
                        key={info.id}
                        onClick={() => {
                          setSelectedCategory(category);
                          setError('');
                        }}
                      >
                        {info.name}
                      </Dropdown.Item>
                    ) : (
                      ''
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ''
          )}
          <Form.Control
            value={name}
            className={error === 'name' ? 'error' : ''}
            onChange={(e) => {
              setName(e.target.value);
              setError('');
            }}
            placeholder={'Введите название типа...'}
          />
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          onClick={toggle ? addСategoryInfo : addСategory}
        >
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCategory;
