import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';

const AwardCardVI = ({ img, text }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <Image
        src={process.env.REACT_APP_API_URL + img}
        className="img"
      />
      <div className="">
        <p className="award-text">{text}</p>
      </div>
    </div>
  );
};

export default AwardCardVI;
