import { makeAutoObservable } from 'mobx';

export default class VIStore {
  constructor() {
    this._VI = false;
    this._tSize = 1;
    this._pColor = 1;
    this._colors = 1;
    this._interval = 1;
    this._kerning = 1;
    makeAutoObservable(this);
  }

  setVI(VI) {
    this._VI = VI;
  }

  setTSize(tSize) {
    this._tSize = tSize;
  }

  setPColor(pColor) {
    this._pColor = pColor;
  }

  setColors(colors) {
    this._colors = colors;
  }

  setInterval(interval) {
    this._interval = interval;
  }

  setKerning(kerning) {
    this._kerning = kerning;
  }

  get VI() {
    return this._VI;
  }
  
  get tSize() {
    return this._tSize;
  }

  get pColor() {
    return this._pColor;
  }

  get colors() {
    return this._colors;
  }

  get interval() {
    return this._interval;
  }

  get kerning() {
    return this._kerning;
  }
}
