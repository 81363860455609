import { makeAutoObservable } from 'mobx';

export default class NewsPhotoStore {
  constructor() {
    this._newsPhoto = [];
    makeAutoObservable(this);
  }

  setNewsPhoto(newsPhoto) {
    this._newsPhoto = newsPhoto;
  }

  addNewsPhoto(newsPhoto) {
    this._newsPhoto.push(newsPhoto);
  }

  updateNewsPhoto(id, data) {
    this._newsPhoto = this._newsPhoto.map((newsPhoto) =>
      newsPhoto.id === id ? { ...data } : { ...newsPhoto }
    );
  }

  removeNewsPhoto(id) {
    this._newsPhoto = this._newsPhoto.filter((newsPhoto) => newsPhoto.id !== id);
  }

  get newsPhoto() {
    return this._newsPhoto;
  }
}
