export const insertTab = (e, fn, str) => {
  if (e.keyCode === 9) {
    e.preventDefault();
    const { selectionStart, selectionEnd } = e.target;
    fn(str.substring(0, selectionStart) + '\t' + str.substring(selectionEnd));
    setTimeout(() => {
      e.target.selectionStart = e.target.selectionEnd = selectionEnd + 1;
    });
  }
};
