import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { Button, Container, Form, Row, Table } from 'react-bootstrap';

import {
  fetchAllCategory,
  updateNewsCategory,
} from '../../http/newsCategoryApi';

const NewsCategory = observer(() => {
  const [editId, setEditId] = useState('');
  const [editName, setEditName] = useState('');

  const { newsCategory } = useContext(Context);

  useEffect(() => {
    fetchAllCategory().then((data) => newsCategory.setCategory(data));
  }, [newsCategory]);

  const editToggle = (id, name) => {
    setEditId(id);
    setEditName(name);
  };

  const editCategory = () => {
    updateNewsCategory(editId, editName).then((data) =>
      newsCategory.updateCategory(data.id, data)
    );
    setEditId('');
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Категории новостей</h1>
      <Row>
        <Table>
          <thead>
            <tr>
              <th className="col-1">#</th>
              <th>Название</th>
              <th className="col-1"></th>
            </tr>
          </thead>
          <tbody>
            {newsCategory.newsCategory.map((category) =>
              category.id === editId ? (
                <tr key={category.id}>
                  <td>{category.id}</td>
                  <td>
                    <Form.Control
                      className="form-control-sm"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      placeholder={'Введите название локализации'}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      className="me-2"
                      onClick={() => editToggle('', '')}
                    >
                      <i className="bi bi-x-lg"></i>
                    </Button>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => editCategory()}
                    >
                      <i className="bi bi-check2"></i>
                    </Button>
                  </td>
                </tr>
              ) : (
                <tr key={category.id}>
                  <td>{category.id}</td>
                  <td>{category.name}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => editToggle(category.id, category.name)}
                    >
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
});

export default NewsCategory;
