import React, { useEffect, useState } from 'react';

const useValidation = (value, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [mailError, setMailError] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [phoneError, setProneError] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case 'isEmpty':
          value ? setEmpty(false) : setEmpty(true);
          break;
        case 'isMail':
          const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          re.test(String(value).toLowerCase())
            ? setMailError(false)
            : setMailError(true);
          break;
        case 'isPhone':
          const reg =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
          reg.test(String(value).toLowerCase())
            ? setProneError(false)
            : setProneError(true);
          break;
      }
    }
  }, [value]);

  useEffect(() => {
    if (isEmpty || mailError || phoneError) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [isEmpty, mailError, phoneError]);
  return { isEmpty, mailError, phoneError, inputValid };
};

export default useValidation;
