import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
  Table,
} from 'react-bootstrap';
import {
  deleteStore,
  deleteStoreInfo,
  fetchAllStore,
  fetchAllStoreLang,
  updateStore,
  updateStoreInfo,
} from '../../http/storeApi';
import { Context } from '../../index';
import CreateStore from '../../components/modals/CreateStore';
import Pages from '../../components/Pages';
import PageSize from '../../components/PageSize';
import { Link } from 'react-router-dom';
import { ADMIN_STORE_ROUTE } from '../../utils/consts';

const Brand = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editInfoId, setInfoEditId] = useState('');
  const [editName, setEditName] = useState('');
  const [editAddress, setEditAddress] = useState('');
  const [editFile, setEditFile] = useState(null);
  const [lang, setLang] = useState();

  const { store, page, locale } = useContext(Context);

  useEffect(() => {
    fetchAllStore(page.limit, page.page).then((data) => {
      store.setStore(data.rows);
      page.setTotalCount(data.count);
    });
    fetchAllStoreLang(1).then((data) => store.setStoreLang(data));
  }, [store, page.page, page.limit]);

  const selectFile = (e) => {
    setEditFile(e.target.files[0]);
  };

  const editToggle = (id) => {
    setEditId(id);
  };

  const editInfoToggle = (id, info) => {
    setEditName(info.name);
    setEditAddress(info.address);
    setLang(info.locale);
    setInfoEditId(id);
  };

  const editStore = () => {
    const formData = new FormData();
    formData.append('photo', editFile);
    updateStore(editId, formData).then((data) => {
      store.updateStore(data.id, data);
      setEditId('');
    });
  };

  const editStoreInfo = () => {
    updateStoreInfo(editInfoId, editName, editAddress, lang.id).then((data) => {
      setInfoEditId('');
      store.updateStore(data.id, data);
    });
  };

  const delStore = (id) => {
    deleteStore(id);
    store.removeStore(id);
  };

  const delStoreInfo = (id, storeId) => {
    deleteStoreInfo(id, storeId).then((data) => {
      store.updateStore(data.id, data);
    });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Магазины</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <PageSize />
        </Col>
        <Col className="d-flex justify-content-end">
          <Pages />
        </Col>
      </Row>

      <Row>
        {store.store.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th className="col-3">Фото</th>
                <th>Информация</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {store.store?.map((store) => (
                <tr key={store.id}>
                  <td>
                    <Link to={ADMIN_STORE_ROUTE + '/' + store.id}>
                      {store.id}
                    </Link>
                  </td>
                  <td>
                    {store.id === editId ? (
                      <Form.Control
                        onChange={selectFile}
                        className="form-control-sm"
                        type="file"
                      />
                    ) : store.photo ? (
                      <div className="photo-wrapper">
                        <Image
                          src={process.env.REACT_APP_API_URL + store.photo}
                          className="photo-mini"
                        />
                      </div>
                    ) : (
                      'нет фото'
                    )}
                  </td>
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th className="col-4">Название</th>
                          <th className="col-4">Адрес</th>
                          <th className="col-2">Язык</th>
                          <th className="col-2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {store.storeInfos?.map((storeInfo) =>
                          storeInfo.id === editInfoId ? (
                            <tr key={storeInfo.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editName}
                                  onChange={(e) => setEditName(e.target.value)}
                                  placeholder={'Введите название магазина...'}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editAddress}
                                  onChange={(e) =>
                                    setEditAddress(e.target.value)
                                  }
                                  placeholder={'Введите адрес...'}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => editInfoToggle('', '')}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editStoreInfo()}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={storeInfo.id}>
                              <td>{storeInfo.name}</td>
                              <td>{storeInfo.address}</td>
                              <td>{storeInfo.locale?.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2"
                                  onClick={() =>
                                    editInfoToggle(storeInfo.id, storeInfo)
                                  }
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    delStoreInfo(storeInfo.id, store.id)
                                  }
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  {store.id === editId ? (
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        className="me-2"
                        onClick={() => editToggle('', '')}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => editStore()}
                      >
                        <i className="bi bi-check2"></i>
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        variant="warning"
                        className="mb-2"
                        size="sm"
                        onClick={() => editToggle(store.id)}
                      >
                        Изменить
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => delStore(store.id)}
                      >
                        Удалить
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateStore
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Brand;
