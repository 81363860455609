import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import {
  deleteRecipe,
  deleteRecipeInfo,
  fetchAllRecipe,
  updateRecipe,
  updateRecipeInfo,
} from '../../http/recipeApi';
import { Context } from '../../index';
import CreateRecipe from '../../components/modals/CreateRecipe';
import Pages from '../../components/Pages';
import PageSize from '../../components/PageSize';
import { insertTab } from '../../utils/insertTab';

const Recipe = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editInfoId, setEditInfoId] = useState('');
  const [editName, setEditName] = useState('');
  const [editIngredients, seteditIngredients] = useState('');
  const [editManual, setEditManual] = useState('');
  const [editTime, setEditTime] = useState('');
  const [editFile, setFile] = useState(null);
  const [lang, setLang] = useState(null);
  const [loading, setLoading] = useState(true);

  const { recipe, page, locale } = useContext(Context);

  useEffect(() => {
    fetchAllRecipe(page.limit, page.page)
      .then((data) => {
        recipe.setRecipe(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [recipe, page.page, page.limit, page]);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const editToggle = (id, recipe) => {
    setEditId(id);
    setEditTime(recipe.time);
  };

  const editInfoToggle = (id, info) => {
    setEditInfoId(id);
    setEditName(info.name);
    seteditIngredients(info.ingredients);
    setEditManual(info.manual);
    setLang(info.locale);
  };

  const editProduct = () => {
    const formData = new FormData();
    formData.append('time', editTime);
    formData.append('photo', editFile);
    updateRecipe(editId, formData).then((data) => {
      recipe.updateRecipe(data.id, data);
      setEditId('');
    });
  };

  const editProductInfo = () => {
    updateRecipeInfo(
      editInfoId,
      editName,
      editIngredients,
      editManual,
      lang.id
    ).then((data) => {
      recipe.updateRecipe(data.id, data);
      setEditInfoId('');
    });
  };

  const delProduct = (id) => {
    deleteRecipe(id)
      .then(() => {
        recipe.removeRecipe(id);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  const delProductInfo = (id, recipeId) => {
    deleteRecipeInfo(id, recipeId)
      .then((data) => {
        recipe.updateRecipe(data.id, data);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  if (loading) return <Spinner animation={'grow'} />;

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Рецепты</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <PageSize />
        </Col>
        <Col className="d-flex justify-content-end">
          <Pages />
        </Col>
      </Row>

      <Row>
        {recipe.recipe.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th className="col-2">Фото</th>
                <th className="col-1">Время (ч)</th>
                <th>Информация</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {recipe.recipe?.map((recipe) => (
                <tr key={recipe.id}>
                  <td>{recipe.id}</td>
                  {recipe.id === editId ? (
                    <>
                      <td>
                        <Form.Control
                          onChange={selectFile}
                          className="form-control-sm"
                          type="file"
                        />
                      </td>
                      <td>
                        <Form.Control
                          className="form-control-sm"
                          value={editTime}
                          onChange={(e) => setEditTime(e.target.value)}
                          placeholder={'Введите время готовки...'}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <div className="photo-wrapper">
                          {recipe.photo ? (
                            <Image
                              src={process.env.REACT_APP_API_URL + recipe.photo}
                              className="photo-mini"
                            />
                          ) : (
                            'нет фото'
                          )}
                        </div>
                      </td>
                      <td>{recipe.time}</td>
                    </>
                  )}
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Название</th>
                          <th>Ингредиенты</th>
                          <th>Рецепт</th>
                          <th>Язык</th>
                          <th className="col-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {recipe.recipeInfos?.map((info) =>
                          info.id === editInfoId ? (
                            <tr key={info.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editName}
                                  onChange={(e) => setEditName(e.target.value)}
                                  placeholder={'Введите название продукта...'}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editIngredients}
                                  onChange={(e) =>
                                    seteditIngredients(e.target.value)
                                  }
                                  onKeyDown={(e) =>
                                    insertTab(
                                      e,
                                      seteditIngredients,
                                      editIngredients
                                    )
                                  }
                                  placeholder={'Введите ингрииенты...'}
                                  as="textarea"
                                  rows={5}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editManual}
                                  onChange={(e) =>
                                    setEditManual(e.target.value)
                                  }
                                  placeholder={'Введите рецепт...'}
                                  as="textarea"
                                  onKeyDown={(e) =>
                                    insertTab(e, setEditManual, editManual)
                                  }
                                  rows={5}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="mb-2"
                                  onClick={() => editInfoToggle('', '')}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editProductInfo()}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={info.id}>
                              <td>{info.name}</td>
                              <td>{info.ingredients}</td>
                              <td>{info.manual}</td>
                              <td>{info.locale?.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2 mb-2"
                                  onClick={() => editInfoToggle(info.id, info)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    delProductInfo(info.id, recipe.id)
                                  }
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  {recipe.id === editId ? (
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        className="me-2"
                        onClick={() => editToggle('', '')}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => editProduct()}
                      >
                        <i className="bi bi-check2"></i>
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-2 mb-2"
                        onClick={() => editToggle(recipe.id, recipe)}
                      >
                        Изменить
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => delProduct(recipe.id)}
                      >
                        Удалить
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateRecipe
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Recipe;
