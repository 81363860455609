import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Dropdown, Form, Row, Table } from 'react-bootstrap';
import {
  deleteBrand,
  deleteBrandInfo,
  fetchAllBrand,
  updateBrandInfo,
} from '../../http/brandApi';
import { Context } from '../../index';
import CreateBrand from '../../components/modals/CreateBrand';

const Brand = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editName, setEditName] = useState('');
  const [lang, setLang] = useState();

  const { brand, locale } = useContext(Context);

  useEffect(() => {
    fetchAllBrand().then((data) => brand.setBrand(data.rows));
  }, [brand]);

  const delBrand = (id) => {
    deleteBrand(id)
      .then((data) => {
        brand.removeBrand(id);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  const delBrandInfo = (id) => {
    deleteBrandInfo(id)
      .then((data) => {
        brand.setBrand(data);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  const editToggle = (id, brand) => {
    setEditId(id);
    setEditName(brand.name);
    setLang(brand.locale);
  };

  const editBrandInfo = () => {
    updateBrandInfo(editId, editName, lang.id).then((data) => {
      setEditId('');
      brand.updateBrand(data.id, data);
    });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Производители</h1>
      <Button variant="outline-success" onClick={() => setCreateVisible(true)}>
        <i className="bi bi-plus-square"></i> Добавить
      </Button>
      <Row>
        {brand.brand.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th>Информация</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {brand.brand.map((brand) => (
                <tr key={brand.id}>
                  <td>{brand.id}</td>
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Название</th>
                          <th className="col-2">Язык</th>
                          <th className="col-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {brand.brandInfos?.map((brandInfo) =>
                          brandInfo.id === editId ? (
                            <tr key={brandInfo.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editName}
                                  onChange={(e) => setEditName(e.target.value)}
                                  placeholder={
                                    'Введите название производителя...'
                                  }
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => editToggle()}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editBrandInfo()}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={brandInfo.id}>
                              <td>{brandInfo.name}</td>
                              <td>{brandInfo.locale?.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2"
                                  onClick={() =>
                                    editToggle(brandInfo.id, brandInfo)
                                  }
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => delBrandInfo(brandInfo.id)}
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => delBrand(brand.id)}
                    >
                      Удалить
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateBrand
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Brand;
