import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import PersoneCard from '../../components/PersoneCard';
import { fetchAllEnterpriseAdministrationLang } from '../../http/enterpriseAdministrationApi';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import PersoneCardVI from '../../components/VI/PersoneCardVI';

const StructureVI = observer(() => {
  const { enterpriseAdministration, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAllEnterpriseAdministrationLang(locale.selectLang)
      .then((data) => {
        enterpriseAdministration.setEnterpriseAdministration(data);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [
    enterpriseAdministration,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  document.title = t('structure.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );
  return (
    <div
      className={
        'container__VI pb-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="text-center mb-3">{t('structure.title')}</h1>
      {enterpriseAdministration.enterpriseAdministration?.map(
        (enterpriseAdministration) =>
          enterpriseAdministration.enterpriseAdministrationInfos?.map(
            (info) => (
              <Col key={info.id}>
                <PersoneCardVI
                  phone={enterpriseAdministration.phone}
                  mail={enterpriseAdministration.mail}
                  fullName={info.fullName}
                  position={info.position}
                  about={info.about}
                />
              </Col>
            )
          )
      )}
    </div>
  );
});

export default StructureVI;
