import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Spinner, Image } from 'react-bootstrap';
import RecipeCard from '../../components/VI/RecipeCard';
import Pages from '../../components/VI/Pages';
import { fetchAllRecipeLang } from '../../http/recipeApi';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { RECIPE_ROUTE } from '../../utils/consts';

const RecipesVI = observer(() => {
  const { recipe, page, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    page.setLimit(10);
    fetchAllRecipeLang(locale.selectLang, page.limit, page.page)
      .then((data) => {
        recipe.setRecipe(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [
    recipe,
    page.limit,
    page.page,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const { t } = useTranslation();
  document.title = t('recipes.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <div className="p-3">
        <Row>
          <Col className="d-flex mb-3" xs={12} md={2}>
            <Pages />
          </Col>
        </Row>
        <Row>
          {recipe.recipe.length === 0 ? (
            <div className="m-5 p-5 d-flex justify-content-center">
              <h1>not found</h1>
            </div>
          ) : (
            ''
          )}
          {recipe.recipe?.map((recipe) =>
            recipe.recipeInfos.map((info) => {
              return (
                <div key={info.id}>
                  <RecipeCard
                    id={recipe.id}
                    img={process.env.REACT_APP_API_URL + recipe.photo}
                    name={info.name}
                    ingredients={info.ingredients}
                    link={RECIPE_ROUTE + '/' + recipe.id}
                  />
                </div>
              );
            })
          )}
        </Row>
      </div>
    </div>
  );
});

export default RecipesVI;
