import React, { useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import CertificateCard from '../../components/CertificateCard';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const QualityVI = observer(() => {
  const { vi } = useContext(Context);
  useEffect(() => {}, [
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  document.title = t('quality.pageTitle');
  return (
    <div
      className={
        'container__VI pb-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="text-center">{t('quality.title')}</h1>
      <Row>
        <CertificateCard img="4-4.jpg" />
        <CertificateCard img="4-1.jpg" />
        <CertificateCard img="4-2.jpg" />
        <CertificateCard img="4-3.jpg" />
        <CertificateCard img="sert-1.jpg" />
        <CertificateCard img="sert-2.jpg" />
        <CertificateCard img="sert-3.jpg" />
        <CertificateCard img="sert-5.jpg" />
        <CertificateCard img="sert-4-1.jpg" />
        <CertificateCard img="sert-4-2.jpg" />
        <CertificateCard img="sert-6.jpg" />
        <CertificateCard img="sert-7.jpg" />
      </Row>
    </div>
  );
});

export default QualityVI;
