import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NewsCard = ({ img, title, newsText, link, id, date }) => {
  return (
    <div id={id}>
      <div className="item pe-3">
        <Row>
          <Col>
            <h4 className="p-3">{title}</h4>
          </Col>
        </Row>
        <Row>
          {img ? (
            <Col>
              <Image src={img} className="img img-news" />
            </Col>
          ) : (
            <></>
          )}
          <Col md={8} sm={12}>
            <div className="ps-3">
              <p className="news-card-text">{newsText}</p>
              <Link to={link} className="btn__card">
                Подробнее
                {/* {t('product.more')} */}
              </Link>
              <p className="news-date">{new Date(date).toLocaleDateString()}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewsCard;
