import { makeAutoObservable } from 'mobx';

export default class RequestStore {
  constructor() {
    this._requests = [];
    makeAutoObservable(this);
  }

  setRequest(request) {
    this._requests = request;
  }

  addRequest(request) {
    this._requests.push(request);
  }

  updateRequest(id, data) {
    this._requests = this._requests.map((request) =>
      request.id === id ? { ...data } : { ...request }
    );
  }

  removeRequest(id) {
    this._requests = this._requests.filter((request) => request.id !== id);
  }

  get request() {
    return this._requests;
  }
}
