import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Spinner, Image } from 'react-bootstrap';
import RecipeCard from '../components/RecipeCard';
import Pages from '../components/Pages';
import { fetchAllRecipeLang } from '../http/recipeApi';
import { Context } from '../index';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { RECIPE_ROUTE } from '../utils/consts';
import { Helmet } from 'react-helmet';

const Recipes = observer(() => {
  const { recipe, page, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    page.setLimit(10);
    fetchAllRecipeLang(locale.selectLang, page.limit, page.page)
      .then((data) => {
        recipe.setRecipe(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [recipe, page.limit, page.page, locale.selectLang]);

  const { t } = useTranslation();
  document.title = t('recipes.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('recipes.pageTitle')}
          meta={[
            { name: 'description', content: t('recipes.pageDescription') },
          ]}
        />
      </Container>
    );

  return (
    <Container className="mt-5 d-flex flex-column min-vh-100">
      <Helmet
        title={t('recipes.pageTitle')}
        meta={[{ name: 'description', content: t('recipes.pageDescription') }]}
      />
      <div className="p-3">
        <Row>
          <Col className="d-flex mb-3" xs={12} md={2}>
            <Pages />
          </Col>
        </Row>
        <Row>
          {recipe.recipe.length === 0 ? (
            <div className="m-5 p-5 d-flex justify-content-center">
              <h1>not found</h1>
            </div>
          ) : (
            ''
          )}
          {recipe.recipe?.map((recipe, index) =>
            recipe.recipeInfos.map((info) => {
              return (
                <Col
                  key={info.id}
                  xs={12}
                  sm={6}
                  className={index !== 0 && index % 2 === 0 ? 'mt-150' : ''}
                >
                  {index === 0 ? (
                    ''
                  ) : (
                    <Image
                      src={process.env.REACT_APP_API_URL + 'recipe.png'}
                      className="recipe_monogram"
                    />
                  )}
                  <RecipeCard
                    id={recipe.id}
                    img={process.env.REACT_APP_API_URL + recipe.photo}
                    name={info.name}
                    ingredients={info.ingredients}
                    link={RECIPE_ROUTE + '/' + recipe.id}
                  />
                </Col>
              );
            })
          )}
        </Row>
      </div>
    </Container>
  );
});

export default Recipes;
