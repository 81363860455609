import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import CertificateCard from '../components/CertificateCard';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Helmet } from 'react-helmet';

const Quality = () => {
  const { t } = useTranslation();
  document.title = t('quality.pageTitle');
  return (
    <div className="container d-flex flex-column min-vh-100">
      <Helmet
        title={t('quality.pageTitle')}
        meta={[{ name: 'description', content: t('quality.pageDescription') }]}
      />
      <h1 className="text-center mt-5 mb-3">{t('quality.title')}</h1>
      <Row>
        <CertificateCard img="4-4.jpg" />
        <CertificateCard img="sert-2023.jpg" />
        <CertificateCard img="4-1.jpg" />
        <CertificateCard img="4-2.jpg" />
        <CertificateCard img="4-3.jpg" />
        <CertificateCard img="sert-1.jpg" />
        <CertificateCard img="sert-2.jpg" />
        <CertificateCard img="sert-3.jpg" />
        <CertificateCard img="sert-5.jpg" />
        <CertificateCard img="sert-4-1.jpg" />
        <CertificateCard img="sert-4-2.jpg" />
        <CertificateCard img="sert-6.jpg" />
        <CertificateCard img="sert-7.jpg" />
      </Row>
    </div>
  );
};

export default Quality;
