import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';

const MainPageCardVI = ({heading, link }) => {
  const { t } = useTranslation();
  return (
    <div className="py-3 bb">
      <h2 className="pb-3">{heading}</h2>
      <Link to={link} className="link__VI">
        {t('main.more')}
      </Link>
    </div>
  );
};

export default MainPageCardVI;
