import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { fetchAllStoreLang } from '../../http/storeApi';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import '../../utils/i18next';
import StoreCardVI from '../../components/VI/StoreCardVI';

const StoreVI = observer(() => {
  const { store, page, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllStoreLang(locale.selectLang).then((data) => {
      store.setStore(data.rows);
      page.setTotalCount(data.count);
      setLoading(false);
    });
  }, [
    store,
    page,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const { t } = useTranslation();
  document.title = t('store.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div
      className={
        'container__VI py-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <Row>
        {store.store.map((store) =>
          store.storeInfos.map((storeInfo) => (
            <StoreCardVI
              img={process.env.REACT_APP_API_URL + store.photo}
              name={storeInfo.name}
              address={storeInfo.address}
              key={store.id}
            />
          ))
        )}
      </Row>
    </div>
  );
});

export default StoreVI;
