import { makeAutoObservable } from 'mobx';

export default class StoreBrandStore {
  constructor() {
    this._storeBrand = [];
    makeAutoObservable(this);
  }

  setStoreBrand(storeBrand) {
    this._storeBrand = storeBrand;
  }

  addStoreBrand(storeBrand) {
    this._storeBrand.push(storeBrand);
  }

  removeStoreBrand(id) {
    this._storeBrand = this._storeBrand.filter(
      (storeBrand) => storeBrand.id !== id
    );
  }

  get storeBrand() {
    return this._storeBrand;
  }
}
