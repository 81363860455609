import { $authHost, $host } from './index';

export const createCategory = async (name, localeId) => {
  const { data } = await $authHost.post('api/category', {name, localeId});
  return data;
};

export const fetchAllCategory = async () => {
  const { data } = await $host.get('api/category');
  return data;
};

export const fetchAllCategoryLang = async (id) => {
  const { data } = await $host.get('api/category/locale/' + id);
  return data;
};

export const fetchCategory = async (id) => {
  const { data } = await $host.get('api/category/' + id);
  return data;
};

export const deleteCategory = async (id) => {
  const { data } = await $authHost.delete('api/category/' + id);
  return data;
};

export const createCategoryInfo = async (name, localeId, categoryId) => {
  const { data } = await $authHost.post('api/category/info/', {name, localeId, categoryId});
  return data;
};

export const updateCategoryInfo = async (id, name, localeId) => {
  const { data } = await $authHost.put('api/category/info/' + id, { name, localeId });
  return data;
};

export const deleteCategoryInfo = async (id) => {
  const { data } = await $authHost.delete('api/category/info/' + id);
  return data;
};
