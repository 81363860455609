import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Context } from '../index';
import { authRoutes, publicRoutes, publicRoutesVI } from '../utils/routes';
import { MAIN_ROUTE } from '../utils/consts';

const AppRouter = () => {
  const { user, vi } = useContext(Context);
  return (
    <Switch>
      {user.isAuth &&
        authRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} component={Component} exact />
        ))}
      {vi.VI === 'true'
        ? publicRoutesVI.map(({ path, Component }) => (
            <Route key={path} path={path} component={Component} exact />
          ))
        : publicRoutes.map(({ path, Component }) => (
            <Route key={path} path={path} component={Component} exact />
          ))}
      <Redirect to={MAIN_ROUTE} />
    </Switch>
  );
};

export default AppRouter;
