import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const ProductCard = ({ img, name, shortDescription, link }) => {
  const { t } = useTranslation();
  return (
    <div className='product_card'>
      <div className="item item__product pt-3">
        <div className="img-pruduct_wrap">
          <Image src={img} className="img img-pruduct" />
        </div>
        <div className="product_text">
          <h2 className="heading">{name}</h2>
          <p className="description">{shortDescription}</p>
          <Link to={link} className="btn__card">
            {t('product.more')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
