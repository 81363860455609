import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Container, Form, Row, Table } from 'react-bootstrap';
import {
  deleteProductAvailability,
  fetchAllProductAvailability,
  updateProductAvailability,
} from '../../http/productAvailabilityApi';
import { Context } from '../../index';
import { useParams } from 'react-router-dom';
import { fetchProduct, fetchProductLang } from '../../http/productApi';
import CreateProductAvailability from '../../components/modals/CreateProductAvailability';

const ProductAvailability = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editWeight, setEditWeight] = useState(0);
  const [editPrice, setEditPrice] = useState(0);
  const [editAvailable, setEditAvailable] = useState(false);
  const [name, setName] = useState('');
  const { productAvailability, product } = useContext(Context);
  const params = useParams();

  useEffect(() => {
    fetchAllProductAvailability(params.id).then((data) =>
      productAvailability.setProductAvailability(data.rows)
    );
    fetchProductLang(params.id, 1).then((data) => {
      product.setProduct(data);
      setName(product.product.productInfos[0].name);
    });
  }, [productAvailability, product, params.id]);

  const editToggle = (id, weight, price, available) => {
    setEditId(id);
    setEditWeight(weight);
    setEditPrice(price);
    setEditAvailable(available);
  };

  const editProductAvailability = () => {
    updateProductAvailability(
      editId,
      editWeight,
      editPrice,
      editAvailable
    ).then((data) => {
      productAvailability.updateProductAvailability(data.id, data);
      setEditId('');
    });
  };

  const delProductAvailability = (id) => {
    deleteProductAvailability(id);
    productAvailability.removeProductAvailability(id);
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">
        Наличие продукта '{name}'
      </h1>
      <Button variant="outline-success" onClick={() => setCreateVisible(true)}>
        <i className="bi bi-plus-square"></i> Добавить
      </Button>
      <Row>
        {productAvailability.productAvailability.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th className="col-2">Вес</th>
                <th className="col-2">Цена</th>
                <th className="col-2">Наличие</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {productAvailability.productAvailability.map((availability) => (
                <tr key={availability.id}>
                  {availability.id === editId ? (
                    <>
                      <td>{availability.id}</td>
                      <td>
                        <Form.Control
                          className="form-control-sm"
                          value={editWeight}
                          onChange={(e) => setEditWeight(e.target.value)}
                          placeholder={'Введите вес...'}
                          type="number"
                        />
                      </td>
                      <td>
                        <Form.Control
                          className="form-control-sm"
                          value={editPrice}
                          onChange={(e) => setEditPrice(e.target.value)}
                          placeholder={'Введите цену...'}
                          type="number"
                        />
                      </td>
                      <td>
                        <Form.Check
                          checked={editAvailable}
                          onChange={(e) => setEditAvailable(e.target.checked)}
                          type="checkbox"
                        />
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={() => editToggle('', '', '', '')}
                        >
                          <i className="bi bi-x-lg"></i>
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => editProductAvailability()}
                        >
                          <i className="bi bi-check2"></i>
                        </Button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{availability.id}</td>
                      <td>{availability.weight} кг</td>
                      <td>{availability.price} BYN</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={availability.available}
                          disabled
                        />
                      </td>
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2"
                          onClick={() =>
                            editToggle(
                              availability.id,
                              availability.weight,
                              availability.price,
                              availability.available
                            )
                          }
                        >
                          <i className="bi bi-pencil"></i>
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={(e) => {
                            delProductAvailability(availability.id);
                          }}
                        >
                          <i className="bi bi-x-circle"></i>
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateProductAvailability
        productId={params.id}
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default ProductAvailability;
