import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import VacancyCard from '../../components/VacancyCard';
import { Context } from '../../index';
import { fetchAllVacancyLang } from '../../http/vacancyApi';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import VacancyCardVI from '../../components/VI/VacancyCardVI';

const VacancyVI = observer(() => {
  const { vacancy, page, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllVacancyLang(locale.selectLang, page.limit, page.page)
      .then((data) => {
        vacancy.setVacancy(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [
    vacancy,
    page,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const { t } = useTranslation();
  document.title = t('vacancy.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center flex-column min-vh-100">
        <Spinner animation={'grow'} />
      </Container>
    );
  return (
    <div
      className={
        'container__VI pb-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <Row>
        <p>{t('vacancy.contact')}</p>
        <Col>
          {vacancy.vacancy?.map((vacancy) =>
            vacancy.vacancyInfos?.map((vacancyInfo) => (
              <VacancyCardVI
                key={vacancyInfo.id}
                title={vacancyInfo.jobTitle}
                requiredSpecialty={vacancyInfo.requiredSpecialty}
                wageMin={vacancy.wageMin}
                wageMax={vacancy.wageMax}
                workingConditions={vacancyInfo.workingConditions}
                requiredExperienceFrom={vacancy.requiredExperienceFrom}
                requiredExperienceUpTo={vacancy.requiredExperienceUpTo}
              />
            ))
          )}
        </Col>
      </Row>
    </div>
  );
});

export default VacancyVI;
