import { makeAutoObservable } from 'mobx';

export default class EnterpriseAdministrationStore {
  constructor() {
    this._enterpriseAdministration = [];
    this._enterpriseAdministrationInfo = [];
    makeAutoObservable(this);
  }

  setEnterpriseAdministration(enterpriseAdministration) {
    this._enterpriseAdministration = enterpriseAdministration;
  }

  setEnterpriseAdministrationInfo(enterpriseAdministrationInfo) {
    this._enterpriseAdministrationInfo = enterpriseAdministrationInfo;
  }

  addEnterpriseAdministration(enterpriseAdministration) {
    this._enterpriseAdministration.push(enterpriseAdministration);
  }

  updateEnterpriseAdministration(id, data) {
    this._enterpriseAdministration = this._enterpriseAdministration.map(
      (enterpriseAdministration) =>
        enterpriseAdministration.id === id
          ? { ...data }
          : { ...enterpriseAdministration }
    );
  }

  removeEnterpriseAdministration(id) {
    this._enterpriseAdministration = this._enterpriseAdministration.filter(
      (enterpriseAdministration) => enterpriseAdministration.id !== id
    );
  }

  get enterpriseAdministration() {
    return this._enterpriseAdministration;
  }

  get enterpriseAdministrationInfo() {
    return this._enterpriseAdministrationInfo;
  }
}
