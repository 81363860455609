import React from 'react';
import { Image } from 'react-bootstrap';

const PersoneCard = ({ fullName, position, about, phone, mail, type }) => {
  return (
    <div>
      <div className="item p-3">
        <div>
          <p>
            <b>{fullName}</b>
          </p>
          <p className="persone_position">{position}</p>
          <p>{about}</p>
          <p>
            <a href={'tel:' + phone}>{phone}</a>
          </p>
          <p>
            <a href={'mailto:' + mail}>{mail}</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PersoneCard;
