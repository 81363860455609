import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const BackToTop = () => {
  const [visible, setVisible] = useState(false);

  const trackScroll = () => {
    var scrolled = window.pageYOffset;
    var coords = document.documentElement.clientHeight;

    scrolled > coords ? setVisible(true) : setVisible(false);
  };

  const backToTop = () => {
    window.scrollTo(0, 0);
  };

  window.addEventListener('scroll', trackScroll);

  return (
    <Button
      onClick={() => backToTop()}
      className={'btn_toTop ' + (visible ? 'btn_toTop-show' : '')}
    >
      &Lambda;
    </Button>
  );
};

export default BackToTop;
