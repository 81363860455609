import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { fetchAllBrand } from '../../http/brandApi';
import { fetchAllCategory } from '../../http/categoryApi';
import { createProduct, createProductInfo } from '../../http/productApi';
import { Context } from '../../index';

const CreateProduct = ({ show, onHide }) => {
  const [name, setName] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [info, setInfo] = useState([]);
  const [lang, setLang] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [error, setError] = useState();

  const { product, brand, category, locale } = useContext(Context);

  useEffect(() => {
    fetchAllBrand().then((data) => brand.setBrand(data.rows));
    fetchAllCategory().then((data) => category.setCategory(data));
  }, []);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const addInfo = () => {
    setInfo([
      ...info,
      { weight: '', price: '', available: false, id: Date.now() },
    ]);
  };

  const removeInfo = (id) => {
    setInfo(info.filter((i) => i.id !== id));
  };

  const changeInfo = (key, value, id) => {
    setInfo(info.map((i) => (i.id === id ? { ...i, [key]: value } : i)));
  };

  const submitHandler = (type) => {
    const formData = new FormData();
    if (type === 'new') {
      formData.append('name', name);
      formData.append('brandId', selectedBrand.id);
      formData.append('categoryId', selectedCategory.id);
      formData.append('shortDescription', shortDescription);
      formData.append('description', description);
      formData.append('photo', file);
      formData.append('localeId', lang.id);
      formData.append('available', JSON.stringify(info));
    }
    type === 'info'
      ? createProductInfo(
          name,
          shortDescription,
          description,
          lang.id,
          selectedProduct.id
        ).then((data) => {
          onHide();
          product.updateProduct(data.id, data);
        })
      : createProduct(formData).then((data) => {
          onHide();
          product.addProduct(data);
        });
    setName('');
    setShortDescription('');
    setDescription('');
    setFile(null);
    setSelectedBrand(null);
    setSelectedCategory(null);
  };

  const addProduct = () => {
    selectedBrand
      ? selectedCategory
        ? name
          ? lang
            ? submitHandler('new')
            : setError('lang')
          : setError('name')
        : setError('category')
      : setError('brand');
  };

  const addProductInfo = () => {
    selectedProduct
      ? name
        ? lang
          ? submitHandler('info')
          : setError('lang')
        : setError('name')
      : setError('product');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить продукт
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle className={error === 'product' ? 'error' : ''}>
                {selectedProduct
                  ? selectedProduct.productInfos[0].name
                  : 'Выберите продукт'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {product.product.map((product) =>
                  product.productInfos.map((productInfo) =>
                    productInfo.localeId === 1 ? (
                      <Dropdown.Item
                        key={productInfo.id}
                        onClick={() => {
                          setSelectedProduct(product);
                          setError('');
                        }}
                      >
                        {productInfo.name}
                      </Dropdown.Item>
                    ) : (
                      <></>
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Dropdown className="mt-3 mb-3">
                <Dropdown.Toggle className={error === 'brand' ? 'error' : ''}>
                  {selectedBrand
                    ? selectedBrand.brandInfos[0].name
                    : 'Выберите производителя'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {brand.brand.map((brand) =>
                    brand.brandInfos.map((info) =>
                      info.localeId === 1 ? (
                        <Dropdown.Item
                          key={info.id}
                          onClick={() => {
                            setSelectedBrand(brand);
                            setError('');
                          }}
                        >
                          {info.name}
                        </Dropdown.Item>
                      ) : (
                        ''
                      )
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="mt-3 mb-3">
                <Dropdown.Toggle
                  className={error === 'category' ? 'error' : ''}
                >
                  {selectedCategory
                    ? selectedCategory.categoryInfos[0].name
                    : 'Выберите тип продукта'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {category.category.map((category) =>
                    category.categoryInfos.map((info) =>
                      info.localeId === 1 ? (
                        <Dropdown.Item
                          key={info.id}
                          onClick={() => {
                            setSelectedCategory(category);
                            setError('');
                          }}
                        >
                          {info.name}
                        </Dropdown.Item>
                      ) : (
                        ''
                      )
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                onChange={selectFile}
                className="mt-3 mb-3"
                type="file"
              />
            </>
          )}
          <Form.Control
            className={error === 'name' ? 'error' : ''}
            placeholder={'Введите название продукта'}
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setError('');
            }}
          />
          <Form.Control
            className="mt-3 mb-3"
            as="textarea"
            rows={1}
            placeholder={'Введите краткое описание'}
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
          />
          <Form.Control
            className="mt-3 mb-3"
            as="textarea"
            rows={3}
            placeholder={'Введите описание'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {toggle ? (
            ''
          ) : (
            <>
              <hr />
              <Button variant="outline-primary" onClick={addInfo}>
                Добавить информацию о наличии
              </Button>
            </>
          )}
          {info.map((i) => (
            <Row className="mt-3" key={i.id}>
              <Col md={3}>
                <Form.Control
                  type="number"
                  value={i.weight}
                  onChange={(e) => changeInfo('weight', e.target.value, i.id)}
                  placeholder="Введите вес"
                />
              </Col>
              <Col md={3}>
                <Form.Control
                  type="number"
                  value={i.price}
                  onChange={(e) => changeInfo('price', e.target.value, i.id)}
                  placeholder="Введите цену"
                />
              </Col>
              <Col md={3}>
                <Form.Check
                  value={i.available}
                  onChange={(e) =>
                    changeInfo('available', e.target.checked, i.id)
                  }
                  type={'checkbox'}
                  label={'В наличии'}
                />
              </Col>
              <Col md={3}>
                <Button
                  onClick={() => removeInfo(i.id)}
                  variant="outline-danger"
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          type="submit"
          onClick={toggle ? addProductInfo : addProduct}
        >
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateProduct;
