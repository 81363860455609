import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const Eco = () => {
  const params = useParams();
  const url = process.env.REACT_APP_API_URL + 'eco_' + params.id + '.jpg';
  window.scrollTo(0, 0);
  return (
    <Col className="d-flex justify-content-center flex-column min-vh-100">
      <a href={url} target="_blank" className="d-flex justify-content-center">
        <Image className="eco__img" src={url} />
      </a>
    </Col>
  );
};

export default Eco;
