import React from 'react';

const PersoneCardVI = ({ fullName, position, about, phone, mail, type }) => {
  return (
    <div className='bb'>
      <p>
        <b>{fullName}</b>
      </p>
      <p className="persone_position">{position}</p>
      <p>{about}</p>
      <p>
        <a className='link__VI' href={'tel:' + phone}>{phone}</a>
      </p>
      <p>
        <a className='link__VI' href={'mailto:' + mail}>{mail}</a>
      </p>
    </div>
  );
};

export default PersoneCardVI;
