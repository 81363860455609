import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Form, Row, Table } from 'react-bootstrap';
import { fetchAllCurrency, updateCurrency } from '../../http/localeApi';
import { Context } from '../../index';

const Locale = observer(() => {
  const [editId, setEditId] = useState('');
  const [editName, setEditName] = useState('');
  const [editValue, setEditValue] = useState('');

  const { locale } = useContext(Context);

  useEffect(() => {
    fetchAllCurrency().then((data) => locale.setCurrencies(data));
  }, [locale]);

  const editToggle = (id, name, value) => {
    setEditId(id);
    setEditName(name);
    setEditValue(value);
  };

  const editСurrency = () => {
    updateCurrency(editId, editName, editValue).then((data) =>
      locale.updateCurrency(data.id, data)
    );
    setEditId('');
  };

  if (locale.currency.length === 0)
    return (
      <Container>
        <h3 className="d-flex justify-content-center">Нет информации</h3>
      </Container>
    );

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Курсы валют</h1>
      <Row>
        <Table>
          <thead>
            <tr>
              <th className="col-1">#</th>
              <th>Название</th>
              <th>Курс</th>
              <th className="col-1"></th>
            </tr>
          </thead>
          <tbody>
            {locale.currency?.map((currency) =>
              currency.id === editId ? (
                <tr key={currency.id}>
                  <td>{currency.id}</td>
                  <td>
                    <Form.Control
                      className="form-control-sm"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      placeholder={'Введите название валюты'}
                    />
                  </td>
                  <td>
                    <Form.Control
                      className="form-control-sm"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      placeholder={'Введите курс'}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      className="me-2"
                      onClick={() => editToggle('', '')}
                    >
                      <i className="bi bi-x-lg"></i>
                    </Button>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => editСurrency()}
                    >
                      <i className="bi bi-check2"></i>
                    </Button>
                  </td>
                </tr>
              ) : (
                <tr key={currency.id}>
                  <td>{currency.id}</td>
                  <td>{currency.name}</td>
                  <td>{currency.value}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() =>
                        editToggle(currency.id, currency.name, currency.value)
                      }
                    >
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
});

export default Locale;
