import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const MainPageCard = ({ img, heading, link }) => {
  const { t } = useTranslation();
  return (
    <div className="item">
      <div style={{ backgroundImage: `url(${img})` }} className="img img-card">
        <div className='card-content'>
          <h2 className="heading heading-card">{heading}</h2>
          <Link to={link} className="btn__card">
            {t('main.more')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainPageCard;
