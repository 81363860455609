import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const EnterpriseToday = () => {
  const { t } = useTranslation();
  document.title = t('today.pageTitle');
  window.scrollTo(0, 0);
  return (
    <div className="container text item mt-3">
      <Helmet
        title={t('today.pageTitle')}
        meta={[{ name: 'description', content: t('today.pageDescription') }]}
      />
      <h1 className="text-center my-5">{t('today.title')}</h1>
      <Row>
        <Col sm={12} md={4}>
          <p className="d-flex justify-content-center mb-3">
            <img className="today__img" src={require('../static/today1.jpg')} />
          </p>
        </Col>
        <Col sm={12} md={8}>
          <p>{t('today.text1')}</p>
          <p>{t('today.text2')}</p>
          <p>{t('today.text3')}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={8}>
          <p>{t('today.text4')}</p>
          <p>{t('today.text5')}</p>
        </Col>
        <Col sm={12} md={4}>
          <p className="d-flex justify-content-center my-3">
            <img className="today__img" src={require('../static/today3.jpg')} />
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default EnterpriseToday;
