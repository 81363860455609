import React, { useContext, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import {
  createStore,
  createStoreInfo,
  fetchAllStoreLang,
} from '../../http/storeApi';
import { Context } from '../../index';

const CreateStore = ({ show, onHide }) => {
  const { store, locale } = useContext(Context);
  const [toggle, setToggle] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [file, setFile] = useState(null);
  const [lang, setLang] = useState();
  const [error, setError] = useState();

  const submitHandler = (type) => {
    const formData = new FormData();
    if (type === 'new') {
      formData.append('name', name);
      formData.append('address', address);
      formData.append('photo', file);
      formData.append('localeId', lang.id);
    }
    type === 'info'
      ? createStoreInfo(name, address, lang.id, selectedStore.id).then(
          (data) => {
            onHide();
            store.updateStore(data.id, data);
          }
        )
      : createStore(formData).then((data) => {
          onHide();
          store.addStore(data);
          fetchAllStoreLang(1).then((data) => store.setStoreLang(data.rows));
        });

    setName('');
    setAddress('');
    setSelectedStore(null);
    setFile(null);
  };

  const addStore = () => {
    name
      ? address
        ? lang
          ? submitHandler('new')
          : setError('lang')
        : setError('address')
      : setError('name');
  };

  const addStoreInfo = () => {
    selectedStore
      ? name
        ? address
          ? lang
            ? submitHandler('info')
            : setError('lang')
          : setError('address')
        : setError('name')
      : setError('store');
  };

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить магазин
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle className={error === 'store' ? 'error' : ''}>
                {selectedStore
                  ? selectedStore.storeInfos[0].name
                  : 'Выберите магазин'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {store.store.map((store) =>
                  store.storeInfos.map((storeInfo) =>
                    storeInfo.localeId === 1 ? (
                      <Dropdown.Item
                        key={storeInfo.id}
                        onClick={() => {
                          setSelectedStore(store);
                          setError('');
                        }}
                      >
                        {storeInfo.name}
                      </Dropdown.Item>
                    ) : (
                      <></>
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Form.Control onChange={selectFile} className="mb-3" type="file" />
          )}

          <Form.Control
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setError('');
            }}
            className={error === 'name' ? 'error' : '' + 'mb-3'}
            placeholder={'Введите название магазина'}
          />
          <Form.Control
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setError('');
            }}
            className={error === 'address' ? 'error' : '' + 'mt-3 mb-3'}
            placeholder={'Введите адрес'}
          />
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          onClick={toggle ? addStoreInfo : addStore}
        >
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateStore;
