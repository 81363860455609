import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Context } from '../index';
import { fetchRecipeLang } from '../http/recipeApi';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Helmet } from 'react-helmet';

const RecipeFull = observer(() => {
  const { recipe, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    fetchRecipeLang(params.id, locale.selectLang)
      .then((data) => {
        recipe.setRecipe(data);
      })
      .then(() => {
        document.title = recipe.recipe?.recipeInfos[0]?.name;
        setLoading(false);
      });
    window.scrollTo(0, 0);
  }, [locale.selectLang]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );
  let hours = Number(String(recipe.recipe?.time).split('.')[0]);
  let minutes = Number(String(recipe.recipe?.time).split('.')[1]);
  return (
    <Container className="d-flex flex-column min-vh-100">
      <div className="item mt-3 p-3">
        {recipe.recipe ? (
          <>
            <Helmet
              meta={[
                {
                  name: 'description',
                  content: recipe.recipe?.recipeInfos[0]?.manual,
                },
              ]}
            />
            <Row>
              <Col md="4">
                <Image
                  src={process.env.REACT_APP_API_URL + recipe.recipe?.photo}
                  className="img"
                />
              </Col>
              <Col>
                <h1>{recipe.recipe?.recipeInfos[0]?.name}</h1>
                <p className="recipe-card-text-full pt-5">
                  {recipe.recipe?.recipeInfos[0]?.ingredients}
                </p>
                <p>
                  <b>{hours || minutes ? t('recipes.cookingTime') : ''}</b>
                  {hours
                    ? hours +
                      (hours === 1 ? t('recipes.hour') : t('recipes.hours'))
                    : ''}
                  {minutes ? minutes + t('recipes.minutes') : ''}
                </p>
                <Col className="my-5"></Col>
              </Col>
            </Row>
            <Row>
              <h3 className="d-flex justify-content-center">
                {t('recipes.cooking')}
              </h3>
              <p className="recipe-card-text-full">
                {recipe.recipe?.recipeInfos[0]?.manual}
              </p>
            </Row>
            <p className="text-center">
              <Image
                src={process.env.REACT_APP_API_URL + 'recipe_appetit.png'}
                className="mw-100"
              />
            </p>
            <Row className="mt-3">
              <Col>
                <button onClick={() => history.goBack()} className="btn__card">
                  {t('recipes.back')}
                </button>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <h2 className="d-flex justify-content-center mt-5">
              {t('product.notFound')}
            </h2>
            <Col className="my-5">
              <button onClick={() => history.goBack()} className="btn__card">
                {t('recipes.back')}
              </button>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
});

export default RecipeFull;
