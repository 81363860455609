import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import CreateRequest from './modals/CreateRequest';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const Request = () => {
  const { t } = useTranslation();
  const [createVisible, setCreateVisible] = useState(false);
  return (
    <div className="d-flex  justify-content-end mb-3 me-3">
      <Button className="btn_request" onClick={() => setCreateVisible(true)}>
        <p className='p-0 my-1'>{t('request.request')}</p>
      </Button>
      <CreateRequest
        show={createVisible}
        onHide={() => setCreateVisible(false)}
        user={true}
      />
    </div>
  );
};

export default Request;
