import React, { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import Navbar from './components/Navbar';
import { observer } from 'mobx-react-lite';
import { Context } from './index';
import { check } from './http/userApi';
import { Spinner } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import FooterVI from './components/VI/FooterVI';
import VIControl from './components/VI/VIControl';
import HeaderVI from './components/VI/HeaderVI';

const App = observer(() => {
  const { user, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    check()
      .then((data) => {
        user.setUser(data);
        user.setIsAuth(true);
      })
      .catch(() => user.setIsAuth(false))
      .then(() => setLoading(false));
  }, [vi.VI]);

  useEffect(() => {
    let VI = localStorage.getItem('VI');
    vi.setVI(VI);
  }, [vi.VI]);

  if (loading) return <Spinner animation={'grow'} />;
  return (
    <Suspense fallback={<Spinner animation={'grow'} />}>
      <BrowserRouter>
        <React.StrictMode>
          {vi.VI === 'true' && <VIControl />}
          {user.isAuth ? (
            <Navbar />
          ) : vi.VI === 'true' ? (
            <HeaderVI />
          ) : (
            <Header />
          )}
          <AppRouter />
          {user.isAuth ? '' : vi.VI === 'true' ? <FooterVI /> : <Footer />}
        </React.StrictMode>
      </BrowserRouter>
    </Suspense>
  );
});

export default App;
