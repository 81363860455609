import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import {
  deleteRequest,
  fetchAllRequest,
  updateRequest,
} from '../../http/requestApi';
import { Context } from '../../index';
import CreateRequest from '../../components/modals/CreateRequest';
import Pages from '../../components/Pages';
import PageSize from '../../components/PageSize';

const Requests = observer(() => {
  const { request, page } = useContext(Context);
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editProcessed, setEditProcessed] = useState(false);

  useEffect(() => {
    fetchAllRequest(page.limit, page.page).then((data) => {
      request.setRequest(data.rows);
      page.setTotalCount(data.count);
    });
  }, [request, page.page, page.limit]);

  const editToggle = (id, processed) => {
    setEditId(id);
    setEditProcessed(processed);
  };

  const editRequest = () => {
    updateRequest(editId, editProcessed).then((data) => {
      request.updateRequest(data.id, data);
      setEditId('');
    });
  };

  const delRequest = (id) => {
    deleteRequest(id)
      .then(() => {
        request.removeRequest(id);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Заявки</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <PageSize />
        </Col>
        <Col className="d-flex justify-content-end">
          <Pages />
        </Col>
      </Row>
      <Row>
        {request.request.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th className="col-4">ФИО</th>
                <th className="col-3">Номер телефона</th>
                <th className="col-3">mail</th>
                <th className="col-1">Обработан</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {request.request.map((request) => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>{request.fullName}</td>
                  <td>{request.phone}</td>
                  <td>{request.mail}</td>
                  {request.id === editId ? (
                    <>
                      <td>
                        <Form.Check
                          checked={editProcessed}
                          onChange={(e) => setEditProcessed(e.target.checked)}
                          type="checkbox"
                        />
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={() => editToggle('', '')}
                        >
                          <i className="bi bi-x-lg"></i>
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => editRequest()}
                        >
                          <i className="bi bi-check2"></i>
                        </Button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <input
                          type="checkbox"
                          checked={request.processed}
                          disabled
                        />
                      </td>
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2"
                          onClick={() =>
                            editToggle(request.id, request.processed)
                          }
                        >
                          <i className="bi bi-pencil"></i>
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            delRequest(request.id);
                          }}
                        >
                          <i className="bi bi-x-circle"></i>
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет заявок</h3>
        )}
      </Row>
      <CreateRequest
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Requests;
