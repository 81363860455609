import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import VacancyCard from '../components/VacancyCard';
import { Context } from '../index';
import { fetchAllVacancyLang } from '../http/vacancyApi';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import Pages from '../components/Pages';
import { Helmet } from 'react-helmet';

const Vacancy = observer(() => {
  const { vacancy, page, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllVacancyLang(locale.selectLang, page.limit, page.page)
      .then((data) => {
        vacancy.setVacancy(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [vacancy, page.page, page, locale.selectLang]);

  const { t } = useTranslation();
  document.title = t('vacancy.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center flex-column min-vh-100">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('vacancy.pageTitle')}
          meta={[
            { name: 'description', content: t('vacancy.pageDescription') },
          ]}
        />
      </Container>
    );
  return (
    <Container className="mt-5 d-flex flex-column min-vh-100">
      <Helmet
        title={t('vacancy.pageTitle')}
        meta={[{ name: 'description', content: t('vacancy.pageDescription') }]}
      />
      <Row>
        <Col className="d-flex mb-3" xs={12} md={2}>
          <Pages />
        </Col>
      </Row>
      <Row>
        <p>{t('vacancy.contact')}</p>
        <Col>
          {vacancy.vacancy?.map((vacancy) =>
            vacancy.vacancyInfos?.map((vacancyInfo) => (
              <VacancyCard
                key={vacancyInfo.id}
                title={vacancyInfo.jobTitle}
                requiredSpecialty={vacancyInfo.requiredSpecialty}
                wageMin={vacancy.wageMin}
                wageMax={vacancy.wageMax}
                workingConditions={vacancyInfo.workingConditions}
                requiredExperienceFrom={vacancy.requiredExperienceFrom}
                requiredExperienceUpTo={vacancy.requiredExperienceUpTo}
              />
            ))
          )}
        </Col>
      </Row>
    </Container>
  );
});

export default Vacancy;
