import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { fetchAllLocale } from '../../http/localeApi';
import { createNews, createNewsInfo } from '../../http/newsApi';
import { fetchAllCategory } from '../../http/newsCategoryApi';
import { Context } from '../../index';

const CreateNews = ({ show, onHide }) => {
  const [title, setTitle] = useState('');
  const [newsText, setNewsText] = useState('');
  const [lang, setLang] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  const { news, newsCategory, locale } = useContext(Context);

  useEffect(() => {
    fetchAllLocale().then((data) => locale.setLocale(data));
    fetchAllCategory().then((data) => newsCategory.setCategory(data));
  }, []);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const submitHandler = (type) => {
    const formData = new FormData();
    if (type === 'new') {
      formData.append('title', title);
      formData.append('newsText', newsText);
      formData.append('newsCategoryId', selectedCategory.id);
      formData.append('localeId', lang.id);
      formData.append('photo', file);
    }
    type === 'info'
      ? createNewsInfo(title, newsText, lang.id, selectedNews.id).then(
          (data) => {
            news.updateNews(data.id, data);
          }
        )
      : createNews(formData).then((data) => {
          news.addNews(data);
        });
    setFile(null);
    setTitle('');
    setNewsText('');
    setSelectedCategory(null);
    setLang(null);
    onHide();
  };

  const addNews = () => {
    selectedCategory
      ? title
        ? newsText
          ? lang
            ? submitHandler('new')
            : setError('lang')
          : setError('newsText')
        : setError('title')
      : setError('selectedCategory');
  };

  const addNewsInfo = () => {
    selectedNews
      ? title
        ? newsText
          ? lang
            ? submitHandler('info')
            : setError('lang')
          : setError('newsText')
        : setError('title')
      : setError('selectedNews');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить новость
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle
                className={error === 'selectedNews' ? 'error' : ''}
              >
                {selectedNews
                  ? selectedNews.newsInfos[0].title.slice(0, 40) + '...'
                  : 'Выберите новость'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {news.news.map((news) =>
                  news.newsInfos.map((info) =>
                    info.localeId === 1 ? (
                      <Dropdown.Item
                        key={info.id}
                        onClick={() => {
                          setSelectedNews(news);
                          setError('');
                        }}
                      >
                        {info.title.slice(0, 40) + '...'}
                      </Dropdown.Item>
                    ) : (
                      ''
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Dropdown className="mt-3 mb-3">
                <Dropdown.Toggle
                  className={error === 'selectedCategory' ? 'error' : ''}
                >
                  {selectedCategory
                    ? selectedCategory.name
                    : 'Выберите категорию'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {newsCategory.newsCategory.map((category) => (
                    <Dropdown.Item
                      key={category.id}
                      onClick={() => {
                        setSelectedCategory(category);
                        setError('');
                      }}
                    >
                      {category.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                onChange={selectFile}
                className="mt-3 mb-3"
                type="file"
              />
            </>
          )}
          <Form.Control
            placeholder={'Введите заголовок'}
            className={error === 'title' ? 'error' : ''}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setError('');
            }}
          />
          <Form.Control
            className={(error === 'newsText' ? 'error' : '') + ' mt-3 mb-3'}
            as="textarea"
            rows={3}
            placeholder={'Введите текст новости'}
            value={newsText}
            onChange={(e) => {
              setNewsText(e.target.value);
              setError('');
            }}
          />
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          onClick={toggle ? addNewsInfo : addNews}
        >
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateNews;
