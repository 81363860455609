import { $authHost, $host } from './index';

export const createEnterpriseAdministration = async (
  enterpriseAdministration
) => {
  const { data } = await $authHost.post(
    'api/enterpriseAdministration',
    enterpriseAdministration
  );
  return data;
};

export const fetchAllEnterpriseAdministration = async () => {
  const { data } = await $host.get('api/enterpriseAdministration');
  return data;
};

export const fetchAllEnterpriseAdministrationLang = async (id) => {
  const { data } = await $host.get('api/enterpriseAdministration/locale/' + id);
  return data;
};

export const fetchEnterpriseAdministration = async (id) => {
  const { data } = await $host.get('api/enterpriseAdministration/' + id);
  return data;
};

export const updateEnterpriseAdministration = async (id, administration) => {
  const { data } = await $authHost.put(
    'api/enterpriseAdministration/' + id,
    administration
  );
  return data;
};

export const deleteEnterpriseAdministration = async (id) => {
  const { data } = await $authHost.delete('api/enterpriseAdministration/' + id);
  return data;
};

export const createEnterpriseAdministrationInfo = async (
  enterpriseAdministrationId,
  fullName,
  position,
  about,
  localeId
) => {
  const { data } = await $authHost.post('api/enterpriseAdministration/info/', {
    enterpriseAdministrationId,
    fullName,
    position,
    about,
    localeId,
  });
  return data;
};

export const updateEnterpriseAdministrationInfo = async (
  id,
  fullName,
  position,
  about,
  localeId
) => {
  const { data } = await $authHost.put(
    'api/enterpriseAdministration/info/' + id,
    { fullName, position, about, localeId }
  );
  return data;
};

export const deleteEnterpriseAdministrationInfo = async (
  id,
  administrationId
) => {
  const { data } = await $authHost.delete(
    'api/enterpriseAdministration/info/' + id,
    {
      data: { administrationId },
    }
  );
  return data;
};
