import { $authHost, $host } from './index';

export const createTradeUnion = async (tradeUnion) => {
  const { data } = await $authHost.post('api/tradeUnion', tradeUnion);
  return data;
};

export const fetchAllTradeUnion = async () => {
  const { data } = await $host.get('api/tradeUnion');
  return data;
};

export const fetchAllTradeUnionLang = async (id) => {
  const { data } = await $host.get('api/tradeUnion/locale/' + id);
  return data;
};

export const fetchTradeUnion = async (id) => {
  const { data } = await $host.get('api/tradeUnion/' + id);
  return data;
};

export const updateTradeUnion = async (id, administration) => {
  const { data } = await $authHost.put(
    'api/tradeUnion/' + id,
    administration
  );
  return data;
};

export const deleteTradeUnion = async (id) => {
  const { data } = await $authHost.delete('api/tradeUnion/' + id);
  return data;
};

export const createTradeUnionInfo = async (
  tradeUnionId,
  fullName,
  position,
  about,
  localeId
) => {
  const { data } = await $authHost.post('api/tradeUnion/info/', {
    tradeUnionId,
    fullName,
    position,
    about,
    localeId,
  });
  return data;
};

export const updateTradeUnionInfo = async (
  id,
  fullName,
  position,
  about,
  localeId
) => {
  const { data } = await $authHost.put(
    'api/tradeUnion/info/' + id,
    { fullName, position, about, localeId }
  );
  return data;
};

export const deleteTradeUnionInfo = async (
  id,
  administrationId
) => {
  const { data } = await $authHost.delete(
    'api/tradeUnion/info/' + id,
    {
      data: { administrationId },
    }
  );
  return data;
};
