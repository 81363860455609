import React from 'react';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const ReceptionSchedule = () => {
  const { t } = useTranslation();
  const schedule = t('scheduleCard', { returnObjects: true });
  document.title = t('schedule.pageTitle');
  return (
    <div className="mt-5 container item">
      <Helmet
        title={t('schedule.pageTitle')}
        meta={[{ name: 'description', content: t('schedule.pageDescription') }]}
      />
      <h1 className="text-center my-3">{t('schedule.title')}</h1>
      <Table striped>
        <thead>
          <tr className="text-center">
            <th className="col-4">{t('schedule.post')}</th>
            <th className="col-4">{t('schedule.time')}</th>
            <th className="col-4">{t('schedule.place')}</th>
          </tr>
        </thead>
        {schedule.map((schedule) => (
          <tbody>
            <tr>
              <td className="text-center">
                {schedule.post}
                <p>
                  {schedule.name} {schedule.surname}
                </p>
              </td>
              <td className="text-center">
                {schedule.schedule1} <br />
                {schedule.schedule2}
              </td>
              <td>
                <p className="text-center">{schedule.place}</p>
              </td>
            </tr>
          </tbody>
        ))}
      </Table>
    </div>
  );
};

export default ReceptionSchedule;
