import { observer } from 'mobx-react-lite';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Alert, Button, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Context } from '../..';
import ErrorText from '../../components/ErrorText';
import { sendFeedback, sendFeedbackEntity } from '../../http/requestApi';
import { MAIN_ROUTE } from '../../utils/consts';
import useInput from '../../utils/hooks/useInput';
import '../../utils/i18next';

const FeedbackVI = observer(() => {
  const { vi } = useContext(Context);
  const { t } = useTranslation();
  document.title = t('feedback.pageTitle');
  const [agree, setAgree] = useState(false);
  const [type, setType] = useState('citizens');
  const [file, setFile] = useState();
  const [show, setShow] = useState(false);
  const [disabledButton, setDisableButton] = useState(true);

  const fullName = useInput('', { isEmpty: true });
  const mail = useInput('', { isEmpty: true, isMail: true });
  const entityName = useInput('', { isEmpty: true });
  const phone = useInput('', { isEmpty: true });
  const address = useInput('', { isEmpty: true });
  const theme = useInput('', { isEmpty: true });
  const text = useInput('', { isEmpty: true });

  const fileRef = useRef();

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (type === 'entities') {
      fullName.inputValid &&
      mail.inputValid &&
      entityName.inputValid &&
      phone.inputValid &&
      address.inputValid &&
      theme.inputValid &&
      text.inputValid &&
      agree
        ? setDisableButton(false)
        : setDisableButton(true);
    } else {
      fullName.inputValid &&
      mail.inputValid &&
      phone.inputValid &&
      address.inputValid &&
      theme.inputValid &&
      text.inputValid &&
      agree
        ? setDisableButton(false)
        : setDisableButton(true);
    }
  }, [
    fullName,
    mail,
    entityName,
    phone,
    address,
    theme,
    text,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const send = () => {
    const formData = new FormData();
    formData.append('fullName', fullName.value);
    formData.append('phone', phone.value);
    formData.append('mail', mail.value);
    formData.append('mailingAddress', address.value);
    formData.append('theme', theme.value);
    formData.append('text', text.value);
    formData.append('file', file);
    sendFeedback(formData);
    setShow(true);
    window.scrollTo(0, 0);
  };

  const sendEntities = () => {
    const formData = new FormData();
    formData.append('entityName', entityName.value);
    formData.append('fullName', fullName.value);
    formData.append('phone', phone.value);
    formData.append('mail', mail.value);
    formData.append('mailingAddress', address.value);
    formData.append('theme', theme.value);
    formData.append('text', text.value);
    formData.append('file', file);
    sendFeedbackEntity(formData);
    setShow(true);
    window.scrollTo(0, 0);
  };

  {
    if (show) {
      return (
        <Container className="item mt-5">
          <div className="d-flex justify-content-center">
            <Row>
              <Alert
                variant="success"
                className="my-5"
                onClose={() => setShow(false)}
              >
                <h5>{t('feedback.message')}</h5>
              </Alert>
              <Link to={MAIN_ROUTE} className="btn__card mb-5 w-auto">
                На главную
              </Link>
            </Row>
          </div>
        </Container>
      );
    }
  }

  return (
    <div
      className={
        'container__VI pb-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <Link className={'link__VI me-5'} onClick={() => setType('citizens')}>
        {t('feedback.btn')}
      </Link>
      <Link className={'link__VI'} onClick={() => setType('entities')}>
        {t('feedback.btnEntity')}
      </Link>
      <div>
        <Form>
          {type === 'entities' ? (
            <>
              <Form.Label className="feedback_lable">
                {t('feedback.entityNameLabel')}
              </Form.Label>
              <Form.Control
                className={entityName.isDirty && entityName.isEmpty && ' error'}
                value={entityName.value}
                onChange={(e) => entityName.onChange(e)}
                onBlur={(e) => entityName.onBlur(e)}
                placeholder={t('feedback.entityName')}
              />
            </>
          ) : (
            ''
          )}
          <Form.Label
            className={
              (type === 'entities' ? ' mt-3 ' : '') + ' feedback_lable'
            }
          >
            {t('feedback.fullNameLabel')}
          </Form.Label>
          <Form.Control
            className={fullName.isDirty && fullName.isEmpty && ' error'}
            value={fullName.value}
            onChange={(e) => fullName.onChange(e)}
            onBlur={(e) => fullName.onBlur(e)}
            placeholder={t('feedback.fullName')}
          />
          {fullName.isDirty && fullName.isEmpty && ErrorText(t('error.empty'))}
          <Form.Label className="mt-3 feedback_lable">
            {t('feedback.phoneLabel')}
          </Form.Label>
          <Form.Control
            className={phone.isDirty && phone.isEmpty && ' error'}
            value={phone.value}
            onChange={(e) => phone.onChange(e)}
            onBlur={(e) => phone.onBlur(e)}
            placeholder={t('feedback.phone')}
          />
          {phone.isDirty && phone.isEmpty && ErrorText(t('error.empty'))}
          <Form.Label className="mt-3 feedback_lable">
            {t('feedback.mailLabel')}
          </Form.Label>
          <Form.Control
            className={
              (mail.isDirty && mail.mailError && ' error') ||
              (mail.isDirty && mail.isEmpty && ' error')
            }
            value={mail.value}
            onChange={(e) => mail.onChange(e)}
            onBlur={(e) => mail.onBlur(e)}
            placeholder={t('feedback.mail')}
          />
          {mail.isDirty && mail.isEmpty && ErrorText(t('error.empty'))}
          {mail.isDirty && mail.mailError && ErrorText(t('error.mail'))}
          <Form.Label className="mt-3 feedback_lable">
            {t('feedback.addressLabel')}
          </Form.Label>
          <Form.Control
            className={address.isDirty && address.isEmpty && ' error'}
            value={address.value}
            onChange={(e) => address.onChange(e)}
            onBlur={(e) => address.onBlur(e)}
            placeholder={t('feedback.address')}
          />
          {address.isDirty && address.isEmpty && ErrorText(t('error.empty'))}
          <Form.Label className="mt-3 feedback_lable">
            {t('feedback.themeLabel')}
          </Form.Label>
          <Form.Control
            className={theme.isDirty && theme.isEmpty && ' error'}
            value={theme.value}
            onChange={(e) => theme.onChange(e)}
            onBlur={(e) => theme.onBlur(e)}
            placeholder={t('feedback.theme')}
          />
          {theme.isDirty && theme.isEmpty && ErrorText(t('error.empty'))}
          <Form.Label className="mt-3 feedback_lable">
            {t('feedback.textLabel')}
          </Form.Label>
          <Form.Control
            className={text.isDirty && text.isEmpty && ' error'}
            value={text.value}
            as="textarea"
            rows={5}
            onChange={(e) => text.onChange(e)}
            onBlur={(e) => text.onBlur(e)}
            placeholder={t('feedback.text')}
          />
          {text.isDirty && text.isEmpty && ErrorText(t('error.empty'))}
          <Form.Label className="mt-3 feedback_lable">
            {t('feedback.fileLabel')}
          </Form.Label>
          <Form.Control onChange={selectFile} type="file" ref={fileRef} />
          <Form.Check
            value={agree}
            onChange={(e) => {
              setAgree(e.target.checked);
            }}
            className={' mt-3'}
            type={'checkbox'}
            label={
              <span>
                {t('request.agreement')}
                <a
                  href={
                    process.env.REACT_APP_API_URL +
                    'Политика_обработки_персональных_данных.pdf'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className='link__VI'
                >
                  {t('request.terms')}
                </a>
              </span>
            }
          />
        </Form>
        <Button
          className="mt-3"
          type="submit"
          disabled={disabledButton}
          onClick={type === 'entities' ? sendEntities : send}
        >
          {t('feedback.send')}
        </Button>
      </div>
    </div>
  );
});

export default FeedbackVI;
