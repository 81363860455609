import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table,
} from 'react-bootstrap';
import { Context } from '../../index';
import {
  deletePhoto,
  fetchAllPhoto,
  updatePhoto,
  deleteFile,
  fetchAllFile,
  updateFile,
} from '../../http/newsApi';
import AddNewsPhoto from '../../components/modals/AddNewsPhoto';
import { useParams } from 'react-router-dom';
import AddNewsFile from '../../components/modals/AddNewsFile';

const NewsFiles = observer(() => {
  const [createVisibleP, setCreateVisibleP] = useState(false);
  const [createVisibleF, setCreateVisibleF] = useState(false);
  const [editFile, setFile] = useState(null);
  const [editPhotoId, setPhotoEditId] = useState('');
  const [editFileId, setFileEditId] = useState('');
  const params = useParams();

  const { newsPhoto, newsFile } = useContext(Context);

  useEffect(() => {
    fetchAllPhoto(params.id).then((data) => {
      newsPhoto.setNewsPhoto(data);
    });
    fetchAllFile(params.id).then((data) => {
      newsFile.setNewsFile(data);
    });
  }, [newsPhoto, newsFile]);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const editPhotoToggle = (photo) => {
    setPhotoEditId(photo.id);
  };

  const editFileToggle = (file) => {
    setFileEditId(file.id);
  };

  const editNewsPhoto = () => {
    const formData = new FormData();
    formData.append('photo', editFile);
    updatePhoto(editPhotoId, formData).then((data) => {
      newsPhoto.updateNewsPhoto(data.id, data);
      setPhotoEditId('');
    });
  };

  const editNewsFile = () => {
    const formData = new FormData();
    formData.append('file', editFile);
    updateFile(editFileId, formData).then((data) => {
      newsFile.updateNewsFile(data.id, data);
      setFileEditId('');
    });
  };

  const delNewsPhoto = (id) => {
    deletePhoto(id);
    newsPhoto.removeNewsPhoto(id);
  };

  const delNewsFile = (id) => {
    deleteFile(id);
    newsFile.removeNewsFile(id);
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Приложения к новости</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisibleP(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisibleF(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {newsPhoto.newsPhoto.length !== 0 ? (
            <Table>
              <thead>
                <tr>
                  <th className="col-1">#</th>
                  <th>Фото</th>
                  <th className="col-1"></th>
                </tr>
              </thead>
              <tbody>
                {newsPhoto.newsPhoto?.map((newsPhoto) => (
                  <tr key={newsPhoto.id}>
                    <td>{newsPhoto.id}</td>
                    {newsPhoto.id === editPhotoId ? (
                      <td>
                        <Form.Control
                          onChange={selectFile}
                          className="form-control-sm"
                          type="file"
                        />
                      </td>
                    ) : (
                      <td>
                        <div className="photo-wrapper">
                          {newsPhoto.photo ? (
                            <Image
                              src={
                                process.env.REACT_APP_API_URL + newsPhoto.photo
                              }
                              className="photo-mini"
                            />
                          ) : (
                            'нет фото'
                          )}
                        </div>
                      </td>
                    )}
                    {newsPhoto.id === editPhotoId ? (
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={() => editPhotoToggle('')}
                        >
                          <i className="bi bi-x-lg"></i>
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => editNewsPhoto()}
                        >
                          <i className="bi bi-check2"></i>
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2 mb-2"
                          onClick={() => editPhotoToggle(newsPhoto)}
                        >
                          Изменить
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => delNewsPhoto(newsPhoto.id)}
                        >
                          Удалить
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h3 className="d-flex justify-content-center">Нет фото</h3>
          )}
        </Col>
        <Col>
          {newsFile.newsFile.length !== 0 ? (
            <Table>
              <thead>
                <tr>
                  <th className="col-1">#</th>
                  <th>Файл</th>
                  <th className="col-1"></th>
                </tr>
              </thead>
              <tbody>
                {newsFile.newsFile?.map((newsFile) => (
                  <tr key={newsFile.id}>
                    <td>{newsFile.id}</td>
                    {newsFile.id === editFileId ? (
                      <td>
                        <Form.Control
                          onChange={selectFile}
                          className="form-control-sm"
                          type="file"
                        />
                      </td>
                    ) : (
                      <td>
                        <a
                          href={process.env.REACT_APP_API_URL + newsFile.file}
                          download={newsFile.name}
                        >
                          {newsFile.name}
                        </a>
                      </td>
                    )}
                    {newsFile.id === editFileId ? (
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={() => editFileToggle('')}
                        >
                          <i className="bi bi-x-lg"></i>
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => editNewsFile()}
                        >
                          <i className="bi bi-check2"></i>
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2 mb-2"
                          onClick={() => editFileToggle(newsFile)}
                        >
                          Изменить
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => delNewsFile(newsFile.id)}
                        >
                          Удалить
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h3 className="d-flex justify-content-center">Нет файлов</h3>
          )}
        </Col>
      </Row>

      <AddNewsPhoto
        newsId={params.id}
        show={createVisibleP}
        onHide={() => setCreateVisibleP(false)}
      />
      <AddNewsFile
        newsId={params.id}
        show={createVisibleF}
        onHide={() => setCreateVisibleF(false)}
      />
    </Container>
  );
});

export default NewsFiles;
