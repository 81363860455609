import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import NewsCard from '../components/NewsCard';
import { fetchAllNews } from '../http/newsApi';
import { Context } from '../index';
import Pages from '../components/Pages';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Container, Col, Spinner } from 'react-bootstrap';
import { NEWS_ROUTE } from '../utils/consts';
import { Helmet } from 'react-helmet';

const News = observer(() => {
  const { news, page, locale } = useContext(Context);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    fetchAllNews(1, locale.selectLang, page.limit, page.page)
      .then((data) => {
        news.setNews(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [news, page.page, page.limit, locale.selectLang]);

  const { t } = useTranslation();
  document.title = t('news.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('news.pageTitle')}
          meta={[{ name: 'description', content: t('news.pageDescription') }]}
        />
      </Container>
    );

  return (
    <Container className="mt-3">
      <Helmet
        title={t('news.pageTitle')}
        meta={[{ name: 'description', content: t('news.pageDescription') }]}
      />
      <Col className="d-flex justify-content-end mb-3">
        <Pages />
      </Col>
      {news.news?.map((news) =>
        news.newsInfos?.map((newsInfo) => (
          <NewsCard
            key={newsInfo.id}
            id={news.id}
            title={newsInfo.title}
            newsText={newsInfo.newsText}
            date={news.createdAt}
            link={NEWS_ROUTE + '/' + news.id}
            img={news.photo ? process.env.REACT_APP_API_URL + news.photo : ''}
          />
        ))
      )}
    </Container>
  );
});

export default News;
