import { makeAutoObservable } from 'mobx';

export default class TradeUnionStore {
  constructor() {
    this._tradeUnion = [];
    this._tradeUnionInfo = [];
    makeAutoObservable(this);
  }

  setTradeUnion(tradeUnion) {
    this._tradeUnion = tradeUnion;
  }

  seTtradeUnionInfo(tradeUnionInfo) {
    this._tradeUnionInfo = tradeUnionInfo;
  }

  addTradeUnion(tradeUnion) {
    this._tradeUnion.push(tradeUnion);
  }

  updateTradeUnion(id, data) {
    this._tradeUnion = this._tradeUnion.map(
      (tradeUnion) =>
      tradeUnion.id === id
          ? { ...data }
          : { ...tradeUnion }
    );
  }

  removeTradeUnion(id) {
    this._tradeUnion = this._tradeUnion.filter(
      (tradeUnion) => tradeUnion.id !== id
    );
  }

  get tradeUnion() {
    return this._tradeUnion;
  }

  get tradeUnionInfo() {
    return this._tradeUnionInfo;
  }
}
