import { makeAutoObservable } from 'mobx';

export default class NewsCategoryStore {
  constructor() {
    this._newsCategory = [];
    makeAutoObservable(this);
  }

  setCategory(newsCategory) {
    this._newsCategory = newsCategory;
  }

  updateCategory(id, data) {
    this._newsCategory = this._newsCategory.map((newsCategory) =>
      newsCategory.id === id ? { ...data } : { ...newsCategory }
    );
  }

  get newsCategory() {
    return this._newsCategory;
  }
}
