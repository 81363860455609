import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../..';
import '../../utils/i18next';

const TodayVI = observer(() => {
  const { vi } = useContext(Context);
  useEffect(() => {}, [
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  document.title = t('today.pageTitle');
  window.scrollTo(0, 0);
  return (
    <div
      className={
        'container__VI text pb-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className=" text-center mb-3">{t('today.title')}</h1>
      <p>{t('today.text1')}</p>
      <p>{t('today.text2')}</p>
      <p>{t('today.text3')}</p>
      <p>{t('today.text4')}</p>
      <p>{t('today.text5')}</p>
    </div>
  );
});

export default TodayVI;
