import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import NewsCard from '../components/NewsCard';
import { fetchAllNews } from '../http/newsApi';
import { Context } from '../index';
import Pages from '../components/Pages';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Col, Container, Spinner } from 'react-bootstrap';
import { SOCIAL_ACTIVITY_ROUTE } from '../utils/consts';
import { Helmet } from 'react-helmet';

const SocialActivity = observer(() => {
  const { news, page, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    fetchAllNews(3, locale.selectLang, page.limit, page.page)
      .then((data) => {
        news.setNews(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [news, page.page, page.limit, locale.selectLang]);

  const { t } = useTranslation();
  document.title = t('socialActivity.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('socialActivity.pageTitle')}
          meta={[
            {
              name: 'description',
              content: t('socialActivity.pageDescription'),
            },
          ]}
        />
      </Container>
    );

  return (
    <div className="container mt-3 d-flex flex-column min-vh-100">
      <Helmet
        title={t('socialActivity.pageTitle')}
        meta={[
          { name: 'description', content: t('socialActivity.pageDescription') },
        ]}
      />
      <h1 className="d-flex justify-content-center">
        {t('socialActivity.title')}
      </h1>
      <Col className="d-flex justify-content-end mb-3">
        <Pages />
      </Col>
      {news.news?.map((news) =>
        news.newsInfos?.map((newsInfo) => (
          <NewsCard
            key={newsInfo.id}
            title={newsInfo.title}
            newsText={newsInfo.newsText}
            date={news.createdAt}
            img={news.photo ? process.env.REACT_APP_API_URL + news.photo : ''}
            link={SOCIAL_ACTIVITY_ROUTE + '/' + news.id}
          />
        ))
      )}
    </div>
  );
});

export default SocialActivity;
