import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
  Table,
} from 'react-bootstrap';
import { Context } from '../../index';
import Pages from '../../components/Pages';
import PageSize from '../../components/PageSize';
import {
  deleteNews,
  deleteNewsInfo,
  fetchAllNewsByCategory,
  updateNews,
  updateNewsInfo,
} from '../../http/newsApi';
import { fetchAllLocale } from '../../http/localeApi';
import { fetchAllCategory } from '../../http/newsCategoryApi';
import CreateNews from '../../components/modals/CreateNews';
import { insertTab } from '../../utils/insertTab';
import { Link } from 'react-router-dom';
import { ADMIN_NEWS_FILES_ROUTE } from '../../utils/consts';

const News = observer(() => {
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [createVisible, setCreateVisible] = useState(false);
  const [editFile, setFile] = useState(null);
  const [editId, setEditId] = useState('');
  const [editInfoId, setEditInfoId] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [editNewsText, setEditNewsText] = useState('');
  const [lang, setLang] = useState(null);

  const { news, newsCategory, page, locale } = useContext(Context);

  useEffect(() => {
    fetchAllNewsByCategory(selectedCategory, page.limit, page.page).then(
      (data) => {
        news.setNews(data.rows);
        page.setTotalCount(data.count);
      }
    );
    fetchAllLocale().then((data) => locale.setLocale(data));
    fetchAllCategory().then((data) => newsCategory.setCategory(data));
  }, [news, page.page, page.limit, locale, selectedCategory]);

  const chooseCategory = (id) => {
    setSelectedCategory(id);
    fetchAllNewsByCategory(selectedCategory, page.limit, page.page).then(
      (data) => {
        news.setNews(data.rows);
        page.setTotalCount(data.count);
      }
    );
    page.setPage(1);
  };

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const editToggle = (news) => {
    setEditId(news.id);
  };

  const editInfoToggle = (id, info) => {
    setEditInfoId(id);
    setEditTitle(info.title);
    setEditNewsText(info.newsText);
    setLang(info.locale);
  };

  const editNews = () => {
    const formData = new FormData();
    formData.append('photo', editFile);
    updateNews(editId, formData).then((data) => {
      news.updateNews(data.id, data);
      setEditId('');
    });
  };

  const editNewsInfo = (newsId) => {
    updateNewsInfo(editInfoId, editTitle, editNewsText, lang.id, newsId).then(
      (data) => {
        news.updateNews(data.id, data);
        setEditInfoId('');
      }
    );
  };

  const delNews = (id) => {
    deleteNews(id);
    news.removeNews(id);
  };

  const delNewsInfo = (id, newsId) => {
    deleteNewsInfo(id, newsId).then((data) => {
      news.updateNews(data.id, data);
    });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Новости</h1>
      <Row>
        <Col sm={3}>
          Категория:
          <Form.Select onChange={(e) => chooseCategory(e.target.value)}>
            {newsCategory.newsCategory.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <PageSize />
        </Col>
        <Col className="d-flex justify-content-end">
          <Pages />
        </Col>
      </Row>
      <Row>
        {news.news.length !== 0 ? (
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th className="col-2">Фото</th>
                <th>Информация</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {news.news?.map((news) => (
                <tr key={news.id}>
                  <td>
                    <Link to={ADMIN_NEWS_FILES_ROUTE + '/' + news.id}>
                      {news.id}
                    </Link>
                  </td>
                  {news.id === editId ? (
                    <td>
                      <Form.Control
                        onChange={selectFile}
                        className="form-control-sm"
                        type="file"
                      />
                    </td>
                  ) : (
                    <td>
                      <div className="photo-wrapper">
                        {news.photo ? (
                          <Image
                            src={process.env.REACT_APP_API_URL + news.photo}
                            className="photo-mini"
                          />
                        ) : (
                          'нет фото'
                        )}
                      </div>
                    </td>
                  )}
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th className="col-4">Заголовок</th>
                          <th className="col-6">Текст</th>
                          <th className="col-1">Язык</th>
                          <th className="col-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {news.newsInfos?.map((info) =>
                          info.id === editInfoId ? (
                            <tr key={info.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editTitle}
                                  onChange={(e) => setEditTitle(e.target.value)}
                                  placeholder={'Введите заголовок...'}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editNewsText}
                                  onChange={(e) =>
                                    setEditNewsText(e.target.value)
                                  }
                                  onKeyDown={(e) =>
                                    insertTab(e, setEditNewsText, editNewsText)
                                  }
                                  placeholder={'Введите текст новости...'}
                                  as="textarea"
                                  rows={4}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="mb-2"
                                  onClick={() => editInfoToggle('', '')}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editNewsInfo(info.newsId)}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={info.id}>
                              <td>{info.title}</td>
                              <td>{info.newsText}</td>
                              <td>{info.locale.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2 mb-2"
                                  onClick={() => editInfoToggle(info.id, info)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => delNewsInfo(info.id, news.id)}
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  {news.id === editId ? (
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        className="me-2"
                        onClick={() => editToggle('')}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => editNews()}
                      >
                        <i className="bi bi-check2"></i>
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-2 mb-2"
                        onClick={() => editToggle(news)}
                      >
                        Изменить
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => delNews(news.id)}
                      >
                        Удалить
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateNews show={createVisible} onHide={() => setCreateVisible(false)} />
    </Container>
  );
});

export default News;
