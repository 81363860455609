import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { fetchAllNews } from '../../http/newsApi';
import { Context } from '../../index';
import Pages from '../../components/VI/Pages';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { Container, Col, Spinner } from 'react-bootstrap';
import { NEWS_ROUTE } from '../../utils/consts';
import NewsCardVI from '../../components/VI/NewsCardVI';

const NewsVI = observer(() => {
  const { news, page, locale, vi } = useContext(Context);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    fetchAllNews(1, locale.selectLang, page.limit, page.page)
      .then((data) => {
        news.setNews(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [
    news,
    page.page,
    page.limit,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const { t } = useTranslation();
  document.title = t('news.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div
      className={
        'container__VI pb-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <Col className="d-flex justify-content-end mb-3">
        <Pages />
      </Col>
      {news.news?.map((news) =>
        news.newsInfos?.map((newsInfo) => (
          <NewsCardVI
            key={newsInfo.id}
            id={news.id}
            title={newsInfo.title}
            newsText={newsInfo.newsText}
            date={news.createdAt}
            link={NEWS_ROUTE + '/' + news.id}
            img={news.photo ? process.env.REACT_APP_API_URL + news.photo : ''}
          />
        ))
      )}
    </div>
  );
});

export default NewsVI;
