import Admin from '../pages/Admin';
import Auth from '../pages/Auth';
import Main from '../pages/Main';
import {
  ADMIN_ADMINREQUESTS_ROUTE,
  ADMIN_ROUTE,
  AUTH_ROUTE,
  ADMIN_BRAND_ROUTE,
  ADMIN_CATEGORY_ROUTE,
  MAIN_ROUTE,
  ADMIN_PRODUCT_ROUTE,
  ADMIN_STORE_ROUTE,
  ADMIN_VACANCY_ROUTE,
  PRODUCTS_ROUTE,
  VACANCY_ROUTE,
  HISTORY_ROUTE,
  AWARDS_ROUTE,
  STORE_ROUTE,
  CONTACT_ROUTE,
  NEWS_ROUTE,
  STRUCTURE_ROUTE,
  ENTERPRISE_TODAY_ROUTE,
  QUALITY_ROUTE,
  TRADE_UNION_ROUTE,
  TRADE_UNION_STRUCTURE_ROUTE,
  SOCIAL_ACTIVITY_ROUTE,
  ADMIN_ENTERPRISE_ADMINISTRATION_ROUTE,
  ADMIN_LOCALE_ROUTE,
  ADMIN_NEWS_ROUTE,
  ADMIN_NEWS_CATEGORY_ROUTE,
  ADMIN_CURRENCY_ROUTE,
  ECO_ROUTE,
  ADMIN_TRADE_UNION_ROUTE,
  ADMIN_AWARD_ROUTE,
  ADMIN_NEWS_FILES_ROUTE,
  ADMIN_RECIPE_ROUTE,
  RECIPE_ROUTE,
  ADMIN_FILES_ROUTE,
  PRICE_ROUTE,
  FEEDBACK_ROUTE,
  RECEPTOION_SCHEDULE,
  MCHS,
} from './consts';
import Admin_ProductAvailability from '../pages/Admin/ProductAvailability';
import Admin_Brand from '../pages/Admin/Brand';
import Admin_Category from '../pages/Admin/Category';
import Admin_Product from '../pages/Admin/Product';
import Admin_Vacancy from '../pages/Admin/Vacancy';
import Admin_Request from '../pages/Admin/Request';
import Admin_EnterpriseAdministration from '../pages/Admin/EnterpriseAdministration';
import Admin_TradeUnion from '../pages/Admin/TradeUnion';
import Admin_Store from '../pages/Admin/Store';
import Admin_Locale from '../pages/Admin/Locale';
import Admin_StoreBrand from '../pages/Admin/StoreBrand';
import Admin_News from '../pages/Admin/News';
import Admin_NewsCategory from '../pages/Admin/NewsCategory';
import Admin_NewsFiles from '../pages/Admin/NewsFiles';
import Admin_Currency from '../pages/Admin/Currency';
import Admin_Award from '../pages/Admin/Award';
import Admin_Recipe from '../pages/Admin/Recipe';
import Admin_Files from '../pages/Admin/Files';
import Product from '../pages/Product';
import Vacancy from '../pages/Vacancy';
import VacancyVI from '../pages/VI/VacancyVI';
import History from '../pages/History';
import HistoryVI from '../pages/VI/HistoryVI';
import Awards from '../pages/Awards';
import Store from '../pages/Store';
import StoreVI from '../pages/VI/StoreVI';
import Contact from '../pages/Contact';
import ContactVI from '../pages/VI/ContactsVI';
import News from '../pages/News';
import NewsFull from '../pages/NewsFull';
import Structure from '../pages/Structure';
import StructureVI from '../pages/VI/StructureVI';
import TradeUnionStructure from '../pages/TradeUnionStructute';
import EnterpriseToday from '../pages/EnterpriseToday';
import TodayVI from '../pages/VI/TodayVI';
import Quality from '../pages/Quality';
import TradeUnion from '../pages/TradeUnion';
import TradeUnionVI from '../pages/VI/TradeUnionVI';
import SocialActivity from '../pages/SocialActivity';
import SocialActivityVI from '../pages/VI/SocialActivityVI';
import ProductInfo from '../pages/ProductInfo';
import Eco from '../pages/Eco';
import Recipe from '../pages/Recipes';
import RecipesVI from '../pages/VI/RecipesVI';
import RecipeFull from '../pages/RecipeFull';
import Price from '../pages/Price';
import PriceVI from '../pages/VI/PriceVI';
import Feedback from '../pages/Feedback';
import FeedbackVI from '../pages/VI/FeedbackVI';
import ReceptionSchedule from '../pages/ReceptionSchedule';
import ReceptionScheduleVI from '../pages/VI/ReceptionScheduleVI';
import ProductVI from '../pages/VI/ProductVI';
import ProductInfoVI from '../pages/VI/ProductInfoVI';
import NewsVI from '../pages/VI/NewsVI';
import QualityVI from '../pages/VI/QualityVI';
import AwardsVI from '../pages/VI/AwardsVI';
import NewsFullVI from '../pages/VI/NewsFullVI';
import TradeUnionStructureVI from '../pages/VI/TradeUnionStructureVI';
import RecipeFullVI from '../pages/VI/RecipeFullVI';
import MainVI from '../pages/VI/MainVI';
import Mchs from '../pages/MCHS'

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: Admin,
  },
  {
    path: ADMIN_BRAND_ROUTE,
    Component: Admin_Brand,
  },
  {
    path: ADMIN_CATEGORY_ROUTE,
    Component: Admin_Category,
  },
  {
    path: ADMIN_STORE_ROUTE,
    Component: Admin_Store,
  },
  {
    path: ADMIN_STORE_ROUTE + '/:id',
    Component: Admin_StoreBrand,
  },
  {
    path: ADMIN_PRODUCT_ROUTE,
    Component: Admin_Product,
  },
  {
    path: ADMIN_PRODUCT_ROUTE + '/:id',
    Component: Admin_ProductAvailability,
  },
  {
    path: ADMIN_VACANCY_ROUTE,
    Component: Admin_Vacancy,
  },
  {
    path: ADMIN_ADMINREQUESTS_ROUTE,
    Component: Admin_Request,
  },
  {
    path: ADMIN_ENTERPRISE_ADMINISTRATION_ROUTE,
    Component: Admin_EnterpriseAdministration,
  },
  {
    path: ADMIN_TRADE_UNION_ROUTE,
    Component: Admin_TradeUnion,
  },
  {
    path: ADMIN_LOCALE_ROUTE,
    Component: Admin_Locale,
  },
  {
    path: ADMIN_NEWS_ROUTE,
    Component: Admin_News,
  },
  {
    path: ADMIN_NEWS_CATEGORY_ROUTE,
    Component: Admin_NewsCategory,
  },
  {
    path: ADMIN_NEWS_FILES_ROUTE + '/:id',
    Component: Admin_NewsFiles,
  },
  {
    path: ADMIN_CURRENCY_ROUTE,
    Component: Admin_Currency,
  },
  {
    path: ADMIN_AWARD_ROUTE,
    Component: Admin_Award,
  },
  {
    path: ADMIN_RECIPE_ROUTE,
    Component: Admin_Recipe,
  },
  {
    path: ADMIN_FILES_ROUTE,
    Component: Admin_Files,
  },
];

export const publicRoutes = [
  {
    path: AUTH_ROUTE,
    Component: Auth,
  },
  {
    path: MAIN_ROUTE,
    Component: Main,
  },
  {
    path: PRODUCTS_ROUTE,
    Component: Product,
  },
  {
    path: PRODUCTS_ROUTE + '/:id',
    Component: ProductInfo,
  },
  {
    path: VACANCY_ROUTE,
    Component: Vacancy,
  },
  {
    path: HISTORY_ROUTE,
    Component: History,
  },
  {
    path: AWARDS_ROUTE,
    Component: Awards,
  },
  {
    path: STORE_ROUTE,
    Component: Store,
  },
  {
    path: CONTACT_ROUTE,
    Component: Contact,
  },
  {
    path: NEWS_ROUTE,
    Component: News,
  },
  {
    path: NEWS_ROUTE + '/:id',
    Component: NewsFull,
  },
  {
    path: STRUCTURE_ROUTE,
    Component: Structure,
  },
  {
    path: TRADE_UNION_STRUCTURE_ROUTE,
    Component: TradeUnionStructure,
  },
  {
    path: ENTERPRISE_TODAY_ROUTE,
    Component: EnterpriseToday,
  },
  {
    path: QUALITY_ROUTE,
    Component: Quality,
  },
  {
    path: TRADE_UNION_ROUTE,
    Component: TradeUnion,
  },
  {
    path: TRADE_UNION_ROUTE + '/:id',
    Component: NewsFull,
  },
  {
    path: SOCIAL_ACTIVITY_ROUTE,
    Component: SocialActivity,
  },
  {
    path: SOCIAL_ACTIVITY_ROUTE + '/:id',
    Component: NewsFull,
  },
  {
    path: ECO_ROUTE + '/:id',
    Component: Eco,
  },
  {
    path: RECIPE_ROUTE,
    Component: Recipe,
  },
  {
    path: RECIPE_ROUTE + '/:id',
    Component: RecipeFull,
  },
  {
    path: PRICE_ROUTE,
    Component: Price,
  },
  {
    path: FEEDBACK_ROUTE,
    Component: Feedback,
  },
  {
    path: RECEPTOION_SCHEDULE,
    Component: ReceptionSchedule,
  },
  {
    path: MCHS,
    Component: Mchs,
  },
];

export const publicRoutesVI = [
  {
    path: AUTH_ROUTE,
    Component: Auth,
  },
  {
    path: MAIN_ROUTE,
    Component: MainVI,
  },
  {
    path: PRODUCTS_ROUTE,
    Component: ProductVI,
  },
  {
    path: PRODUCTS_ROUTE + '/:id',
    Component: ProductInfoVI,
  },
  {
    path: VACANCY_ROUTE,
    Component: VacancyVI,
  },
  {
    path: HISTORY_ROUTE,
    Component: HistoryVI,
  },
  {
    path: AWARDS_ROUTE,
    Component: AwardsVI,
  },
  {
    path: STORE_ROUTE,
    Component: StoreVI,
  },
  {
    path: CONTACT_ROUTE,
    Component: ContactVI,
  },
  {
    path: NEWS_ROUTE,
    Component: NewsVI,
  },
  {
    path: NEWS_ROUTE + '/:id',
    Component: NewsFullVI,
  },
  {
    path: STRUCTURE_ROUTE,
    Component: StructureVI,
  },
  {
    path: TRADE_UNION_STRUCTURE_ROUTE,
    Component: TradeUnionStructureVI,
  },
  {
    path: ENTERPRISE_TODAY_ROUTE,
    Component: TodayVI,
  },
  {
    path: QUALITY_ROUTE,
    Component: QualityVI,
  },
  {
    path: TRADE_UNION_ROUTE,
    Component: TradeUnionVI,
  },
  {
    path: TRADE_UNION_ROUTE + '/:id',
    Component: NewsFullVI,
  },
  {
    path: SOCIAL_ACTIVITY_ROUTE,
    Component: SocialActivityVI,
  },
  {
    path: SOCIAL_ACTIVITY_ROUTE + '/:id',
    Component: NewsFullVI,
  },
  {
    path: ECO_ROUTE + '/:id',
    Component: Eco,
  },
  {
    path: RECIPE_ROUTE,
    Component: RecipesVI,
  },
  {
    path: RECIPE_ROUTE + '/:id',
    Component: RecipeFullVI,
  },
  {
    path: PRICE_ROUTE,
    Component: PriceVI,
  },
  {
    path: FEEDBACK_ROUTE,
    Component: FeedbackVI,
  },
  {
    path: RECEPTOION_SCHEDULE,
    Component: ReceptionScheduleVI,
  },
];
