import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import StoreCard from '../components/StoreCard';
import { fetchAllStoreLang } from '../http/storeApi';
import { Context } from '../index';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import '../utils/i18next';

import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Helmet } from 'react-helmet';

const mapData = {
  center: [52.43416, 31.014272],
  zoom: 12,
};

const Shop = observer(() => {
  const { store, page, locale } = useContext(Context);
  const [coordinates, setCoordinates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState([]);

  useEffect(() => {
    fetchAllStoreLang(locale.selectLang).then((data) => {
      store.setStore(data.rows);
      page.setTotalCount(data.count);
      setLoading(false);
    });
    if (locale.selectLang === 4) {
      fetchAllStoreLang(2).then((data) => {
        setAddress(data.rows);
      });
    } else {
      fetchAllStoreLang(locale.selectLang).then((data) => {
        setAddress(data.rows);
      });
    }
  }, [store, page, locale.selectLang]);

  const { t } = useTranslation();
  document.title = t('store.pageTitle');

  const geocode = (ymaps) => {
    address.map((store) =>
      store.storeInfos.map((storeInfo) =>
        ymaps
          .geocode(storeInfo.address)
          .then((result) =>
            setCoordinates((coordinates) => [
              ...coordinates,
              [
                result.geoObjects.get(0).geometry.getCoordinates(),
                storeInfo.address,
              ],
            ])
          )
      )
    );
  };

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('store.pageTitle')}
          meta={[{ name: 'description', content: t('store.pageDescription') }]}
        />
      </Container>
    );
  return (
    <Container className="mt-5 d-flex flex-column min-vh-100">
      <Helmet
        title={t('store.pageTitle')}
        meta={[{ name: 'description', content: t('store.pageDescription') }]}
      />
      <Row>
        {store.store.map((store) =>
          store.storeInfos.map((storeInfo) => (
            <Col key={store.id} md={6}>
              <StoreCard
                img={process.env.REACT_APP_API_URL + store.photo}
                name={storeInfo.name}
                address={storeInfo.address}
              />
            </Col>
          ))
        )}
      </Row>
      <div className="mb-5">
        <YMaps
          query={{
            apikey: '8f057781-9a9c-4cf5-bd8b-4e45ec94640b',
            load: 'Map,Placemark,geoObject.addon.balloon,geoObject.addon.hint',
          }}
        >
          <Map
            modules={['geocode']}
            onLoad={(ymaps) => geocode(ymaps)}
            style={{ width: '100%', height: '400px' }}
            defaultState={mapData}
          >
            {coordinates?.map((coordinate) => (
              <Placemark
                key={coordinate[0]}
                geometry={coordinate[0]}
                properties={{
                  hintContent: coordinate[1],
                }}
              />
            ))}
          </Map>
        </YMaps>
      </div>
    </Container>
  );
});

export default Shop;
