import React, { useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';

const FooterVI = observer(() => {
  const { vi } = useContext(Context);
  const { t } = useTranslation();
  useEffect(() => {}, [
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  return (
    <div
      className={
        'container__VI footer__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <Row>
        <Row>
          <p>{t('footer.quality')}</p>
          <p>
            <a
              className="link__VI pe-3"
              href="tel:+375232344256"
              target="_blank"
              rel="noreferrer"
            >
              +375232344256
            </a>
            <a
              className="link__VI"
              href="mailto:muka@mail.gomel.by"
              target="_blank"
              rel="noreferrer"
            >
              muka@mail.gomel.by
            </a>
          </p>
        </Row>
        <Row>
          <p>{t('footer.export')}</p>
          <p>
            <a
              className="link__VI pe-3"
              href="tel:+375232344891"
              target="_blank"
              rel="noreferrer"
            >
              +375232344891
            </a>
            <a
              className="link__VI"
              href="mailto:mukatam@mail.gomel.by"
              target="_blank"
              rel="noreferrer"
            >
              mukatam@mail.gomel.by
            </a>
          </p>
        </Row>
        <Row>
          <p>{t('footer.sale')}</p>
          <p>
            <a
              className="link__VI pe-3"
              href="tel:+375232344226"
              target="_blank"
              rel="noreferrer"
            >
              +375232344226
            </a>
            <a
              className="link__VI"
              href="mailto:sbitgomelkhp@mail.gomel.by"
              target="_blank"
              rel="noreferrer"
            >
              sbitgomelkhp@mail.gomel.by
            </a>
          </p>
        </Row>
        <Row>
          <p>
            <a
              className="link__VI"
              href="https://president.gov.by/ru"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.president')}
            </a>
          </p>
          <p>
            <a
              className="link__VI"
              href="http://mininform.gov.by/"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.ministry')}
            </a>
          </p>
          <p>
            <a
              className="link__VI"
              href="https://pravo.by/"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.law')}
            </a>
          </p>
          <p>
            <a
              className="link__VI"
              href={
                process.env.REACT_APP_API_URL +
                'Политика_обработки_персональных_данных.pdf'
              }
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.politics')}
            </a>
          </p>
        </Row>
      </Row>
      <Row className="mt-2">
        <p>{t('footer.copyright')}</p>
      </Row>
    </div>
  );
});

export default FooterVI;
