import { makeAutoObservable } from 'mobx';

export default class RecipeStore {
  constructor() {
    this._recipe = [];
    makeAutoObservable(this);
  }

  setRecipe(recipe) {
    this._recipe = recipe;
  }

  addRecipe(recipe) {
    this._recipe.push(recipe);
  }

  updateRecipe(id, data) {
    this._recipe = this._recipe.map((recipe) =>
      recipe.id === id ? { ...data } : { ...recipe }
    );
  }

  removeRecipe(id) {
    this._recipe = this._recipe.filter((recipe) => recipe.id !== id);
  }

  get recipe() {
    return this._recipe;
  }
}
