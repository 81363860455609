import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import CreateEnterpriseAdministration from '../../components/modals/CreateEnterpriseAdministration';
import Pages from '../../components/Pages';
import PageSize from '../../components/PageSize';
import {
  deleteEnterpriseAdministration,
  deleteEnterpriseAdministrationInfo,
  fetchAllEnterpriseAdministration,
  updateEnterpriseAdministration,
  updateEnterpriseAdministrationInfo,
} from '../../http/enterpriseAdministrationApi';
import { Context } from '../../index';

const EnterpriseAdministration = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [fullName, setFullName] = useState('');
  const [position, setPosition] = useState('');
  const [about, setAbout] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [editId, setEditId] = useState('');
  const [editInfoId, setEditInfoId] = useState('');
  const [lang, setLang] = useState(null);

  const { enterpriseAdministration, locale } = useContext(Context);

  useEffect(() => {
    fetchAllEnterpriseAdministration().then((data) => {
      enterpriseAdministration.setEnterpriseAdministration(data);
    });
  }, [enterpriseAdministration]);

  const editToggle = (id, enterpriseAdministration) => {
    setEditId(id);
    setPhone(enterpriseAdministration.phone);
    setMail(enterpriseAdministration.mail);
  };

  const editInfoToggle = (id, info) => {
    setEditInfoId(id);
    setFullName(info.fullName);
    setPosition(info.position);
    setAbout(info.about);
    setLang(info.locale);
  };

  const editAdministration = () => {
    const formData = new FormData();
    formData.append('phone', phone);
    formData.append('mail', mail);
    updateEnterpriseAdministration(editId, formData).then((data) => {
      enterpriseAdministration.updateEnterpriseAdministration(data.id, data);
      setEditId('');
    });
  };

  const editInfo = () => {
    updateEnterpriseAdministrationInfo(
      editInfoId,
      fullName,
      position,
      about,
      lang.id
    ).then((data) => {
      enterpriseAdministration.updateEnterpriseAdministration(data.id, data);
      setEditInfoId('');
    });
  };

  const delEnterpriseAdministration = (id) => {
    deleteEnterpriseAdministration(id);
    enterpriseAdministration.removeEnterpriseAdministration(id);
  };

  const delInfo = (id, administrationId) => {
    deleteEnterpriseAdministrationInfo(id, administrationId).then((data) => {
      enterpriseAdministration.updateEnterpriseAdministration(data.id, data);
    });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Администрация</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <PageSize />
        </Col>
        <Col className="d-flex justify-content-end">
          <Pages />
        </Col>
      </Row>
      <Row>
        {enterpriseAdministration.enterpriseAdministration.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Телефон</th>
                <th>Почта</th>
                <th>Информация</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {enterpriseAdministration.enterpriseAdministration?.map(
                (administration) => (
                  <tr key={administration.id}>
                    <td>{administration.id}</td>
                    {administration.id === editId ? (
                      <>
                        <td>
                          <Form.Control
                            className="form-control-sm"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder={'Введите номер телефона'}
                          />
                        </td>
                        <td>
                          <Form.Control
                            className="form-control-sm"
                            value={mail}
                            onChange={(e) => setMail(e.target.value)}
                            placeholder={'Введите почту'}
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{administration.phone}</td>
                        <td>{administration.mail}</td>
                      </>
                    )}
                    <td>
                      <Table>
                        <thead>
                          <tr>
                            <th>ФИО</th>
                            <th>Должность</th>
                            <th>Информация</th>
                            <th>Язык</th>
                            <th className="col-1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {administration.enterpriseAdministrationInfos?.map(
                            (info) =>
                              info.id === editInfoId ? (
                                <tr key={info.id}>
                                  <td>
                                    <Form.Control
                                      className="form-control-sm"
                                      value={fullName}
                                      onChange={(e) =>
                                        setFullName(e.target.value)
                                      }
                                      placeholder={'Введите ФИО'}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      className="form-control-sm"
                                      value={position}
                                      onChange={(e) =>
                                        setPosition(e.target.value)
                                      }
                                      placeholder={'Введите должность'}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      className="form-control-sm"
                                      value={about}
                                      onChange={(e) => setAbout(e.target.value)}
                                      placeholder={'Введите информацию'}
                                      as="textarea"
                                      rows={5}
                                    />
                                  </td>
                                  <td>
                                    <Dropdown>
                                      <Dropdown.Toggle size="sm">
                                        {lang ? lang.name : 'Выберите язык'}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {locale.locale.map((locale) => (
                                          <Dropdown.Item
                                            key={locale.id}
                                            onClick={() => setLang(locale)}
                                          >
                                            {locale.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      className="mb-2"
                                      onClick={() => editInfoToggle('', '')}
                                    >
                                      <i className="bi bi-x-lg"></i>
                                    </Button>
                                    <Button
                                      variant="success"
                                      size="sm"
                                      onClick={() => editInfo()}
                                    >
                                      <i className="bi bi-check2"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ) : (
                                <tr key={info.id}>
                                  <td>{info.fullName}</td>
                                  <td>{info.position}</td>
                                  <td>{info.about}</td>
                                  <td>{info.locale.name}</td>
                                  <td>
                                    <Button
                                      variant="warning"
                                      size="sm"
                                      className="me-2 mb-2"
                                      onClick={() =>
                                        editInfoToggle(info.id, info)
                                      }
                                    >
                                      <i className="bi bi-pencil"></i>
                                    </Button>
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() =>
                                        delInfo(info.id, administration.id)
                                      }
                                    >
                                      <i className="bi bi-x-circle"></i>
                                    </Button>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table>
                    </td>
                    {administration.id === editId ? (
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={() => editToggle('', '')}
                        >
                          <i className="bi bi-x-lg"></i>
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => editAdministration()}
                        >
                          <i className="bi bi-check2"></i>
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="me-2 mb-2"
                          onClick={() =>
                            editToggle(administration.id, administration)
                          }
                        >
                          Изменить
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            delEnterpriseAdministration(administration.id)
                          }
                        >
                          Удалить
                        </Button>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateEnterpriseAdministration
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default EnterpriseAdministration;
