import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import AwardCard from '../components/AwardCard';
import { fetchAllAwardLang } from '../http/awardApi';
import { Context } from '../index';
import '../utils/i18next';

const Awards = observer(() => {
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  document.title = t('awards.pageTitle');
  window.scrollTo(0, 0);
  const awards = t('awards.text', { returnObjects: true });

  const { award, locale } = useContext(Context);

  useEffect(() => {
    fetchAllAwardLang(locale.selectLang)
      .then((data) => {
        award.setAward(data);
      })
      .then(() => {
        setLoading(false);
      });
  }, [award, locale.selectLang]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('awards.pageTitle')}
          meta={[{ name: 'description', content: t('awards.pageDescription') }]}
        />
      </Container>
    );

  return (
    <div className="container d-flex flex-column min-vh-100">
      <Helmet
        title={t('awards.pageTitle')}
        meta={[{ name: 'description', content: t('awards.pageDescription') }]}
      />
      <h1 className="text-center mt-5 mb-3">{t('awards.title')}</h1>
      <Row className="d-flex align-items-stretch">
        {award.award?.map((award) =>
          award.awardInfos.map((info) => (
            <Col sm={6} md={4} key={award.id}>
              <AwardCard text={info.description} img={award.photo} />
            </Col>
          ))
        )}
      </Row>
    </div>
  );
});

export default Awards;
