import { makeAutoObservable } from 'mobx';

export default class StoreStore {
  constructor() {
    this._store = [];
    this._storeLang = [];
    makeAutoObservable(this);
  }

  setStore(store) {
    this._store = store;
  }

  setStoreLang(store) {
    this._storeLang = store;
  }

  addStore(store) {
    this._store.push(store);
  }

  updateStore(id, data) {
    this._store = this._store.map((store) =>
      store.id === id ? { ...data } : { ...store }
    );
  }

  removeStore(id) {
    this._store = this._store.filter((store) => store.id !== id);
  }

  get store() {
    return this._store;
  }

  get storeLang() {
    return this._storeLang;
  }
}
