import React, { useState } from 'react';
import ContactCard from '../components/ContactCard';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const { t } = useTranslation();
  const [coordinates, setCoordinates] = useState([]);
  document.title = t('contacts.pageTitle');
  const contacts = t('contactCard', { returnObjects: true });

  const mapData = {
    center: [52.44216, 31.02072],
    zoom: 14,
  };

  const address = ['г. Гомель, ул. Подгорная, 4'];

  const geocode = (ymaps) => {
    address.map((address) =>
      ymaps
        .geocode(address)
        .then((result) =>
          setCoordinates((coordinates) => [
            ...coordinates,
            [result.geoObjects.get(0).geometry.getCoordinates(), address],
          ])
        )
    );
  };
  return (
    <>
      <Helmet
        title={t('contacts.pageTitle')}
        meta={[{ name: 'description', content: t('contacts.pageDescription') }]}
      />
      <div className="container mt-5">
        <h1 className="text-center mb-3">{t('contacts.title')}</h1>
        {contacts.map((contact) => (
          <ContactCard
            key={contact.name}
            name={contact.name}
            contacts={contact.contacts}
          />
        ))}
      </div>
      <Container className="mb-5">
        <YMaps
          query={{
            apikey: '8f057781-9a9c-4cf5-bd8b-4e45ec94640b',
            load: 'Map,Placemark,geoObject.addon.balloon,geoObject.addon.hint',
          }}
        >
          <Map
            modules={['geocode']}
            onLoad={(ymaps) => geocode(ymaps)}
            style={{ width: '100%', height: '400px' }}
            defaultState={mapData}
          >
            {coordinates?.map((coordinate) => (
              <Placemark
                key={coordinate[0]}
                geometry={coordinate[0]}
                properties={{
                  hintContent: coordinate[1],
                }}
              />
            ))}
          </Map>
        </YMaps>
      </Container>
    </>
  );
};

export default Contact;
