import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';

const ProductCardVI = ({ img, name, ingredients, link }) => {
  const { t } = useTranslation();
  return (
    <div className="recipe_cardVI">
      <h3 className="">{name}</h3>
      <p className="">{ingredients}</p>
      <Link to={link} className="link__VI float-end">
        {t('recipes.more')}
      </Link>
    </div>
  );
};

export default ProductCardVI;
