import { makeAutoObservable } from 'mobx';

export default class ProductAvailabilityStore {
  constructor() {
    this._productAvailability = [];
    makeAutoObservable(this);
  }

  setProductAvailability(productAvailability) {
    this._productAvailability = productAvailability;
  }

  addProductAvailability(productAvailability) {
    this._productAvailability.push(productAvailability);
  }

  updateProductAvailability(id, data) {
    this._productAvailability = this._productAvailability.map(
      (productAvailability) =>
        productAvailability.id === id ? { ...data } : { ...productAvailability }
    );
  }

  removeProductAvailability(id) {
    this._productAvailability = this._productAvailability.filter(
      (productAvailability) => productAvailability.id !== id
    );
  }

  get productAvailability() {
    return this._productAvailability;
  }
}
