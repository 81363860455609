import { makeAutoObservable } from 'mobx';

export default class CategoryStore {
  constructor() {
    this._category = [];
    this._selectCategory = null;
    makeAutoObservable(this);
  }

  setCategory(category) {
    this._category = category;
  }

  setSelectCategory(categoryId) {
    this._selectCategory = categoryId;
  }

  addСategory(category) {
    this._category.push(category);
  }

  updateСategory(id, data) {
    this._category = this._category.map((category) =>
      category.id === id ? { ...data } : { ...category }
    );
  }

  removeСategory(id) {
    this._category = this._category.filter((category) => category.id !== id);
  }

  get category() {
    return this._category;
  }

  get selectCategory() {
    return this._selectCategory;
  }
}
