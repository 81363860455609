import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const EcoCard = ({ heading, icon, link }) => {
  const { t } = useTranslation();
  return (
    <div className="service">
      <div className="icon">
        <i className={icon}></i>
      </div>
      <h2 className="heading heading-eco">{heading}</h2>
      <div className='eco-btn'>
        <Link to={link} className="btn__card">
          {t('main.more')}
        </Link>
      </div>
    </div>
  );
};

export default EcoCard;
