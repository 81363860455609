import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Table, Row, Spinner } from 'react-bootstrap';
import { Context } from '../../index';
import { useParams } from 'react-router-dom';
import CreateStoreBrand from '../../components/modals/CreateStoreBrand';
import { deleteStoreBrand, fetchAllStoreBrand } from '../../http/storeBrandApi';

const StoreBrand = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const { storeBrand, store } = useContext(Context);
  const params = useParams();

  useEffect(() => {
    fetchAllStoreBrand(params.id)
      .then((data) => storeBrand.setStoreBrand(data))
      .then(() => setLoading(false));
  }, [storeBrand, params.id, store]);

  const delStoreBrand = (id) => {
    deleteStoreBrand(id);
    storeBrand.removeStoreBrand(id);
  };

  if (loading) return <Spinner animation={'grow'} />;

  return (
    <Container>
      <h1 className="d-flex justify-content-center">
        Производители в магазине
      </h1>
      <Button variant="outline-success" onClick={() => setCreateVisible(true)}>
        <i className="bi bi-plus-square"></i> Добавить
      </Button>
      <Row>
        {storeBrand.storeBrand.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th>Код производителя</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {storeBrand.storeBrand?.map((storeBrand) => (
                <tr key={storeBrand.id}>
                  <td>{storeBrand.id}</td>
                  <td>{storeBrand.brand?.brandInfos[0]?.name}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => delStoreBrand(storeBrand.id)}
                    >
                      <i className="bi bi-x-circle"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateStoreBrand
        storeId={params.id}
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default StoreBrand;
