import React, { useContext, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import {
  createRecipe,
  createRecipeInfo,
  fetchAllRecipeLang,
} from '../../http/recipeApi';
import { Context } from '../../index';

const CreateRecipe = ({ show, onHide }) => {
  const { recipe, locale } = useContext(Context);
  const [toggle, setToggle] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [name, setName] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [manual, setManual] = useState('');
  const [time, setTime] = useState('');
  const [file, setFile] = useState(null);
  const [lang, setLang] = useState();
  const [error, setError] = useState();

  const submitHandler = (type) => {
    const formData = new FormData();
    if (type === 'new') {
      formData.append('name', name);
      formData.append('ingredients', ingredients);
      formData.append('manual', manual);
      formData.append('time', time);
      formData.append('photo', file);
      formData.append('localeId', lang.id);
    }
    type === 'info'
      ? createRecipeInfo(
          name,
          ingredients,
          manual,
          lang.id,
          selectedRecipe.id
        ).then((data) => {
          onHide();
          recipe.updateRecipe(data.id, data);
        })
      : createRecipe(formData).then((data) => {
          onHide();
          recipe.addRecipe(data);
        });

    setName('');
    setIngredients('');
    setManual('');
    setTime('');
    setSelectedRecipe(null);
    setFile(null);
  };

  const addStore = () => {
    lang ? submitHandler('new') : setError('lang');
  };

  const addStoreInfo = () => {
    lang ? submitHandler('info') : setError('lang');
  };

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить рецепт
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle className={error === 'store' ? 'error' : ''}>
                {selectedRecipe
                  ? selectedRecipe.recipeInfos[0].name
                  : 'Выберите рецепт'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {recipe.recipe.map((recipe) =>
                  recipe.recipeInfos.map((info) =>
                    info.localeId === 1 ? (
                      <Dropdown.Item
                        key={info.id}
                        onClick={() => {
                          setSelectedRecipe(recipe);
                          setError('');
                        }}
                      >
                        {info.name}
                      </Dropdown.Item>
                    ) : (
                      <></>
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Form.Control
                onChange={selectFile}
                className="mb-3"
                type="file"
              />

              <Form.Control
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                className={'my-3'}
                placeholder={'Введите время приготовления (ч)'}
              />
            </>
          )}

          <Form.Control
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className={'mb-3'}
            placeholder={'Введите название рецепта'}
          />
          <Form.Control
            value={ingredients}
            onChange={(e) => {
              setIngredients(e.target.value);
            }}
            className={'my-3'}
            placeholder={'Введите ингедиенты'}
            as="textarea"
            rows={2}
          />
          <Form.Control
            value={manual}
            onChange={(e) => {
              setManual(e.target.value);
            }}
            className={'my-3'}
            placeholder={'Введите рецепт'}
            as="textarea"
            rows={2}
          />
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          onClick={toggle ? addStoreInfo : addStore}
        >
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateRecipe;
