import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import BrandStore from './store/BrandStore';
import CategoryStore from './store/CategoryStore';
import ProductStore from './store/ProductStore';
import ProductAvailabilityStore from './store/ProductAvailabilityStore';
import RequestStore from './store/RequestStore';
import StoreStore from './store/StoreStore';
import StoreBrandStore from './store/StoreBrandStore';
import UserStore from './store/UserStore';
import VacancyStore from './store/VacancyStore';
import NewsStore from './store/NewsStore';
import NewsCategoryStore from './store/NewsCategoryStore';
import NewsPhotoStore from './store/NewsPhotoStore';
import NewsFileStore from './store/NewsFileStore';
import EnterpriseAdministrationStore from './store/EnterpriseAdministrationStore';
import PageStore from './store/PageStore';
import LocaleStore from './store/LocaleStore';
import TradeUnionStore from './store/TradeUnionStore';
import AwardStore from './store/AwardStore';
import RecipeStore from './store/RecipeStore';
import FIlesFtore from './store/FilesStore';
import VIStore from './store/VIStore';

export const Context = createContext(null);

ReactDOM.render(
  <Context.Provider
    value={{
      user: new UserStore(),
      brand: new BrandStore(),
      category: new CategoryStore(),
      store: new StoreStore(),
      storeBrand: new StoreBrandStore(),
      product: new ProductStore(),
      productAvailability: new ProductAvailabilityStore(),
      request: new RequestStore(),
      vacancy: new VacancyStore(),
      enterpriseAdministration: new EnterpriseAdministrationStore(),
      tradeUnion: new TradeUnionStore(),
      news: new NewsStore(),
      newsCategory: new NewsCategoryStore(),
      newsPhoto: new NewsPhotoStore(),
      newsFile: new NewsFileStore(),
      award: new AwardStore(),
      recipe: new RecipeStore(),
      files: new FIlesFtore(),
      page: new PageStore(),
      locale: new LocaleStore(),
      vi: new VIStore(),
    }}
  >
    <App />
  </Context.Provider>,
  document.getElementById('root')
);
