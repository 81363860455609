import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const MCHS = () => {
  const { t } = useTranslation();
  document.title = t('MES.pageTitle');
  window.scrollTo(0, 0);
  return (
    <div className="container mt-5 d-flex flex-column min-vh-100">
      <div className="item text p-3">
        <h1 className="text-center"> {t('MES.title')}</h1>
        <Row className="mt-3">
          <Col>
            <p>
              <a
                href={
                  process.env.REACT_APP_API_URL +
                  'Pravila_povedeniya_na_vode.pdf'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('MES.file1')}
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MCHS;
