import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import {
  deleteVacancy,
  deleteVacancyInfo,
  fetchAllVacancy,
  updateVacancy,
  updateVacancyInfo,
} from '../../http/vacancyApi';
import { Context } from '../../index';
import CreateVacancy from '../../components/modals/CreateVacancy';
import Pages from '../../components/Pages';
import PageSize from '../../components/PageSize';

const Vacancy = observer(() => {
  const { vacancy, page, locale } = useContext(Context);
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editInfoId, setEditInfoId] = useState('');
  const [editJobTitle, setEditJobTitle] = useState('');
  const [editRequiredSpecialty, setEditRequiredSpecialty] = useState('');
  const [editWageMin, setEditWageMin] = useState('');
  const [editWageMax, setEditWageMax] = useState('');
  const [editWorkingConditions, setEditWorkingConditions] = useState('');
  const [editRequiredExperienceFrom, setEditRequiredExperienceFrom] =
    useState('');
  const [editRequiredExperienceUpTo, setEditRequiredExperienceUpTo] =
    useState('');
  const [lang, setLang] = useState();

  useEffect(() => {
    fetchAllVacancy(page.limit, page.page).then((data) => {
      vacancy.setVacancy(data.rows);
      page.setTotalCount(data.count);
    });
  }, [vacancy, page.page, page.limit]);

  const editToggle = (id, vacancy) => {
    setEditId(id);
    setEditWageMin(vacancy.wageMin);
    setEditWageMax(vacancy.wageMax);
    setEditRequiredExperienceFrom(vacancy.requiredExperienceFrom);
    setEditRequiredExperienceUpTo(vacancy.requiredExperienceUpTo);
  };

  const editInfoToggle = (id, info) => {
    setEditInfoId(id);
    setEditJobTitle(info.jobTitle);
    setEditRequiredSpecialty(info.requiredSpecialty);
    setEditWorkingConditions(info.workingConditions);
    setLang(info.locale);
  };

  const editVacancy = () => {
    updateVacancy(
      editId,
      editWageMin,
      editWageMax,
      editRequiredExperienceFrom,
      editRequiredExperienceUpTo
    ).then((data) => {
      vacancy.updateVacancy(data.id, data);
      setEditId('');
    });
  };

  const editVacancyInfo = () => {
    updateVacancyInfo(
      editInfoId,
      editJobTitle,
      editRequiredSpecialty,
      editWorkingConditions,
      lang.id
    ).then((data) => {
      vacancy.updateVacancy(data.id, data);
      setEditInfoId('');
    });
  };

  const delVacancy = (id) => {
    deleteVacancy(id);
    vacancy.removeVacancy(id);
  };

  const delVacancyInfo = (id, vacancyId) => {
    deleteVacancyInfo(id, vacancyId).then((data) => {
      vacancy.updateVacancy(data.id, data);
    });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Вакансии</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <PageSize />
        </Col>
        <Col className="d-flex justify-content-end">
          <Pages />
        </Col>
      </Row>

      <Row>
        {vacancy.vacancy.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th className="col-8">Информация</th>
                <th>Требуемый опыт (лет)</th>
                <th>ЗП (руб.)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {vacancy.vacancy.map((vacancy) => (
                <tr key={vacancy.id}>
                  <td>{vacancy.id}</td>
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th className="col-2">Название</th>
                          <th className="col-3">Специальность</th>
                          <th className="col-5">Условия</th>
                          <th className="col-6">Язык</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {vacancy.vacancyInfos.map((info) =>
                          info.id === editInfoId ? (
                            <tr key={info.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editJobTitle}
                                  onChange={(e) =>
                                    setEditJobTitle(e.target.value)
                                  }
                                  placeholder={'Введите название вакансии...'}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editRequiredSpecialty}
                                  onChange={(e) =>
                                    setEditRequiredSpecialty(e.target.value)
                                  }
                                  placeholder={
                                    'Введите требуемую специальность...'
                                  }
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editWorkingConditions}
                                  onChange={(e) =>
                                    setEditWorkingConditions(e.target.value)
                                  }
                                  placeholder={'Введите условия труда...'}
                                  as="textarea"
                                  rows={4}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="me-2 mb-2"
                                  onClick={() => editInfoToggle('', '')}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editVacancyInfo()}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={info.id}>
                              <td>{info.jobTitle}</td>
                              <td>{info.requiredSpecialty}</td>
                              <td>{info.workingConditions}</td>
                              <td>{info.locale.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2 mb-2"
                                  onClick={() => editInfoToggle(info.id, info)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    delVacancyInfo(info.id, vacancy.id)
                                  }
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  {vacancy.id === editId ? (
                    <>
                      <td>
                        <Form.Control
                          className="form-control-sm mb-2"
                          value={editRequiredExperienceFrom}
                          onChange={(e) =>
                            setEditRequiredExperienceFrom(e.target.value)
                          }
                          placeholder={'От'}
                          type="number"
                        />
                        <Form.Control
                          className="form-control-sm"
                          value={editRequiredExperienceUpTo}
                          onChange={(e) =>
                            setEditRequiredExperienceUpTo(e.target.value)
                          }
                          placeholder={'До'}
                          type="number"
                        />
                      </td>
                      <td>
                        <Form.Control
                          className="form-control-sm mb-2"
                          value={editWageMin}
                          onChange={(e) => setEditWageMin(e.target.value)}
                          placeholder={'От'}
                          type="number"
                        />
                        <Form.Control
                          className="form-control-sm"
                          value={editWageMax}
                          onChange={(e) => setEditWageMax(e.target.value)}
                          placeholder={'До'}
                          type="number"
                        />
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          className="me-2"
                          onClick={() => editToggle('', '')}
                        >
                          <i className="bi bi-x-lg"></i>
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => editVacancy()}
                        >
                          <i className="bi bi-check2"></i>
                        </Button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        {vacancy.requiredExperienceFrom
                          ? 'от ' + vacancy.requiredExperienceFrom
                          : ''}
                        {vacancy.requiredExperienceUpTo
                          ? ' до ' + vacancy.requiredExperienceUpTo
                          : ''}
                      </td>
                      <td>
                        {vacancy.wageMin ? 'от ' + vacancy.wageMin : ''}
                        {vacancy.wageMax ? ' до ' + vacancy.wageMax : ''}
                      </td>
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          className="mb-2"
                          onClick={() => editToggle(vacancy.id, vacancy)}
                        >
                          Изменить
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => delVacancy(vacancy.id)}
                        >
                          Удалить
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateVacancy
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Vacancy;
