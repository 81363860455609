import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
  Table,
} from 'react-bootstrap';
import {
  deleteAward,
  deleteAwardInfo,
  fetchAllAward,
  updateAward,
  updateAwardInfo,
} from '../../http/awardApi';
import { Context } from '../../index';
import CreateAward from '../../components/modals/CreateAward';

const Award = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editInfoId, setInfoEditId] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editFile, setEditFile] = useState(null);
  const [lang, setLang] = useState();

  const { award, locale } = useContext(Context);

  useEffect(() => {
    fetchAllAward().then((data) => {
      award.setAward(data.rows);
    });
  }, [award]);

  const selectFile = (e) => {
    setEditFile(e.target.files[0]);
  };

  const editToggle = (id) => {
    setEditId(id);
  };

  const editInfoToggle = (id, info) => {
    setEditDescription(info.description);
    setLang(info.locale);
    setInfoEditId(id);
  };

  const editAward = () => {
    const formData = new FormData();
    formData.append('photo', editFile);
    updateAward(editId, formData).then((data) => {
      award.updateAward(data.id, data);
      setEditId('');
    });
  };

  const editAwardInfo = () => {
    updateAwardInfo(editInfoId, editDescription, lang.id).then((data) => {
      setInfoEditId('');
      award.updateAward(data.id, data);
    });
  };

  const delAward = (id) => {
    deleteAward(id);
    award.removeAward(id);
  };

  const delAwardInfo = (id, awardId) => {
    deleteAwardInfo(id, awardId).then((data) => {
      award.updateAward(data.id, data);
    });
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Награды</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
      </Row>

      <Row>
        {award.award.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th className="col-3">Фото</th>
                <th>Информация</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {award.award?.map((award) => (
                <tr key={award.id}>
                  <td>{award.id}</td>
                  <td>
                    {award.id === editId ? (
                      <Form.Control
                        onChange={selectFile}
                        className="form-control-sm"
                        type="file"
                      />
                    ) : award.photo ? (
                      <div className="photo-wrapper">
                        <Image
                          src={process.env.REACT_APP_API_URL + award.photo}
                          className="photo-mini"
                        />
                      </div>
                    ) : (
                      'нет фото'
                    )}
                  </td>
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th className="col-4">Описание</th>
                          <th className="col-2">Язык</th>
                          <th className="col-2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {award.awardInfos?.map((info) =>
                          info.id === editInfoId ? (
                            <tr key={info.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editDescription}
                                  onChange={(e) =>
                                    setEditDescription(e.target.value)
                                  }
                                  as="textarea"
                                  rows={3}
                                  placeholder={'Введите описание...'}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => editInfoToggle('', '')}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editAwardInfo()}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={info.id}>
                              <td>{info.description}</td>
                              <td>{info.locale?.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => editInfoToggle(info.id, info)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    delAwardInfo(info.id, award.id)
                                  }
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  {award.id === editId ? (
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        className="me-2"
                        onClick={() => editToggle('', '')}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => editAward()}
                      >
                        <i className="bi bi-check2"></i>
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        variant="warning"
                        className="mb-2"
                        size="sm"
                        onClick={() => editToggle(award.id)}
                      >
                        Изменить
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => delAward(award.id)}
                      >
                        Удалить
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateAward
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Award;
