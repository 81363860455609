import { $authHost, $host } from './index';

export const createStore = async (store) => {
  const { data } = await $authHost.post('api/store', store);
  return data;
};

export const fetchAllStore = async (limit = 45, page = 1) => {
  const { data } = await $host.get('api/store', { params: { limit, page } });
  return data;
};

export const fetchAllStoreLang = async (id, limit = 45, page = 1) => {
  const { data } = await $host.get('api/store/locale/' + id, {
    params: { limit, page },
  });
  return data;
};

export const fetchStore = async (id) => {
  const { data } = await $host.get('api/store/' + id);
  return data;
};

export const updateStore = async (id, store) => {
  const { data } = await $authHost.put('api/store/' + id, store);
  return data;
};

export const deleteStore = async (id) => {
  const { data } = await $authHost.delete('api/store/' + id);
  return data;
};

export const createStoreInfo = async (name, address, localeId, storeId) => {
  const { data } = await $authHost.post('api/store/info', {
    name,
    address,
    localeId,
    storeId,
  });
  return data;
};

export const updateStoreInfo = async (id, name, address, localeId) => {
  const { data } = await $authHost.put('api/store/info/' + id, {
    name,
    address,
    localeId,
  });
  return data;
};

export const deleteStoreInfo = async (id, storeId) => {
  const { data } = await $authHost.delete('api/store/info/' + id, {
    data: { storeId },
  });
  return data;
};
