import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import { fetchAllFile } from '../../http/filesApi';
import { Container, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';

const PriceVI = observer(() => {
  const { t } = useTranslation();
  document.title = t('price.pageTitle');
  const { files, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAllFile()
      .then((data) => {
        files.setFile(data);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="text-center py-3">{t('price.title')}</h1>
      <p>{t('price.text')}</p>
      {files.files.map((file) => (
        <li key={file.id} className="file-list-li">
          <a
            className="link__VI"
            href={process.env.REACT_APP_API_URL + file.file}
            download={file.name}
          >
            {file.name}
          </a>
        </li>
      ))}
    </div>
  );
});

export default PriceVI;
