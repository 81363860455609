import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Pagination } from 'react-bootstrap';
import { Context } from '../index';

const Pages = observer(() => {
  const { page } = useContext(Context);
  const pageCount = Math.ceil(page.totalCount / page.limit);
  const pages = [];
  const visible = page.totalCount > page.limit;
  useEffect(() => {
    page.setPage(1);
  }, []);

  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }

  return (
    <Pagination className="mb-0 z-0">
      {visible ? (
        pages.map((pageItem) => (
          <Pagination.Item
            className="btn__page"
            key={pageItem}
            active={page.page === pageItem}
            onClick={() => page.setPage(pageItem)}
          >
            {pageItem}
          </Pagination.Item>
        ))
      ) : (
        <></>
      )}
    </Pagination>
  );
});

export default Pages;
