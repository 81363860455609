import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { createFile } from '../../http/filesApi';
import { Context } from '../../index';

const AddFile = ({ show, onHide }) => {
  const { files } = useContext(Context);

  const [file, setFile] = useState(null);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const addNewsFile = () => {
    const formData = new FormData();
    formData.append('file', file);
    createFile(formData).then((data) => {
      onHide();
      files.addFile(data);
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить файл к новости
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            onChange={selectFile}
            className="mt-3 mb-3"
            type="file"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button variant="outline-success" onClick={addNewsFile}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFile;
