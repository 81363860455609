import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import Pages from '../../components/VI/Pages';
import { fetchAllProductLang } from '../../http/productApi';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { PRODUCTS_ROUTE } from '../../utils/consts';
import ProductCardVI from '../../components/VI/ProductCardVI';
import CategoryVI from '../../components/VI/CategoryVI';

const ProductVI = observer(() => {
  const { product, page, locale, category, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
    page.setLimit(9);
    fetchAllProductLang(
      locale.selectLang,
      page.limit,
      page.page,
      category.selectCategory
    )
      .then((data) => {
        product.setProduct(data.rows);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
  }, [
    product,
    page.limit,
    page.page,
    locale.selectLang,
    category.selectCategory,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  const { t } = useTranslation();
  document.title = t('product.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <div className="p-3">
        <Row>
          <Col className="d-flex justify-content-start mb-3" xs={12} md={10}>
            <CategoryVI />
          </Col>
          <Col className="d-flex justify-content-end mb-3" xs={12} md={2}>
            <Pages />
          </Col>
        </Row>
        <Row>
          {product.product.length === 0 ? (
            <div className="m-5 p-5 d-flex justify-content-center">
              <h1>not found</h1>
            </div>
          ) : (
            ''
          )}
          {product.product?.map((product) =>
            product.productInfos?.map((productInfo) => (
              <Col key={productInfo.id} xs={12} sm={6} md={4} lg={4}>
                <ProductCardVI
                  id={product.id}
                  img={process.env.REACT_APP_API_URL + product.photo}
                  name={productInfo.name}
                  shortDescription={productInfo.shortDescription}
                  link={PRODUCTS_ROUTE + '/' + product.id}
                />
              </Col>
            ))
          )}
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-start mb-3" xs={12} md={10}>
            <CategoryVI />
          </Col>
          <Col className="d-flex justify-content-end mb-3" xs={12} md={2}>
            <Pages />
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default ProductVI;
