import React from 'react';
// import './imgBlock.css';

const ImgBlock = ({ img, heading, text, size }) => {
  return (
    <div
      className={'bg bg-' + size}
      style={{ backgroundImage: 'url(' + img + ')' }}
    >
      <div className="container text-center">
        <h2 className="heading__img-block">{heading}</h2>
        <p className={'sub-heading img-text'}>{text}</p>
      </div>
    </div>
  );
};

export default ImgBlock;
