import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AwardCardVI from '../../components/VI/AwardCardVI';
import { fetchAllAwardLang } from '../../http/awardApi';
import { Context } from '../../index';
import '../../utils/i18next';

const AwardsVI = observer(() => {
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  document.title = t('awards.pageTitle');
  window.scrollTo(0, 0);
  const awards = t('awards.text', { returnObjects: true });

  const { award, locale, vi } = useContext(Context);

  useEffect(() => {
    fetchAllAwardLang(locale.selectLang)
      .then((data) => {
        award.setAward(data);
      })
      .then(() => {
        setLoading(false);
      });
  }, [
    award,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );

  return (
    <div
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="text-center pt-3 pb-3">{t('awards.title')}</h1>
      <Row className="d-flex align-items-stretch">
        {award.award?.map((award) =>
          award.awardInfos.map((info) => (
            <Col sm={6} md={4} key={award.id}>
              <AwardCardVI text={info.description} img={award.photo} />
            </Col>
          ))
        )}
      </Row>
    </div>
  );
});

export default AwardsVI;
