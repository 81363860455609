import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { fetchAllCategoryLang } from '../http/categoryApi';
import { Context } from '../index';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const Category = observer(() => {
  const { category, locale, page } = useContext(Context);

  const { t } = useTranslation();

  useEffect(() => {
    fetchAllCategoryLang(locale.selectLang).then((data) => {
      category.setCategory(data);
    });
  }, [locale.selectLang]);

  const categoryHandler = (id) => {
    category.setSelectCategory(id);
    page.setPage(1);
  };

  return (
    <div>
      <Button
        className={
          'btn__card btn__card_outline me-1 mb-1' +
          (!category.selectCategory ? ' btn__card_active' : '')
        }
        onClick={() => categoryHandler(null)}
      >
        {t('product.all')}
      </Button>
      {category.category.map((cat) => (
        <Button
          key={cat.categoryId}
          className={
            'btn__card btn__card_outline me-1 mb-1' +
            (category.selectCategory === cat.categoryId ? ' btn__card_active' : '')
          }
          onClick={() => categoryHandler(cat.categoryId)}
        >
          {cat.name}
        </Button>
      ))}
    </div>
  );
});

export default Category;
