import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';

const Pages = observer(() => {
  const { page } = useContext(Context);
  const pageCount = Math.ceil(page.totalCount / page.limit);
  const pages = [];
  const visible = page.totalCount > page.limit;

  useEffect(() => {
    page.setPage(1);
  }, []);

  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }

  return (
    <>
      {visible ? (
        <>
          <p className="me-3">Страница: </p>
          {pages.map((pageItem) => (
            <div
              key={pageItem}
              className={
                (page.page === pageItem ? 'link__VI_activ' : 'link__VI') +
                ' me-3'
              }
              disable={(page.page === pageItem).toString()}
              onClick={() => page.setPage(pageItem)}
            >
              {pageItem}
            </div>
          ))}
        </>
      ) : (
        ''
      )}
    </>
  );
});

export default Pages;
