import { makeAutoObservable } from 'mobx';

export default class NewsStore {
  constructor() {
    this._news = [];
    this._newsInfo = [];
    makeAutoObservable(this);
  }

  setNews(news) {
    this._news = news;
  }

  setNewsInfo(newsInfo) {
    this._newsInfo = newsInfo;
  }

  addNews(news) {
    this._news.push(news);
  }

  updateNews(id, data) {
    this._news = this._news.map((news) =>
      news.id === id ? { ...data } : { ...news }
    );
  }

  removeNews(id) {
    this._news = this._news.filter((news) => news.id !== id);
  }

  get news() {
    return this._news;
  }

  get newsInfo() {
    return this._newsInfo;
  }
}
