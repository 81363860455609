import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import PersoneCard from '../components/PersoneCard';
import { fetchAllTradeUnionLang } from '../http/tradeUnionApi';
import { Context } from '../index';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Helmet } from 'react-helmet';

const Structure = observer(() => {
  const { tradeUnion, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAllTradeUnionLang(locale.selectLang)
      .then((data) => {
        tradeUnion.setTradeUnion(data);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [tradeUnion, locale.selectLang]);
  const { t } = useTranslation();
  document.title = t('tradeUnion.structurePageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('tradeUnion.structurePageTitle')}
          meta={[
            {
              name: 'description',
              content: t('tradeUnion.structurePageDescription'),
            },
          ]}
        />
      </Container>
    );
  return (
    <div className="container mt-5 d-flex flex-column min-vh-100">
       <Helmet
        title={t('tradeUnion.structurePageTitle')}
        meta={[
          {
            name: 'description',
            content: t('tradeUnion.structurePageDescription'),
          },
        ]}
      />
      <h1 className="text-center mb-3">{t('tradeUnion.structure')}</h1>
      <Row>
        {tradeUnion.tradeUnion?.map((tradeUnion) =>
          tradeUnion.tradeUnionInfos?.map((info) => (
            <Col key={info.id} xs={12} sm={6} md={4}>
              <PersoneCard
                type={'tradeUnion'}
                phone={tradeUnion.phone}
                mail={tradeUnion.mail}
                img={process.env.REACT_APP_API_URL + tradeUnion.photo}
                fullName={info.fullName}
                position={info.position}
                about={info.about}
              />
            </Col>
          ))
        )}
        <Col></Col>
      </Row>
    </div>
  );
});

export default Structure;
