import { $authHost, $host } from './index';

export const fetchAllCategory = async () => {
  const { data } = await $host.get('api/newsCategory');
  return data;
};

export const updateNewsCategory = async (id, name) => {
  const { data } = await $authHost.put('api/newsCategory/' + id, {name});
  return data;
};
