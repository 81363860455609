import React from 'react';
import { Carousel, Col, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

const History = () => {
  const { t } = useTranslation();
  document.title = t('history.pageTitle');
  window.scrollTo(0, 0);
  return (
    <div className="container mt-5 d-flex flex-column min-vh-100">
      <Helmet
        title={t('history.pageTitle')}
        meta={[{ name: 'description', content: t('history.pageDescription') }]}
      />
      <div className="item text p-3">
        <h1 className="text-center"> {t('history.title')}</h1>
        <Row className="mt-3">
          <Col sm={12} md={6}>
            <img
              className="history_photo"
              src={require('../static/history4.jpg')}
            />
          </Col>
          <Col sm={12} md={6}>
            <p>{t('history.p1')}</p>
            <p>{t('history.p2')}</p>
            <p>{t('history.p3')}</p>
            <p>{t('history.p4')}</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <p>{t('history.p5')}</p>
          </Col>
        </Row>
        {/* <Row className="my-3">
          <Col sm={12}>
            <img
              className="history_photo"
              src={require('../static/history2.jpg')}
            />
          </Col>
        </Row> */}
        {/* <Row>
          <Col md={12}>
            <p>{t('history.p7')}</p>
            <p>{t('history.p8')}</p>
            <p>{t('history.p9')}</p>
            <p>{t('history.p10')}</p>
          </Col>
        </Row> */}
        {/* <Carousel interval={null} variant="dark">
          <Carousel.Item>
            <img
              className="img img-news__big"
              src={require('../static/history2.jpg')}
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="img img-news__big"
              src={require('../static/history3.jpg')}
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="img img-news__big"
              src={require('../static/history1.png')}
            />
          </Carousel.Item>
        </Carousel> */}
        <Row>
          <Col sm={12} md={6}>
            <p>{t('history.p6')}</p>
            <p className="mb-5">{t('history.p7')}</p>
          </Col>
          <Col sm={12} md={6}>
            <img
              className="history_photo"
              src={require('../static/history3.jpg')}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default History;
