import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';

const ProductCardVI = ({ img, name, shortDescription, link }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="pt-3">
        <div className="img-pruduct_wrap">
          <Image src={img} className="img" />
        </div>
        <div className="product_text">
          <h2>{name}</h2>
          <p className="description">{shortDescription}</p>
          <Link to={link} className="link__VI">
            {t('product.more')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCardVI;
