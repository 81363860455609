import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Image, Spinner, Carousel } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Context } from '../index';
import { fetchNewsLang } from '../http/newsApi';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { NEWS_ROUTE } from '../utils/consts';
import { Helmet } from 'react-helmet';

const NewsFull = observer(() => {
  const { news, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    fetchNewsLang(params.id, locale.selectLang)
      .then((data) => {
        news.setNews(data);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [locale.selectLang]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('news.pageTitle')}
          meta={[{ name: 'description', content: t('news.pageDescription') }]}
        />
      </Container>
    );
  return (
    <Container className="d-flex flex-column min-vh-100">
      <Helmet
        title={t('news.pageTitle')}
        meta={[{ name: 'description', content: t('news.pageDescription') }]}
      />
      <div className="item mt-3 p-3">
        {news.news ? (
          <Row>
            <Helmet
              meta={[
                {
                  name: 'description',
                  content: news.news?.newsInfos[0]?.newsText,
                },
              ]}
            />
            <h2>{news.news?.newsInfos[0]?.title}</h2>
            <Image
              src={process.env.REACT_APP_API_URL + news.news?.photo}
              className="img img-news__big"
            />
            <p className="news-date mt-3">
              {new Date(news.news?.createdAt).toLocaleDateString()}
            </p>
            <p className="news-text">{news.news?.newsInfos[0]?.newsText}</p>

            {news.news.newsPhotos.length > 0 ? (
              <Carousel interval={null} variant="dark">
                {news.news?.newsPhotos.map((photo) => (
                  <Carousel.Item key={photo.id}>
                    <img
                      className="img img-news__big"
                      src={process.env.REACT_APP_API_URL + photo.photo}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              ''
            )}
            {news.news.newsFiles.length > 0 ? (
              <div className="m-3">
                <p>Прикреплённые файлы:</p>
                <ul>
                  {news.news.newsFiles.map((file) => (
                    <li key={file.id} className="file-list-li">
                      <a
                        href={process.env.REACT_APP_API_URL + file.file}
                        download={file.name}
                      >
                        <i className="fa-solid fa-download"></i> {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              ''
            )}
            <Row className="mt-3">
              <Col>
                <Link to={NEWS_ROUTE} href={NEWS_ROUTE} className="btn__card">
                  {t('news.back')}
                </Link>
              </Col>
            </Row>
          </Row>
        ) : (
          <Row>
            <p className="my-5 d-flex justify-content-center">not found</p>
            <Col className="my-5">
              <Link to={NEWS_ROUTE} href={NEWS_ROUTE} className="btn__card">
                {t('news.back')}
              </Link>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
});

export default NewsFull;
