import { $authHost, $host } from './index';

export const createNews = async (news) => {
  const { data } = await $authHost.post('api/news', news);
  return data;
};

export const fetchAllNews = async (
  categoryId = 1,
  localeId = 1,
  limit = 5,
  page = 1
) => {
  const { data } = await $host.get('api/news', {
    params: { categoryId, localeId, limit, page },
  });
  return data;
};

export const fetchAllNewsByCategory = async (
  categoryId,
  limit = 5,
  page = 1
) => {
  const { data } = await $host.get('api/news?categoryId=' + categoryId, {
    params: { limit, page },
  });
  return data;
};

export const fetchAllNewsLang = async (id) => {
  const { data } = await $host.get('api/news/locale/' + id);
  return data;
};

export const fetchNews = async (id) => {
  const { data } = await $host.get('api/news/' + id);
  return data;
};

export const fetchNewsLang = async (id, localeId) => {
  const { data } = await $host.get('api/news/' + id + '/' + localeId);
  return data;
};

export const updateNews = async (id, news) => {
  const { data } = await $authHost.put('api/news/' + id, news);
  return data;
};

export const deleteNews = async (id) => {
  const { data } = await $authHost.delete('api/news/' + id);
  return data;
};

export const createNewsInfo = async (title, newsText, localeId, newsId) => {
  const { data } = await $authHost.post('api/news/info', {
    title,
    newsText,
    localeId,
    newsId,
  });
  return data;
};

export const updateNewsInfo = async (id, title, newsText, localeId, newsId) => {
  const { data } = await $authHost.put('api/news/info/' + id, {
    title,
    newsText,
    localeId,
    newsId,
  });
  return data;
};

export const deleteNewsInfo = async (id, newsId) => {
  const { data } = await $authHost.delete('api/news/info/' + id, {
    data: { newsId },
  });
  return data;
};

export const fetchAllPhoto = async (newsId) => {
  const { data } = await $host.get('api/newsPhoto/' + newsId);
  return data;
};

export const createPhoto = async (photo) => {
  const { data } = await $authHost.post('api/newsPhoto', photo);
  return data;
};

export const updatePhoto = async (id, photo) => {
  const { data } = await $authHost.put('api/newsPhoto/' + id, photo);
  return data;
};

export const deletePhoto = async (id) => {
  const { data } = await $authHost.delete('api/newsPhoto/' + id);
  return data;
};


export const fetchAllFile = async (newsId) => {
  const { data } = await $host.get('api/newsFile/' + newsId);
  return data;
};

export const createFile = async (file) => {
  const { data } = await $authHost.post('api/newsFile', file);
  return data;
};

export const updateFile = async (id, file) => {
  const { data } = await $authHost.put('api/newsFile/' + id, file);
  return data;
};

export const deleteFile = async (id) => {
  const { data } = await $authHost.delete('api/newsFile/' + id);
  return data;
};
