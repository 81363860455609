import { $authHost, $host } from './index';

export const createProductAvailability = async (
  weight,
  price,
  available,
  productId
) => {
  const { data } = await $authHost.post('api/productAvailability', {
    weight,
    price,
    available,
    productId,
  });
  return data;
};

export const fetchAllProductAvailability = async (productId) => {
  const { data } = await $host.get(
    'api/productAvailability?productId=' + productId
  );
  return data;
};

export const fetchProductAvailability = async (id) => {
  const { data } = await $host.get('api/productAvailability/' + id);
  return data;
};

export const updateProductAvailability = async (
  id,
  weight,
  price,
  available
) => {
  const { data } = await $authHost.put('api/productAvailability/' + id, {
    weight,
    price,
    available,
  });
  return data;
};

export const deleteProductAvailability = async (id) => {
  const { data } = await $authHost.delete('api/productAvailability/' + id);
  return data;
};
