import React, { useContext, useEffect } from 'react';
import { Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {
  AWARDS_ROUTE,
  CONTACT_ROUTE,
  ENTERPRISE_TODAY_ROUTE,
  HISTORY_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  PRODUCTS_ROUTE,
  QUALITY_ROUTE,
  RECIPE_ROUTE,
  SOCIAL_ACTIVITY_ROUTE,
  STORE_ROUTE,
  STRUCTURE_ROUTE,
  TRADE_UNION_ROUTE,
  VACANCY_ROUTE,
  PRICE_ROUTE,
  FEEDBACK_ROUTE,
  RECEPTOION_SCHEDULE,
} from '../../utils/consts';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { Link, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const HeaderVI = observer(() => {
  const { locale, vi } = useContext(Context);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    locale.setSelectLang(lang);
  };

  let lang = localStorage.getItem('i18nextLng');
  locale.setSelectLang(lang);

  useEffect(() => {
    let lang = localStorage.getItem('i18nextLng');
    locale.setSelectLang(lang);
  }, [locale.selectLang]);

  return (
    <Navbar
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
      expand="xl"
      role="navigation"
    >
      <div className="flex-xl-column header_container__VI">
        <Navbar.Brand href={MAIN_ROUTE} className="link__VI logo">
          {t('header.brand')}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar" className="burger" />
        <Navbar.Collapse id="navbar" className="pt-3">
          <Nav className="d-flex justify-content-between" navbarScroll>
            <NavDropdown
              title={t('header.about')}
              id="basic-nav-dropdown"
              bsPrefix="nav-link__VI link__VI"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item href={HISTORY_ROUTE}>
                {t('header.aboutCompany')}
              </NavDropdown.Item>
              <NavDropdown.Item href={ENTERPRISE_TODAY_ROUTE}>
                {t('header.today')}
              </NavDropdown.Item>
              <NavDropdown.Item href={STRUCTURE_ROUTE}>
                {t('header.structure')}
              </NavDropdown.Item>
              <NavDropdown.Item href={QUALITY_ROUTE}>
                {t('header.quality')}
              </NavDropdown.Item>
              <NavDropdown.Item href={AWARDS_ROUTE}>
                {t('header.awards')}
              </NavDropdown.Item>
              <NavDropdown.Item href={TRADE_UNION_ROUTE}>
                {t('header.tradeUnion')}
              </NavDropdown.Item>
              <NavDropdown.Item href={SOCIAL_ACTIVITY_ROUTE}>
                {t('header.socialActivity')}
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink className="header-link link__VI" to={PRODUCTS_ROUTE}>
              {t('header.products')}
            </NavLink>
            <NavLink className="header-link link__VI" to={RECIPE_ROUTE}>
              {t('header.recipes')}
            </NavLink>
            <NavLink className="header-link link__VI" to={STORE_ROUTE}>
              {t('header.store')}
            </NavLink>
            <NavLink className="header-link link__VI" to={VACANCY_ROUTE}>
              {t('header.vacancy')}
            </NavLink>
            <NavLink className="header-link link__VI" to={NEWS_ROUTE}>
              {t('header.news')}
            </NavLink>
            <NavDropdown
              title={t('header.contacts')}
              id="basic-nav-dropdown"
              bsPrefix="nav-link__VI link__VI"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item href={CONTACT_ROUTE}>
                {t('header.contacts')}
              </NavDropdown.Item>
              <NavDropdown.Item href={RECEPTOION_SCHEDULE}>
                {t('header.receptionSchedule')}
              </NavDropdown.Item>
              <NavDropdown.Item href={FEEDBACK_ROUTE}>
                {t('header.feedback')}
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink className="header-link link__VI" to={PRICE_ROUTE}>
              {t('header.price')}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
});

export default HeaderVI;
