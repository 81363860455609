import { $authHost, $host } from './index';

export const createProduct = async (product) => {
  const { data } = await $authHost.post('api/product', product);
  return data;
};

export const fetchAllProduct = async (limit = 5, page = 1) => {
  const { data } = await $host.get('api/product', { params: { limit, page } });
  return data;
};

export const fetchAllProductLang = async (id, limit = 5, page = 1, categoryId) => {
  const { data } = await $host.get('api/product/locale/' + id, {
    params: { limit, page, categoryId },
  });
  return data;
};

export const fetchProductLang = async (id, localeId) => {
  const { data } = await $host.get('api/product/' + id + '/' + localeId);
  return data;
};

export const fetchProduct = async (id) => {
  const { data } = await $host.get('api/product/' + id);
  return data;
};

export const updateProduct = async (id, product) => {
  const { data } = await $authHost.put('api/product/' + id, product);
  return data;
};

export const deleteProduct = async (id) => {
  const { data } = await $authHost.delete('api/product/' + id);
  return data;
};

export const createProductInfo = async (
  name,
  shortDescription,
  description,
  localeId,
  productId
) => {
  const { data } = await $authHost.post('api/product/info', {
    name,
    shortDescription,
    description,
    localeId,
    productId,
  });
  return data;
};

export const updateProductInfo = async (
  id,
  name,
  shortDescription,
  description,
  localeId
) => {
  const { data } = await $authHost.put('api/product/info/' + id, {
    name,
    shortDescription,
    description,
    localeId,
  });
  return data;
};

export const deleteProductInfo = async (id, productId) => {
  const { data } = await $authHost.delete('api/product/info/' + id, {
    data: { productId },
  });
  return data;
};
