import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import { Context } from '../../index';
import { deleteFile, fetchAllFile, updateFile } from '../../http/filesApi';
import { useParams } from 'react-router-dom';
import AddFile from '../../components/modals/AddFile';

const Files = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [editFileId, setFileEditId] = useState('');
  const params = useParams();

  const { files } = useContext(Context);

  useEffect(() => {
    fetchAllFile(params.id).then((data) => {
      files.setFile(data);
    });
  }, [files]);

  const selectFile = (e) => {
    setEditFile(e.target.files[0]);
  };

  const editFileToggle = (file) => {
    setFileEditId(file.id);
  };

  const editNewsFile = () => {
    const formData = new FormData();
    formData.append('file', editFile);
    updateFile(editFileId, formData).then((data) => {
      files.updateFile(data.id, data);
      setFileEditId('');
    });
  };

  const delNewsFile = (id) => {
    deleteFile(id);
    files.removeFile(id);
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Прайс-лист</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
      </Row>
      <Row>
        {files.files.length !== 0 ? (
          <Table>
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th>Файл</th>
                {/* <th>Показывать</th> */}
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {files.files?.map((file) => (
                <tr key={file.id}>
                  <td>{file.id}</td>
                  {file.id === editFileId ? (
                    <>
                      <td>
                        <Form.Control
                          onChange={selectFile}
                          className="form-control-sm"
                          type="file"
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <a
                          href={process.env.REACT_APP_API_URL + file.file}
                          download={file.name}
                        >
                          {file.name}
                        </a>
                      </td>
                    </>
                  )}
                  {file.id === editFileId ? (
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        className="me-2"
                        onClick={() => editFileToggle('')}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => editNewsFile()}
                      >
                        <i className="bi bi-check2"></i>
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-2 mb-2"
                        onClick={() => editFileToggle(file, file.show)}
                      >
                        Изменить
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => delNewsFile(file.id)}
                      >
                        Удалить
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет файлов</h3>
        )}
      </Row>

      <AddFile
        newsId={params.id}
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Files;
