import React, { useContext, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import {
  createTradeUnion,
  createTradeUnionInfo,
} from '../../http/tradeUnionApi';
import { Context } from '../../index';

const CreateTradeUnion = ({ show, onHide }) => {
  const [toggle, setToggle] = useState(false);
  const [fullName, setFullName] = useState('');
  const [position, setPosition] = useState('');
  const [about, setAbout] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [
    selectedTradeUnion,
    setSelectedTradeUnion,
  ] = useState(null);
  const [lang, setLang] = useState();
  const [error, setError] = useState();

  const { tradeUnion, locale } = useContext(Context);

  const submitHandler = (type) => {
    const formData = new FormData();
    if (type === 'new') {
      formData.append('fullName', fullName);
      formData.append('position', position);
      formData.append('about', about);
      formData.append('phone', phone);
      formData.append('mail', mail);
      formData.append('localeId', lang.id);
    }
    type === 'info'
      ? createTradeUnionInfo(
          selectedTradeUnion.id,
          fullName,
          position,
          about,
          lang.id
        ).then((data) => {
          onHide();
          tradeUnion.updateTradeUnion(
            data.id,
            data
          );
        })
      : createTradeUnion(formData).then((data) => {
          tradeUnion.addTradeUnion(data);
        });
    setSelectedTradeUnion(null);
    setFullName('');
    setPosition('');
    setAbout('');
    setLang(null);
    setPhone('');
    setMail('');
    onHide();
  };

  const addTradeUnion = () => {
    fullName
      ? position
        ? about
          ? lang
            ? submitHandler('new')
            : setError('lang')
          : setError('about')
        : setError('position')
      : setError('fullName');
  };

  const addTradeUnionInfo = () => {
    selectedTradeUnion
      ? fullName
        ? position
          ? about
            ? lang
              ? submitHandler('info')
              : setError('lang')
            : setError('about')
          : setError('position')
        : setError('fullName')
      : setError('selected');
  };
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить члена администрации
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
        </Form>
        {toggle ? (
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'selected' ? 'error' : ''}>
              {selectedTradeUnion
                ? selectedTradeUnion
                    .tradeUnionInfos[0].fullName
                : 'Выберите персону'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {tradeUnion.tradeUnion.map(
                (tradeUnion) =>
                  tradeUnion.tradeUnionInfos.map(
                    (tradeUnionInfo) =>
                      tradeUnionInfo.locale.name === 'ru' ? (
                        <Dropdown.Item
                          key={tradeUnionInfo.id}
                          onClick={() => {
                            setSelectedTradeUnion(
                              tradeUnion
                            );
                            setError('');
                          }}
                        >
                          {tradeUnionInfo.fullName}
                        </Dropdown.Item>
                      ) : (
                        ''
                      )
                  )
              )}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          ''
        )}
        <Form.Control
          placeholder={'Введите ФИО'}
          className={error === 'fullName' ? 'error mt-3 mb-3' : 'mt-3 mb-3'}
          value={fullName}
          onChange={(e) => {
            setFullName(e.target.value);
            setError('');
          }}
        />
        <Form.Control
          placeholder={'Введите должность'}
          value={position}
          className={error === 'position' ? 'error mt-3 mb-3' : 'mt-3 mb-3'}
          onChange={(e) => {
            setPosition(e.target.value);
            setError('');
          }}
        />
        <Form.Control
          placeholder={'Введите информацию'}
          value={about}
          className={error === 'about' ? 'error mt-3 mb-3' : 'mt-3 mb-3'}
          onChange={(e) => {
            setAbout(e.target.value);
            setError('');
          }}
        />
        {toggle ? (
          ''
        ) : (
          <>
            <Form.Control
              className="mb-3"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={'Введите телефон'}
            />
            <Form.Control
              className="mb-3"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              placeholder={'Введите почту'}
            />
          </>
        )}
        <Dropdown className="mt-3 mb-3">
          <Dropdown.Toggle
            className={error === 'lang' ? 'error mt-3 mb-3' : 'mt-3 mb-3'}
          >
            {lang ? lang.name : 'Выберите язык'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {locale.locale.map((locale) => (
              <Dropdown.Item
                key={locale.id}
                onClick={() => {
                  setLang(locale);
                  setError('');
                }}
              >
                {locale.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          onClick={
            toggle
              ? addTradeUnionInfo
              : addTradeUnion
          }
        >
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTradeUnion;
