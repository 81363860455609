import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../index';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {
  ADMIN_ADMINREQUESTS_ROUTE,
  ADMIN_BRAND_ROUTE,
  ADMIN_CATEGORY_ROUTE,
  ADMIN_PRODUCT_ROUTE,
  ADMIN_STORE_ROUTE,
  ADMIN_VACANCY_ROUTE,
  ADMIN_ENTERPRISE_ADMINISTRATION_ROUTE,
  // ADMIN_LOCALE_ROUTE,
  ADMIN_NEWS_ROUTE,
  ADMIN_NEWS_CATEGORY_ROUTE,
  ADMIN_CURRENCY_ROUTE,
  ADMIN_TRADE_UNION_ROUTE,
  ADMIN_AWARD_ROUTE,
  ADMIN_RECIPE_ROUTE,
  ADMIN_FILES_ROUTE,
} from '../utils/consts';
import { observer } from 'mobx-react-lite';
import { NavDropdown, Spinner } from 'react-bootstrap';
import { check } from '../http/userApi';

const NavBar = observer(() => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    check()
      .then((data) => {
        user.setUser(data);
        user.setIsAuth(true);
      })
      .catch(() => user.setIsAuth(false))
      .then(() => setLoading(false));
  }, [user]);

  const logOut = () => {
    user.setUser({});
    user.setIsAuth(false);
    localStorage.removeItem('token');
  };

  if (loading) return <Spinner animation={'grow'} />;
  return (
    <Navbar bg="light" expand="xl">
      <Container>
        {/* <Navbar.Brand href={ADMIN_ROUTE}>Гомельхлебопродукт</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="admin-navbar" />
        <Navbar.Collapse id="admin-navbar">
          {user.user?.role === 'admin' ? (
            <Nav className="ms-auto">
              <Nav.Link href={ADMIN_VACANCY_ROUTE}>Вакансии</Nav.Link>
              <Nav.Link href={ADMIN_PRODUCT_ROUTE}>Продукты</Nav.Link>
              <Nav.Link href={ADMIN_STORE_ROUTE}>Магазины</Nav.Link>
              <Nav.Link href={ADMIN_BRAND_ROUTE}>Производители</Nav.Link>
              <Nav.Link href={ADMIN_CATEGORY_ROUTE}>Типы</Nav.Link>
              <Nav.Link href={ADMIN_ADMINREQUESTS_ROUTE}>Заявки</Nav.Link>
              <Nav.Link href={ADMIN_ENTERPRISE_ADMINISTRATION_ROUTE}>
                Администрация
              </Nav.Link>
              <Nav.Link href={ADMIN_TRADE_UNION_ROUTE}>Профсоюз</Nav.Link>
              <NavDropdown title="Новости">
                <NavDropdown.Item href={ADMIN_NEWS_ROUTE}>
                  Новости
                </NavDropdown.Item>
                <NavDropdown.Item href={ADMIN_NEWS_CATEGORY_ROUTE}>
                  Категории
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link href={ADMIN_LOCALE_ROUTE}>Языки</Nav.Link> */}
              <Nav.Link href={ADMIN_CURRENCY_ROUTE}>Валюта</Nav.Link>
              <Nav.Link href={ADMIN_AWARD_ROUTE}>Награды</Nav.Link>
              <Nav.Link href={ADMIN_RECIPE_ROUTE}>Рецепты</Nav.Link>
              <Nav.Link href={ADMIN_FILES_ROUTE}>Прайс</Nav.Link>
              <Nav.Link onClick={() => logOut()}>Выйти</Nav.Link>
            </Nav>
          ) : (
            <Nav className="ms-auto">
              <Nav.Link href={ADMIN_ADMINREQUESTS_ROUTE}>Заявки</Nav.Link>
              <Nav.Link href={ADMIN_PRODUCT_ROUTE}>Продукты</Nav.Link>
              <Nav.Link href={ADMIN_BRAND_ROUTE}>Производители</Nav.Link>
              <Nav.Link href={ADMIN_CATEGORY_ROUTE}>Типы продуктов</Nav.Link>
              <Nav.Link onClick={() => logOut()}>Выйти</Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
});

export default NavBar;
