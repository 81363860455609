import { makeAutoObservable } from 'mobx';

export default class LocaleStore {
  constructor() {
    this._locale = [
      { id: 1, name: 'ru' },
      { id: 2, name: 'en' },
      { id: 3, name: 'be' },
      { id: 4, name: 'zh' },
    ];
    this._selectLang = 1;
    this._currency = [];
    makeAutoObservable(this);
  }

  setLocale(locale) {
    this._locale = locale;
  }

  setSelectLang(lang) {
    switch (lang) {
      case 'ru':
        this._selectLang = 1;
        break;
      case 'en':
        this._selectLang = 2;
        break;
      case 'be':
        this._selectLang = 3;
        break;
      case 'zh':
        this._selectLang = 4;
        break;
      default:
        this._selectLang = 1;
    }
  }

  updateLocale(id, data) {
    this._locale = this._locale.map((locale) =>
      locale.id === id ? { ...data } : { ...locale }
    );
  }

  setCurrency(name, value) {
    this._currency = { ...this._currency, [name]: value };
  }

  setCurrencies(currency) {
    this._currency = currency;
  }

  updateCurrency(id, data) {
    this._currency = this._currency.map((currency) =>
      currency.id === id ? { ...data } : { ...currency }
    );
  }

  get locale() {
    return this._locale;
  }

  get currency() {
    return this._currency;
  }

  get selectLang() {
    return this._selectLang;
  }
}
