import React, { useState } from 'react';
import useValidation from './useValidation';

const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setDirty] = useState(false);
  const valid = useValidation(value, validations);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = (e) => {
    setDirty(true);
  };

  const clear = () => {
    setValue('');
    setDirty(false);
  };

  return {
    value,
    onChange,
    onBlur,
    isDirty,
    clear,
    ...valid,
  };
};

export default useInput;
