import { $authHost, $host } from './index';

export const fetchAllFile = async () => {
  const { data } = await $host.get('api/files/');
  return data;
};

export const createFile = async (file) => {
  const { data } = await $authHost.post('api/files', file);
  return data;
};

export const updateFile = async (id, file) => {
  const { data } = await $authHost.put('api/files/' + id, file);
  return data;
};

export const deleteFile = async (id) => {
  const { data } = await $authHost.delete('api/files/' + id);
  return data;
};
