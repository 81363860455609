import { makeAutoObservable } from 'mobx';

export default class FileStore {
  constructor() {
    this._files = [];
    makeAutoObservable(this);
  }

  setFile(file) {
    this._files = file;
  }

  addFile(file) {
    this._files.push(file);
  }

  updateFile(id, data) {
    this._files = this._files.map((file) =>
      file.id === id ? { ...data } : { ...file }
    );
  }

  removeFile(id) {
    this._files = this._files.filter((file) => file.id !== id);
  }

  get files() {
    return this._files;
  }
}
