import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from '../..';
import '../../utils/i18next';

const HistoryVI = observer(() => {
  const { vi } = useContext(Context);
  useEffect(() => {}, [
    ,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  document.title = t('history.pageTitle');
  window.scrollTo(0, 0);
  return (
    <div
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <div className="text p-3">
        <h1 className="text-center"> {t('history.title')}</h1>
        <p>{t('history.p1')}</p>
        <p>{t('history.p2')}</p>
        <p>{t('history.p3')}</p>
        <p>{t('history.p4')}</p>
        <p>{t('history.p5')}</p>
        <p>{t('history.p6')}</p>
        <p className="mb-5">{t('history.p7')}</p>
      </div>
    </div>
  );
});

export default HistoryVI;
