import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../utils/i18next';
import { useTranslation } from 'react-i18next';

const NewsCardVI = ({ img, title, newsText, link, id, date }) => {
  const { t } = useTranslation();
  return (
    <div id={id} className="py-3 bb">
      <Row>
        <Col>
          <h4>
            <b>{title}</b>
          </h4>
        </Col>
      </Row>
      <Row>
        {/* {img ? (
          <Col>
            <Image src={img} className="img" />
          </Col>
        ) : (
          <></>
        )} */}
        <Col>
          <div className="">
            <p className="news-card-text__VI">{newsText}</p>
            <Link to={link} className="link__VI">
              {t('product.more')}
            </Link>
            <p className="news-date__VI">
              {new Date(date).toLocaleDateString()}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NewsCardVI;
