import { $authHost, $host } from './index';

export const createRequest = async (fullName, phone, mail, type) => {
  const { data } = await $host.post('api/request', {
    fullName,
    phone,
    mail,
    type,
  });
  return data;
};

export const fetchAllRequest = async (limit = 5, page = 1) => {
  const { data } = await $authHost.get('api/request', {
    params: { limit, page },
  });
  return data;
};

export const fetchRequest = async (id) => {
  const { data } = await $authHost.get('api/request/' + id);
  return data;
};

export const updateRequest = async (id, processed) => {
  const { data } = await $authHost.put('api/request/' + id, { processed });
  return data;
};

export const deleteRequest = async (id) => {
  const { data } = await $authHost.delete('api/request/' + id);
  return data;
};

export const sendFeedback = async (feedback) => {
  const { data } = await $host.post('api/request/feedback', feedback);
  return data;
};

export const sendFeedbackEntity = async (feedback) => {
  const { data } = await $host.post('api/request/feedbackEntity', feedback);
  return data;
};
