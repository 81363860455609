import React, { useContext, useEffect } from 'react';
import ContactCard from '../../components/ContactCard';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import ContactCardVI from '../../components/VI/ContactCardVI';

const ContactVI = observer(() => {
  const { vi } = useContext(Context);

  useEffect(() => {}, [
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  document.title = t('contacts.pageTitle');
  const contacts = t('contactCard', { returnObjects: true });

  return (
    <div
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="text-center py-3">{t('contacts.title')}</h1>
      {contacts.map((contact) => (
        <ContactCardVI
          key={contact.name}
          name={contact.name}
          contacts={contact.contacts}
        />
      ))}
    </div>
  );
});

export default ContactVI;
