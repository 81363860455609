import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MCHS } from '../utils/consts';
import '../utils/i18next';
import BackToTop from './BackToTop';
import Request from './Request';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer
      className="footer mt-auto"
      style={{ backgroundImage: 'url(../img/slider_3.jpg)' }}
    >
      <div className="container">
        <Row>
          <Col lg={3} md={4} sm={6} xs={12} className="mb-3">
            <i className="fa-solid fa-gem footer-icon"></i>
            <p>{t('footer.quality')}</p>
            <p>
              <i className="fa-solid fa-phone"></i>{' '}
              <a
                className="footer-link"
                href="tel:+375232344256"
                target="_blank"
                rel="noreferrer"
              >
                +375232344256
              </a>
            </p>
            <p>
              <i className="fa-solid fa-at"></i>{' '}
              <a
                className="footer-link"
                href="mailto:muka@melenka.by"
                target="_blank"
                rel="noreferrer"
              >
                muka@melenka.by
              </a>
            </p>
          </Col>
          <Col lg={3} md={4} sm={6} xs={12} className="mb-3">
            <i className="fa-solid fa-dolly footer-icon"></i>
            <p>{t('footer.export')}</p>
            <p>
              <i className="fa-solid fa-phone"></i>{' '}
              <a
                className="footer-link"
                href="tel:+375232344891"
                target="_blank"
                rel="noreferrer"
              >
                +375232344891
              </a>
            </p>
            <p>
              <i className="fa-solid fa-at"></i>{' '}
              <a
                className="footer-link"
                href="mailto:mukatam@mail.gomel.by"
                target="_blank"
                rel="noreferrer"
              >
                mukatam@mail.gomel.by
              </a>
            </p>
          </Col>
          <Col lg={3} md={4} sm={6} xs={12} className="mb-3">
            <i className="fa-solid fa-cart-shopping footer-icon"></i>
            <p>{t('footer.sale')}</p>
            <p>
              <i className="fa-solid fa-phone"></i>{' '}
              <a
                className="footer-link"
                href="tel:+375232344226"
                target="_blank"
                rel="noreferrer"
              >
                +375232344226
              </a>
            </p>
            <p>
              <i className="fa-solid fa-at"></i>{' '}
              <a
                className="footer-link"
                href="mailto:sbitgomelkhp@mail.gomel.by"
                target="_blank"
                rel="noreferrer"
              >
                sbitgomelkhp@mail.gomel.by
              </a>
            </p>
          </Col>
          <Col md={12} lg={3}>
            <p className="footer-paragraph">
              <a
                className="footer-link"
                href="https://president.gov.by/ru"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.president')}
              </a>
            </p>
            <p className="footer-paragraph">
              <a
                className="footer-link"
                href="http://mininform.gov.by/"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.ministry')}
              </a>
            </p>
            <p className="footer-paragraph">
              <a
                className="footer-link"
                href="https://pravo.by/"
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.law')}
              </a>
            </p>
            <p className="footer-paragraph">
              <a
                className="footer-link"
                href={MCHS}
                rel="noreferrer"
              >
                {t('footer.MES')}
              </a>
            </p>
            <p className="footer-paragraph">
              <a
                className="footer-link"
                href={
                  process.env.REACT_APP_API_URL +
                  'Политика_обработки_персональных_данных.pdf'
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('footer.politics')}
              </a>
            </p>
          </Col>
        </Row>
        <Row className="mt-2">
          <p className="d-flex justify-content-end">{t('footer.copyright')}</p>
        </Row>
        <Request />
        <BackToTop />
      </div>
    </footer>
  );
};

export default Footer;
