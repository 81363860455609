import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Form, Row, Table } from 'react-bootstrap';
import { fetchAllLocale, updateLocale } from '../../http/localeApi';
import { Context } from '../../index';

const Locale = observer(() => {
  const [editId, setEditId] = useState('');
  const [editName, setEditName] = useState('');

  const { locale } = useContext(Context);

  useEffect(() => {
    fetchAllLocale().then((data) => locale.setLocale(data));
  }, [locale]);

  const editToggle = (id, name) => {
    setEditId(id);
    setEditName(name);
  };

  const editLocale = () => {
    updateLocale(editId, editName).then((data) => locale.updateLocale(data.id, data));
    setEditId('');
  };

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Локализации</h1>
      <Row>
        <Table>
          <thead>
            <tr>
              <th className="col-1">#</th>
              <th>Название</th>
              <th className="col-1"></th>
            </tr>
          </thead>
          <tbody>
            {locale.locale.map((locale) =>
              locale.id === editId ? (
                <tr key={locale.id}>
                  <td>{locale.id}</td>
                  <td>
                    <Form.Control
                      className="form-control-sm"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      placeholder={'Введите название локализации'}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      className="me-2"
                      onClick={() => editToggle('', '')}
                    >
                      <i className="bi bi-x-lg"></i>
                    </Button>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => editLocale()}
                    >
                      <i className="bi bi-check2"></i>
                    </Button>
                  </td>
                </tr>
              ) : (
                <tr key={locale.id}>
                  <td>{locale.id}</td>
                  <td>{locale.name}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => editToggle(locale.id, locale.name)}
                    >
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
});

export default Locale;
