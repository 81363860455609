import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import { fetchAllTradeUnionLang } from '../../http/tradeUnionApi';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';
import PersoneCardVI from '../../components/VI/PersoneCardVI';

const TradeUnionStructureVI = observer(() => {
  const { tradeUnion, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAllTradeUnionLang(locale.selectLang)
      .then((data) => {
        tradeUnion.setTradeUnion(data);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [
    tradeUnion,
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  document.title = t('tradeUnion.pageTitle');

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );
  return (
    <div
      className={
        'container__VI py-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="text-center mb-3">{t('tradeUnion.structure')}</h1>
      <Row>
        {tradeUnion.tradeUnion?.map((tradeUnion) =>
          tradeUnion.tradeUnionInfos?.map((info) => (
            <PersoneCardVI
              key={info.id}
              type={'tradeUnion'}
              phone={tradeUnion.phone}
              mail={tradeUnion.mail}
              img={process.env.REACT_APP_API_URL + tradeUnion.photo}
              fullName={info.fullName}
              position={info.position}
              about={info.about}
            />
          ))
        )}
        <Col></Col>
      </Row>
    </div>
  );
});

export default TradeUnionStructureVI;
