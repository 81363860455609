import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { createPhoto } from '../../http/newsApi';
import { Context } from '../../index';

const AddNewsPhoto = ({ newsId, show, onHide }) => {
  const { newsPhoto } = useContext(Context);

  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const addNewsPhoto = () => {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('newsId', newsId);
    createPhoto(formData).then((data) => {
      onHide();
      newsPhoto.addNewsPhoto(data);
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить фото к новости
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            onChange={selectFile}
            className="mt-3 mb-3"
            type="file"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button variant="outline-success" onClick={addNewsPhoto}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewsPhoto;
