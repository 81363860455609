import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { fetchAllBrand } from '../../http/brandApi';
import { createStoreBrand } from '../../http/storeBrandApi';
import { Context } from '../../index';

const CreateStoreBrand = ({ storeId, show, onHide }) => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [error, setError] = useState(false);

  const { brand, storeBrand } = useContext(Context);

  useEffect(() => {
    fetchAllBrand().then((data) => brand.setBrand(data.rows));
  }, [brand]);

  const addStoreBrand = () => {
    selectedBrand
      ? createStoreBrand(storeId, selectedBrand.id).then((data) => {
          setSelectedBrand(null);
          onHide();
          storeBrand.addStoreBrand(data);
        })
      : setError(true);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить производителя в магазин
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error ? 'error' : ''}>
              {selectedBrand
                ? selectedBrand.brandInfos[0].name
                : 'Выберите производителя'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {brand.brand.map((brand) =>
                brand.brandInfos.map((info) =>
                  info.localeId === 1 ? (
                    <Dropdown.Item
                      key={info.id}
                      onClick={() => {
                        setSelectedBrand(brand);
                        setError(false)
                      }}
                    >
                      {info.name}
                    </Dropdown.Item>
                  ) : (
                    ''
                  )
                )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button variant="outline-success" onClick={addStoreBrand}>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateStoreBrand;
