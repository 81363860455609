import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import {
  deleteProduct,
  deleteProductInfo,
  fetchAllProduct,
  updateProduct,
  updateProductInfo,
} from '../../http/productApi';
import { fetchAllBrand } from '../../http/brandApi';
import { fetchAllCategory } from '../../http/categoryApi';
import { Context } from '../../index';
import { Link } from 'react-router-dom';
import { ADMIN_PRODUCT_ROUTE } from '../../utils/consts';
import CreateProduct from '../../components/modals/CreateProduct';
import Pages from '../../components/Pages';
import PageSize from '../../components/PageSize';
import { insertTab } from '../../utils/insertTab';

const Product = observer(() => {
  const [createVisible, setCreateVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editInfoId, setEditInfoId] = useState('');
  const [editName, setEditName] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editShortDescription, seteditShortDescription] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editFile, setFile] = useState(null);
  const [lang, setLang] = useState(null);
  const [loading, setLoading] = useState(true);

  const { product, brand, category, page, locale } = useContext(Context);

  useEffect(() => {
    fetchAllProduct(page.limit, page.page)
      .then((data) => {
        product.setProduct(data.product);
        page.setTotalCount(data.count);
      })
      .then(() => setLoading(false));
    fetchAllBrand().then((data) => brand.setBrand(data.rows));
    fetchAllCategory().then((data) => category.setCategory(data));
  }, [product, page.page, page.limit, category, brand]);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const editToggle = (id, product) => {
    setEditId(id);
    setSelectedBrand(product.brand);
    setSelectedCategory(product.category);
  };

  const editInfoToggle = (id, product) => {
    setEditInfoId(id);
    setEditName(product.name);
    seteditShortDescription(product.shortDescription);
    setEditDescription(product.description);
    setLang(product.locale);
  };

  const editProduct = () => {
    const formData = new FormData();
    formData.append('brandId', selectedBrand.id);
    formData.append('categoryId', selectedCategory.id);
    formData.append('photo', editFile);
    updateProduct(editId, formData).then((data) => {
      product.updateProduct(data.id, data);
      setEditId('');
    });
  };

  const editProductInfo = () => {
    updateProductInfo(
      editInfoId,
      editName,
      editShortDescription,
      editDescription,
      lang.id
    ).then((data) => {
      product.updateProduct(data.id, data);
      setEditInfoId('');
    });
  };

  const delProduct = (id) => {
    deleteProduct(id)
      .then(() => {
        product.removeProduct(id);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  const delProductInfo = (id, productId) => {
    deleteProductInfo(id, productId)
      .then((data) => {
        product.updateProduct(data.id, data);
      })
      .catch(() => {
        alert('Нет доступа');
      });
  };

  if (loading) return <Spinner animation={'grow'} />;

  return (
    <Container>
      <h1 className="d-flex justify-content-center">Продукты</h1>
      <Row className="mt-3">
        <Col>
          <Button
            variant="outline-success"
            onClick={() => setCreateVisible(true)}
          >
            <i className="bi bi-plus-square"></i> Добавить
          </Button>
        </Col>
        <Col className="d-flex justify-content-center">
          <PageSize />
        </Col>
        <Col className="d-flex justify-content-end">
          <Pages />
        </Col>
      </Row>

      <Row>
        {product.product.length !== 0 ? (
          <Table hover>
            <thead>
              <tr>
                <th>#</th>
                <th className="col-2">Фото</th>
                <th className="col-1">Брэнд</th>
                <th className="col-1">Тип</th>
                <th>Информация</th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {product.product?.map((product) => (
                <tr key={product.id}>
                  <td>
                    <Link to={ADMIN_PRODUCT_ROUTE + '/' + product.id}>
                      {product.id}
                    </Link>
                  </td>
                  {product.id === editId ? (
                    <>
                      <td>
                        <Form.Control
                          onChange={selectFile}
                          className="form-control-sm"
                          type="file"
                        />
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle size="sm">
                            {selectedBrand
                              ? selectedBrand.brandInfos[0].name
                              : 'Выберите производителя'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {brand.brand.map((brand) =>
                              brand.brandInfos.map((info) =>
                                info.locale.name === 'ru' ? (
                                  <Dropdown.Item
                                    key={info.id}
                                    onClick={() => setSelectedBrand(brand)}
                                  >
                                    {info.name}
                                  </Dropdown.Item>
                                ) : (
                                  ''
                                )
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle size="sm">
                            {selectedCategory
                              ? selectedCategory.categoryInfos[0].name
                              : 'Выберите тип'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {category.category.map((category) =>
                              category.categoryInfos.map((info) =>
                                info.locale.name === 'ru' ? (
                                  <Dropdown.Item
                                    key={info.id}
                                    onClick={() =>
                                      setSelectedCategory(category)
                                    }
                                  >
                                    {info.name}
                                  </Dropdown.Item>
                                ) : (
                                  ''
                                )
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <div className="photo-wrapper">
                          {product.photo ? (
                            <Image
                              src={
                                process.env.REACT_APP_API_URL + product.photo
                              }
                              className="photo-mini"
                            />
                          ) : (
                            'нет фото'
                          )}
                        </div>
                      </td>
                      <td>{product.brand?.brandInfos[0]?.name}</td>
                      <td>{product.category?.categoryInfos[0]?.name}</td>
                    </>
                  )}
                  <td>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Название</th>
                          <th>Краткой описание</th>
                          <th>Описание</th>
                          <th>Язык</th>
                          <th className="col-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.productInfos?.map((info) =>
                          info.id === editInfoId ? (
                            <tr key={info.id}>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editName}
                                  onChange={(e) => setEditName(e.target.value)}
                                  placeholder={'Введите название продукта...'}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editShortDescription}
                                  onChange={(e) =>
                                    seteditShortDescription(e.target.value)
                                  }
                                  placeholder={'Введите краткое описание...'}
                                  as="textarea"
                                  rows={5}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  className="form-control-sm"
                                  value={editDescription}
                                  onChange={(e) =>
                                    setEditDescription(e.target.value)
                                  }
                                  placeholder={'Введите описание...'}
                                  as="textarea"
                                  onKeyDown={(e) =>
                                    insertTab(
                                      e,
                                      setEditDescription,
                                      editDescription
                                    )
                                  }
                                  rows={5}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle size="sm">
                                    {lang ? lang.name : 'Выберите язык'}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {locale.locale.map((locale) => (
                                      <Dropdown.Item
                                        key={locale.id}
                                        onClick={() => setLang(locale)}
                                      >
                                        {locale.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="mb-2"
                                  onClick={() => editInfoToggle('', '')}
                                >
                                  <i className="bi bi-x-lg"></i>
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  onClick={() => editProductInfo()}
                                >
                                  <i className="bi bi-check2"></i>
                                </Button>
                              </td>
                            </tr>
                          ) : (
                            <tr key={info.id}>
                              <td>{info.name}</td>
                              <td>{info.shortDescription}</td>
                              <td>{info.description}</td>
                              <td>{info.locale?.name}</td>
                              <td>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="me-2 mb-2"
                                  onClick={() => editInfoToggle(info.id, info)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    delProductInfo(info.id, product.id)
                                  }
                                >
                                  <i className="bi bi-x-circle"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  {product.id === editId ? (
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        className="me-2"
                        onClick={() => editToggle('', '')}
                      >
                        <i className="bi bi-x-lg"></i>
                      </Button>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => editProduct()}
                      >
                        <i className="bi bi-check2"></i>
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-2 mb-2"
                        onClick={() => editToggle(product.id, product)}
                      >
                        Изменить
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => delProduct(product.id)}
                      >
                        Удалить
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="d-flex justify-content-center">Нет информации</h3>
        )}
      </Row>
      <CreateProduct
        show={createVisible}
        onHide={() => setCreateVisible(false)}
      />
    </Container>
  );
});

export default Product;
