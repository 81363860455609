import React from 'react';

const ContactCard = ({name, contacts}) => {
  return (
    <div>
      <div className="item">
        <div className="text p-3">
          <h4 className='text-center mb-3'>
            <b>{name}</b>
          </h4>
          {contacts.map((contact) => (
              <p key={contact}>{contact}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
