export const ADMIN_ROUTE = '/admin';
export const ADMIN_BRAND_ROUTE = '/admin/brand';
export const ADMIN_CATEGORY_ROUTE = '/admin/categore';
export const ADMIN_PRODUCT_ROUTE = '/admin/product';
export const ADMIN_STORE_ROUTE = '/admin/store';
export const ADMIN_ADMINREQUESTS_ROUTE = '/admin/requests';
export const ADMIN_VACANCY_ROUTE = '/admin/vacancy';
export const ADMIN_ENTERPRISE_ADMINISTRATION_ROUTE =
  '/admin/enterpriseAdministration';
export const ADMIN_TRADE_UNION_ROUTE = '/admin/tradeUnion';
export const ADMIN_LOCALE_ROUTE = '/admin/locale';
export const ADMIN_CURRENCY_ROUTE = '/admin/currency';
export const ADMIN_AWARD_ROUTE = '/admin/award';
export const ADMIN_NEWS_ROUTE = '/admin/news';
export const ADMIN_NEWS_CATEGORY_ROUTE = '/admin/news/category';
export const ADMIN_NEWS_FILES_ROUTE = '/admin/newsFiles';
export const ADMIN_RECIPE_ROUTE = '/admin/recipe';
export const ADMIN_FILES_ROUTE = '/admin/files';
export const AUTH_ROUTE = '/auth';

export const MAIN_ROUTE = '/';
export const ABOUT_ROUTE = '/about';
export const PRODUCTS_ROUTE = '/product';
export const VACANCY_ROUTE = '/vacancy';
export const HISTORY_ROUTE = '/about/history';
export const AWARDS_ROUTE = '/about/awards';
export const STORE_ROUTE = '/store';
export const RECIPE_ROUTE = '/recipes';
export const CONTACT_ROUTE = '/contanct';
export const NEWS_ROUTE = '/news';
export const TRADE_UNION_ROUTE = '/tradeunion';
export const STRUCTURE_ROUTE = '/structure';
export const TRADE_UNION_STRUCTURE_ROUTE = '/tradeunionstructure';
export const ENTERPRISE_TODAY_ROUTE = '/about/today';
export const QUALITY_ROUTE = '/about/quality';
export const ECO_ROUTE = '/about/eco';
export const SOCIAL_ACTIVITY_ROUTE = '/socialactivity';
export const PRICE_ROUTE = '/price';
export const FEEDBACK_ROUTE = '/feedback';
export const RECEPTOION_SCHEDULE = '/receptionschedule'
export const MCHS = '/MCHSinformiruyet'
