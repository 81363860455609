import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { fetchAllFile } from '../http/filesApi';
import { Container, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { Helmet } from 'react-helmet';

const Price = observer(() => {
  const { t } = useTranslation();
  document.title = t('price.pageTitle');
  const { files, locale } = useContext(Context);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchAllFile()
      .then((data) => {
        files.setFile(data);
      })
      .then(() => setLoading(false));
    window.scrollTo(0, 0);
  }, [locale.selectLang]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
        <Helmet
          title={t('price.pageTitle')}
          meta={[{ name: 'description', content: t('price.pageDescription') }]}
        />
      </Container>
    );

  return (
    <Container className="mt-3 min-vh-100">
      <div className="item p-3">
        <Helmet
          title={t('price.pageTitle')}
          meta={[{ name: 'description', content: t('price.pageDescription') }]}
        />
        <h1 className="text-center mt-3 mb-3">{t('price.title')}</h1>
        <p>{t('price.text')}</p>
        {files.files.map((file) => (
          <li key={file.id} className="file-list-li">
            <a
              href={process.env.REACT_APP_API_URL + file.file}
              download={file.name}
            >
              <i className="fa-solid fa-download"></i> {file.name}
            </a>
          </li>
        ))}
      </div>
    </Container>
  );
});

export default Price;
