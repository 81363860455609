import { makeAutoObservable } from 'mobx';

export default class BrandStore {
  constructor() {
    this._brand = [];
    makeAutoObservable(this);
  }

  setBrand(brand) {
    this._brand = brand;
  }

  addBrand(brand) {
    this._brand.push(brand);
  }

  updateBrand(id, data) {
    this._brand = this._brand.map((brand) =>
      brand.id === id ? { ...data } : { ...brand }
    );
  }

  removeBrand(id) {
    this._brand = this._brand.filter((brand) => brand.id !== id);
  }

  get brand() {
    return this._brand;
  }
}
