import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Context } from '../..';
import '../../utils/i18next';

const ReceptionScheduleVI = observer(() => {
  const { vi } = useContext(Context);
  useEffect(() => {}, [
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);
  const { t } = useTranslation();
  const schedule = t('scheduleCard', { returnObjects: true });
  document.title = t('schedule.pageTitle');
  return (
    <div
      className={
        'container__VI' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      <h1 className="text-center py-3">{t('schedule.title')}</h1>
      <Table className='m-0'>
        <thead>
          <tr className="text-center">
            <th className="col-4">{t('schedule.post')}</th>
            <th className="col-4">{t('schedule.time')}</th>
            <th className="col-4">{t('schedule.place')}</th>
          </tr>
        </thead>
        {schedule.map((schedule) => (
          <tbody>
            <tr>
              <td className="text-center">
                {schedule.post}
                <p>
                  {schedule.name} {schedule.surname}
                </p>
              </td>
              <td className="text-center">
                {schedule.schedule1} <br />
                {schedule.schedule2}
              </td>
              <td>
                <p className="text-center">{schedule.place}</p>
              </td>
            </tr>
          </tbody>
        ))}
      </Table>
    </div>
  );
});

export default ReceptionScheduleVI;
