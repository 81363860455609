import { $authHost, $host } from './index';

export const createVacancy = async (
  jobTitle,
  requiredSpecialty,
  wageMin,
  wageMax,
  workingConditions,
  requiredExperienceFrom,
  requiredExperienceUpTo,
  localeId
) => {
  const { data } = await $authHost.post('api/vacancy', {
    jobTitle,
    requiredSpecialty,
    wageMin,
    wageMax,
    workingConditions,
    requiredExperienceFrom,
    requiredExperienceUpTo,
    localeId,
  });
  return data;
};

export const fetchAllVacancy = async (limit = 5, page = 1) => {
  const { data } = await $host.get('api/vacancy', { params: { limit, page } });
  return data;
};

export const fetchAllVacancyLang = async (id, limit = 5, page = 1) => {
  const { data } = await $host.get('api/vacancy/locale/' + id, {
    params: { limit, page },
  });
  return data;
};

export const fetchVacancy = async (id) => {
  const { data } = await $host.get('api/vacancy/' + id);
  return data;
};

export const updateVacancy = async (
  id,
  wageMin,
  wageMax,
  requiredExperienceFrom,
  requiredExperienceUpTo
) => {
  const { data } = await $authHost.put('api/vacancy/' + id, {
    wageMin,
    wageMax,
    requiredExperienceFrom,
    requiredExperienceUpTo,
  });
  return data;
};

export const deleteVacancy = async (id) => {
  const { data } = await $authHost.delete('api/vacancy/' + id);
  return data;
};

export const createVacancyInfo = async (
  jobTitle,
  requiredSpecialty,
  workingConditions,
  localeId,
  vacancyId
) => {
  const { data } = await $authHost.post('api/vacancy/info', {
    jobTitle,
    requiredSpecialty,
    workingConditions,
    localeId,
    vacancyId,
  });
  return data;
};

export const updateVacancyInfo = async (
  id,
  jobTitle,
  requiredSpecialty,
  workingConditions,
  localeId
) => {
  const { data } = await $authHost.put('api/vacancy/info/' + id, {
    jobTitle,
    requiredSpecialty,
    workingConditions,
    localeId,
  });
  return data;
};

export const deleteVacancyInfo = async (id, vacancyId) => {
  const { data } = await $authHost.delete('api/vacancy/info/' + id, {
    data: { vacancyId },
  });
  return data;
};
