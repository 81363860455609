import { makeAutoObservable } from 'mobx';

export default class NewsFileStore {
  constructor() {
    this._newsFile = [];
    makeAutoObservable(this);
  }

  setNewsFile(newsFile) {
    this._newsFile = newsFile;
  }

  addNewsFile(newsFile) {
    this._newsFile.push(newsFile);
  }

  updateNewsFile(id, data) {
    this._newsFile = this._newsFile.map((newsFile) =>
      newsFile.id === id ? { ...data } : { ...newsFile }
    );
  }

  removeNewsFile(id) {
    this._newsFile = this._newsFile.filter((newsFile) => newsFile.id !== id);
  }

  get newsFile() {
    return this._newsFile;
  }
}
