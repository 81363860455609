import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fetchAllCategoryLang } from '../../http/categoryApi';
import { Context } from '../../index';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';

const CategoryVI = observer(() => {
  const { category, locale, page } = useContext(Context);

  const { t } = useTranslation();

  useEffect(() => {
    fetchAllCategoryLang(locale.selectLang).then((data) => {
      category.setCategory(data);
    });
  }, [locale.selectLang]);

  const categoryHandler = (id) => {
    category.setSelectCategory(id);
    page.setPage(1);
  };

  return (
    <>
      <p
        className={
          'link__VI me-3' + (!category.selectCategory ? ' link__VI_activ' : '')
        }
        onClick={() => categoryHandler(null)}
      >
        {t('product.all')}
      </p>
      {category.category.map((cat) => (
        <p
          key={cat.id}
          className={
            'link__VI me-3' +
            (category.selectCategory === cat.id ? ' link__VI_activ' : '')
          }
          onClick={() => categoryHandler(cat.id)}
        >
          {cat.name}
        </p>
      ))}
    </>
  );
});

export default CategoryVI;
