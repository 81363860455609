import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Context } from '../../index';
import { fetchRecipeLang } from '../../http/recipeApi';
import { useTranslation } from 'react-i18next';
import '../../utils/i18next';

const RecipeFullVI = observer(() => {
  const { recipe, locale, vi } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    fetchRecipeLang(params.id, locale.selectLang)
      .then((data) => {
        recipe.setRecipe(data);
      })
      .then(() => {
        document.title = recipe.recipe?.recipeInfos[0]?.name;
        setLoading(false);
      });
    window.scrollTo(0, 0);
  }, [
    locale.selectLang,
    vi.tSize,
    vi.interval,
    vi.kerning,
    vi.colors,
    vi.pColor,
  ]);

  if (loading)
    return (
      <Container className="mt-5 h-100 d-flex justify-content-center">
        <Spinner animation={'grow'} />
      </Container>
    );
  let hours = Number(String(recipe.recipe?.time).split('.')[0]);
  let minutes = Number(String(recipe.recipe?.time).split('.')[1]);
  return (
    <div
      className={
        'container__VI py-3' +
        ' tSize_' +
        vi.tSize +
        ' interval_' +
        vi.interval +
        ' kerning_' +
        vi.kerning +
        ' colors_' +
        vi.colors +
        ' pColor_' +
        vi.pColor
      }
    >
      {recipe.recipe ? (
        <>
          <h1 className='tac'>{recipe.recipe?.recipeInfos[0]?.name}</h1>
          <Image
            src={process.env.REACT_APP_API_URL + recipe.recipe?.photo}
            className="img w50"
          />
          <p className="recipe-card-text-full pt-5">
            {recipe.recipe?.recipeInfos[0]?.ingredients}
          </p>
          <p>
            <b>{hours || minutes ? t('recipes.cookingTime') : ''}</b>
            {hours
              ? hours + (hours === 1 ? t('recipes.hour') : t('recipes.hours'))
              : ''}
            {minutes ? minutes + t('recipes.minutes') : ''}
          </p>
          <h3 className="d-flex justify-content-center">
            {t('recipes.cooking')}
          </h3>
          <p className="recipe-card-text-full">
            {recipe.recipe?.recipeInfos[0]?.manual}
          </p>
          <Link onClick={() => history.goBack()} className="link__VI">
            {t('recipes.back')}
          </Link>
        </>
      ) : (
        <Row>
          <h2 className="d-flex justify-content-center mt-5">
            {t('product.notFound')}
          </h2>
          <Col className="my-5">
            <button onClick={() => history.goBack()} className="btn__card">
              {t('recipes.back')}
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
});

export default RecipeFullVI;
