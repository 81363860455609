import React, { useContext, useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { createAward, createAwardInfo } from '../../http/awardApi';
import { Context } from '../../index';

const CreateStore = ({ show, onHide }) => {
  const { award, locale } = useContext(Context);
  const [toggle, setToggle] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [lang, setLang] = useState();
  const [error, setError] = useState();

  const submitHandler = (type) => {
    const formData = new FormData();
    if (type === 'new') {
      formData.append('description', description);
      formData.append('photo', file);
      formData.append('localeId', lang.id);
    }
    type === 'info'
      ? createAwardInfo(description, lang.id, selectedAward.id).then((data) => {
          onHide();
          award.updateAward(data.id, data);
        })
      : createAward(formData).then((data) => {
          onHide();
          award.addAward(data);
        });

    setDescription('');
    setSelectedAward(null);
    setFile(null);
  };

  const addAward = () => {
    description
      ? lang
        ? submitHandler('new')
        : setError('lang')
      : setError('description');
  };

  const addAwardInfo = () => {
    selectedAward
      ? description
        ? lang
          ? submitHandler('info')
          : setError('lang')
        : setError('description')
      : setError('award');
  };

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить награду
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            checked={toggle}
            onChange={(e) => setToggle(e.target.checked)}
            className="mb-3"
            type="switch"
            label="Добавить перевод"
          />
          {toggle ? (
            <Dropdown className="mt-3 mb-3">
              <Dropdown.Toggle className={error === 'award' ? 'error' : ''}>
                {selectedAward ? selectedAward.id : 'Выберите награду'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {award.award.map((award) => (
                  <Dropdown.Item
                    key={award.id}
                    onClick={() => {
                      setSelectedAward(award);
                      setError('');
                    }}
                  >
                    {award.id}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Form.Control onChange={selectFile} className="mb-3" type="file" />
          )}

          <Form.Control
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setError('');
            }}
            as="textarea"
            rows={1}
            className={error === 'description' ? 'error' : '' + 'mb-3'}
            placeholder={'Введите описание награды'}
          />
          <Dropdown className="mt-3 mb-3">
            <Dropdown.Toggle className={error === 'lang' ? 'error' : ''}>
              {lang ? lang.name : 'Выберите язык'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {locale.locale.map((locale) => (
                <Dropdown.Item
                  key={locale.id}
                  onClick={() => {
                    setLang(locale);
                    setError('');
                  }}
                >
                  {locale.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>
          Закрыть
        </Button>
        <Button
          variant="outline-success"
          onClick={toggle ? addAwardInfo : addAward}
        >
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateStore;
